import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetSourceById = async (id) => {

    //const userInfo = await Auth.currentAuthenticatedUser();
    let apiData = {}
    try {
        apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.getSources, // change this after we know DB schema
            variables: {
                id: id,
            }
        }); 
    } catch (err) {
        console.log("Error:" + err)
    }

    console.log(apiData.data.getSources)
    return apiData.data.getSources;
}

export default GetSourceById;