import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const SavePanelists = async (data, createOrChange) => {
    
    if (createOrChange === "create") {

        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: mutations.createPanelist, // change this after we know DB schema
            variables: {
                input: data,
            }
        });
    
        return apiData.data.createPanelist.id;

    } else if (createOrChange === "change") {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: mutations.updatePanelist, // change this after we know DB schema
            variables: {
                input: data,
            }
        });
    
        return apiData.data.createPanelist.id;
    }
}

export default SavePanelists;