import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetLLMAssistantByPersonaID = async (personaID) => {
    const response = await API.graphql({
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: queries.lLMAssistantsByCustomerPersonaID,
        variables: { customerPersonaID: personaID }
    });
    return response.data.lLMAssistantsByCustomerPersonaID.items[0];
}

export default GetLLMAssistantByPersonaID;