import { useEffect, useState } from 'react';
import Loading from '../../utils/Loading';
import { Card, Title, BarChart } from "@tremor/react";

const ProjectSurveyResultsForConcepts = (props) => {
    console.log("in ProjectSurveyResultsForConcepts: ", props)

    const [loading, setLoading] = useState(true);
    const objective = props.objective;
    const results = props.results;
    const respondents = props.respondents;
    const concepts = props.concepts;
    const questions = props.questions;

    const valueFormatter = (number) => Intl.NumberFormat("us").format(number).toString();

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading])    

    let dataForBarChart = [];

    return (

        <div className="flex flex-col w-full m-4 bg-slate-50 overflow-y-auto">

            {loading ? (<Loading /> ) : (

            results.map((conceptResults, i) => {

                console.log("conceptResults");
                console.log(conceptResults);

                return (       

                    <div key={i} className="flex flex-row flex-wrap w-full bg-slate-50 overflow-x-auto">
                        
                        <div className="flex flex-row bg-slate-500 text-left w-full text-white px-4 py-2 rounded-xl">
                            <h4>{concepts[i].description}</h4>
                        </div>

                        {questions.map((question, j) => {

                            let resultsquestion = [];
                            if (conceptResults) {
                                const index = conceptResults.findIndex((result) => result[0].question === question.question);
                                if (index !== -1) {
                                    resultsquestion = conceptResults[index];
                                }
                               // console.log("resultsquestion: ", resultsquestion);
                            }

                            //const optionsArray = JSON.parse(question.options);  
                            const optionsArray = question.options;

                            const dataForBarChart = optionsArray.map((optionObject, k) => {
                                //console.log("optionObject", optionObject);
                                // trim leading or lagging spaces
                                optionObject.option = optionObject.option.trim();
                                // check if optionObject.respondents is already set
                                /*if (optionObject.respondents) {
                                    return {
                                        categories: optionObject.option,
                                        values: optionObject.respondents,  // number of respondents
                                    };
                                }*/

                                // find the number of respondents for each option
                                optionObject.respondents = 0;
                                for (let l = 0; l < resultsquestion.length; l++) {
                                    if (resultsquestion[l].question === question.question) {
                                        //console.log("resultsquestion[l].answer", resultsquestion[l].answer);
                                        try {
                                            let answerOptions = resultsquestion[l].answer ? JSON.parse(resultsquestion[l].answer) : {};
                                            // if answerOptions is an array, set answerOptions to the first element
                                            if (Array.isArray(answerOptions)) {
                                                answerOptions = answerOptions[0];
                                            }
                                            if (typeof answerOptions === 'object' && answerOptions.hasOwnProperty('option')) {
                                                // trim leading or lagging spaces
                                                answerOptions.option = answerOptions.option.trim();
                                                if (answerOptions.option === optionObject.option) {
                                                    //console.log("optionObject.option", optionObject.option);
                                                    optionObject.respondents = optionObject.respondents + 1;
                                                }
                                            }
                                        } catch (e) {
                                            console.error("Error parsing JSON string:", e);
                                        }
                                    }
                                }

                                return {
                                    categories: optionObject.option,
                                    respondents: optionObject.respondents,  // number of respondents
                                };
                                /*

                                const respondentsForOption = conceptResults.flat().filter((result) => {
                                    const answerOption = JSON.parse(result.answer);
                                    return result.question === question.question && answerOption.option === optionObject.option;
                                });
                                                        
                                console.log("options", optionObject.option, "respondentsForOption", respondentsForOption.length)
                                
                                return {
                                    categories: optionObject.option,
                                    values: respondentsForOption.length,  // number of respondents
                                };*/

                            });

                            //console.log("dataForBarChart:");
                            //console.log(dataForBarChart);

                            return (

                                <div key={`${i}-${j}`} className="flex flex-row flex-wrap w-full bg-slate-50 mt-4 mb-4 overflow-x-auto">
                                    <div className="flex flex-row bg-slate-50 w-full">
                                        <Card className="w-full h-196" decoration="">
                                            <Title className="text-center">{question.question}</Title>
                                            <BarChart 
                                                data={dataForBarChart}
                                                categories={['respondents']}
                                                index="categories"
                                                valueFormatter={valueFormatter}
                                                colors={["blue"]}
                                                showLegend={false}
                                                yAxisWidth={48}
                                                showAnimation={true}
                                            />
                                        </Card>
                                    </div>
                                </div>
                            );
                        })}
                        <hr className="w-full border-5 border-slate-500 mb-4" />
                    </div>
                );                
            })
        )}
        </div>
    );
}

export default ProjectSurveyResultsForConcepts;
