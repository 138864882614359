// use state
import { useState, useEffect } from "react";
// AWS
import { Auth, API } from 'aws-amplify';
// local components
//import InteractionSidebar from "../navigation/InteractionSidebar";
//import InterviewQuestions from "./InterviewQuestions";
//import InterviewResults from "./InterviewResults";
//import InterviewBrief from "./InterviewBrief";

// GraphQL
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

import { useParams } from "react-router-dom";
import { set } from "react-hook-form";

// Loading
//import CircularProgress from '@mui/material/CircularProgress';
import Loading from "../../utils/Loading";
import ProjectBrief from "./ProjectBrief";
import ProjectConcepts from "./ProjectConcepts";
import ProjectResults from "./ProjectResults";
import ProjectPartialResults from "./ProjectPartialResults";
import ProjectPartialConsumerResults from "./ProjectPartialConsumerResults";
import GetSegments from "../../utils/personas/GetSegments";
import GetPanels from "../../utils/panels/GetPanels";
import SimpleLLMCall from "../../utils/llmcall/SimpleLLMCall";
import SimpleLLMCallJSON from "../../utils/llmcall/SimpleLLMCallJSON";
import ProjectConsumer from "./ProjectConsumer";
import ProjectConsumerResults from "./ProjectConsumerResults";
import SaveQualProjectBrief from "../../utils/qualProjects/SaveQualProjectBrief";
import SaveQualProjectResults from "../../utils/qualProjects/SaveQualProjectResults";
import SaveQualProjectsQueAns from "../../utils/qualProjects/SaveQualProjectsQueAns";
import { Save } from "@mui/icons-material";
import SaveProjectBrief from "../../utils/projects/SaveProjectBrief";
import SaveConcept from "../../utils/projects/SaveConcept";
import SaveConceptQuestion from "../../utils/projects/SaveConceptQuestion";
import SaveConceptAnswer from "../../utils/projects/SaveConceptAnswer";
import SaveQuestions from "../../utils/projects/SaveQuestions";
import SaveAnswers from "../../utils/projects/SaveAnswers";
import GetProjectBriefs from "../../utils/projects/GetProjectBriefs";

import ProjectInterviewResultsForConcepts from "./ProjectInterviewResultsForConcepts";
import ProjectSurveyResultsForConcepts from "./ProjectSurveyResultsForConcepts";
import ProjectInterviewResultsForInsights from "./ProjectInterviewResultsForInsights";
import ProjectSurveyResultsForInsights from "./ProjectSurveyResultsForInsights";

import GetUser from "../../utils/users/GetUser";
import ProjectInterviewQuestions from "./ProjectInterviewQuestions";
import ProjectSurveyQuestions from "./ProjectSurveyQuestions";
import GetPersonasBySegmentID from "../../utils/personas/GetPersonasBySegmentID";
import GetPanelistsbyPanelId from "../../utils/panels/GetPanelistsbyPanelId";
import GetInsightAnswersByPanelistID from "../../utils/projects/GetInsightAnswersByPanelistID";
import { CreateThreadLLM } from "../../utils/llmcall/CreateThreadLLM";
import { CreateMessageLLM } from "../../utils/llmcall/CreateMessageLLM";
import SaveLLMThreadDB from "../../utils/llmcall/SaveLLMThreadDB";
import GetLLMAssistantByPanelistID from "../../utils/llmcall/GetLLMAssistantByPanelistID";
import GetLLMAssistantByPersonaID from "../../utils/llmcall/GetLLMAssistantByPersonaID";

// interview questions for concept testing
const interviewQuestionsForConcepts = [
    {id: 1, question: "How appealing is the concept to you?"},
    {id: 2, question: "How unique is the concept to you?"},
    {id: 3, question: "How likely are you to purchase the concept?"},
];

// survey questions for concept testing
const surveyQuestionsForConcepts = [
    {id: 1, question: "How appealing is the concept to you?", 
        options: [{id: 1, option: "Very unappealing"}, {id: 2, option: "Somewhat unappealing"}, {id: 3, option: "Neither appealing nor unappealing"}, {id: 4, option: "Somewhat appealing"}, {id: 5, option: "Very appealing"}]},
    {id: 2, question: "How unique is the concept to you?", type: "rating",
        options: [{id: 1, option: "Not unique at all"}, {id: 2, option: "Not that unique"}, {id: 3, option: "Neither unique nor common"}, {id: 4, option: "Somewhat unique"}, {id: 5, option: "Very unique"}]},
    {id: 3, question: "How likely are you to purchase the concept?", type: "rating",
        options: [{id: 1, option: "Very unlikely"}, {id: 2, option: "Somewhat unlikely"}, {id: 3, option: "Neither likely nor unlikely"}, {id: 4, option: "Somewhat likely"}, {id: 5, option: "Very likely"}]},
];

// project workflow state
export const WorkflowStatus = {
    brief:"brief",
    concepts:"concepts",
    conceptQuestions: "conceptQuestions",
    questions:"questions",
    interviewQuestions: "interviewQuestions",
    surveyQuestions: "surveyQuestions",
    results: "results",
    interviewResults: "interviewResults",
    surveyResults: "surveyResults",
    cloading: "cloading",
    qloading: "qloading",
    rloading: "rloading",
    rcusloading: "rcusloading",
    cusresults: "cusresults",
}

// openai models
const model = "gpt-3.5-turbo";
const model2 = "gpt-4";

const NewProjectWorkflow = (props) => {
    console.log("New Project Workflow");
    console.log(props);
    
    // workflow status
    const [workflowStatus, setWorkflowStatus] = useState(WorkflowStatus.brief);
    // user
    const [user, setUser] = useState(null); 
    // project results
    const [projectResults, setProjectResults] = useState([]);
    // project title
    const [projectTitle, setProjectTitle] = useState(null);
    // project type - concept testing or consumer understanding or message testing
    const [projectType, setProjectType] = useState(null);
    // project method - Qualitative or Quantitative
    const [projectMethod, setProjectMethod] = useState(null);
    // project questions    
    const [projectQuestions, setProjectQuestions] = useState([]);
    // project concepts
    const [projectConcepts, setProjectConcepts] = useState([]);
    // project objective
    const [projectObjective, setProjectObjective] = useState(null);
    // project segment
    const [projectSegment, setProjectSegment] = useState(null);
    // project personas
    const [projectPersonas, setProjectPersonas] = useState([]);
    // project panels
    const [projectPanel, setProjectPanel] = useState([]);
    // project panels
    const [projectPanelists, setProjectPanelists] = useState([]);
    // audience
    const [projectAudience, setProjectAudience] = useState(null);
    // respondents
    const [respondents, setRespondents] = useState([]);
    // loading
    const [loadingAnswers, setLoadingAnswers] = useState(false);
    // list all segments
    const [segments, setSegments] = useState([]);
    // list all panels
    const [panels, setPanels] = useState([]);
    // form data
    const [formData, setFormData] = useState(null);
    // question count
    const [questionCount, setQuestionCount] = useState(0);
    // concept count
    const [conceptCount, setConceptCount] = useState(0);

    // fetch user
    useEffect(() => {     
        
        const fetchUser = async() => {
            const user = await GetUser();
            setUser(user);
        }

        const fetchSegments = async() => {
            GetSegments().then((segments) => {
                setSegments(segments);
            });
        }

        const fetchPanels = async() => {
            GetPanels().then((panels) => {
                setPanels(panels);
            });
        }

        fetchUser();
        fetchSegments();
        fetchPanels();

        console.log("User: ", user);
        console.log("Segments: ", segments);
        console.log("Panels: ", panels);
        
    },[])

    function format_llm_response(text) {
        // parse json response
        let concepts = JSON.parse(text);

        console.log(concepts);
        return concepts;
    }

    async function generateConcepts(data) {
        
        setWorkflowStatus(WorkflowStatus.cloading);

        const projectPrompt = "Name of project : " + projectTitle + ". Objective: " + projectObjective + ".";

        const conceptPrompt = "You are a market researcher. You are tasked with coming up with " + conceptCount + " concepts for the following objective: " + projectPrompt + ". ";
        
        const systemPrompt = `Each concept is depicted as <Concept>. Return in JSON format. For example, [{"id": 1, "description": "Concept 1 and a succint description"}, {"id": 2, "description": "Concept 2 and a succint description"},...]`;
            
        let response;

        /*
        * Using ASSISTANT MODE
        */
        console.log(props.MRAssistantID);
        // get the MR Assistant
        const assistantDB = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.getLLMAssistant,
            variables: {
                id: props.MRAssistantID,
            }
        });
        console.log("Assistant: ", assistantDB.data.getLLMAssistant);
        const LLMAssistant = assistantDB.data.getLLMAssistant.assistantID;
        console.log("Assistant ID: ", LLMAssistant);

        // create a thread
        let dataToLLM={}
        dataToLLM.model = model;
        dataToLLM.temperature = 0.5;
        dataToLLM.mode = "assistant";
        dataToLLM.llm_data = {
            assistantMode: "createThread",
        }
        const threadData =  await CreateThreadLLM(dataToLLM);
        // save thread to DB
        const threadDBData = {
            threadID: threadData.threadID,
            owner: user.attributes.email,
            assistantID: LLMAssistant,
        }
        console.log("Thread in DB data: ", threadDBData)
        const threadDBID = await SaveLLMThreadDB(threadDBData).catch(error => {
            console.error('Error saving LLM Thread:', error);
            console.error('Error details:', error.errors);
        });
        console.log("Thread in DB info: ", threadDBID);
        let messageData = {};
        messageData.model = model;
        messageData.temperature = 0.5;
        messageData.mode = "assistant";
        messageData.llm_data = {
            assistantMode: "createMessage",
            threadID: threadData.threadID,
            assistantID: LLMAssistant,
            /*message: 'This is start of a customer segmentation project. Your objective is to segment the ' + n_panelists + ' respondents from a qualitative research into customer segments. ' + 
                    'You will be provided with the respondent profiles and interview transcripts one at a time. ' +
                    'You will identify key themes and patterns in the profiles and use these to create distinct personas.'
            */
        };

        // get the concepts from LLM
        /* 
        * Using SIMPLE MODE
        */
       /* try {
            response = await API.post('openaigptapi', '/chat', {
                    body: {
                    input: {
                        question: conceptPrompt,
                        system_prompt: systemPrompt, 
                        history: "",
                        model: model,
                        mode: "simple",
                        sources: [],
                    }
                },
                });
            } catch(err) {
                console.error(err);
            }
            
        const conceptsFromLLM = response.Answer.trim();  
        console.log("Concepts returned from LLM: " + conceptsFromLLM);
        const formattedConcepts = format_llm_response(conceptsFromLLM);
        */
        messageData.llm_data.message = conceptPrompt + systemPrompt;
        let messageResponse = await CreateMessageLLM(messageData);
        const formattedConcepts = messageResponse[0].message;
        
        console.log("Concepts after formatting: ");
        console.log(formattedConcepts);

        setProjectConcepts(formattedConcepts);   
        setWorkflowStatus(WorkflowStatus.concepts);           
    }

    function editConcept(id, text) {
        //console.log("edit button");
        const newConcepts = projectConcepts.map(concept => 
            concept.id === id ? { ...concept, description: text } : concept
        );
        setProjectConcepts(newConcepts);
    }

    function deleteConcept(id) {
        let newConcepts = projectConcepts.filter(concept => concept.id !== id);
        newConcepts = newConcepts.map((concept, index) => ({ ...concept, id: index + 1 }));
        setProjectConcepts(newConcepts);
    }

    function addConcept(newConcept) {   
        const newConcepts = [...projectConcepts, { id: projectConcepts.length + 1, description: newConcept }];
        setProjectConcepts(newConcepts);
        //console.log(newQuestions);
    }

    async function generateQuestionsForConcepts(data) {
        
        console.log("Generating questions for concepts");
        console.log(data);
        console.log(props.MRAssistantID);


        setWorkflowStatus(WorkflowStatus.qloading);

        
        if (projectMethod === "Qualitative") {
            setProjectQuestions(interviewQuestionsForConcepts);
            setWorkflowStatus(WorkflowStatus.interviewQuestions);
        } else {
            console.log("Survey questions for concepts: ", surveyQuestionsForConcepts);
            setProjectQuestions(surveyQuestionsForConcepts);
            setWorkflowStatus(WorkflowStatus.surveyQuestions);
        }
    }

    async function generateResultsForConcepts() {        
        
        console.log("Generating results for concepts");

        setLoadingAnswers(true);

        console.log("Project Title: ", projectTitle);
        console.log("Project Objective: ", projectObjective);
        console.log("Project Audience: ", projectAudience);
        console.log("Project Type: ", projectType);
        console.log("Project Method: ", projectMethod);
        console.log("Project Concepts: ", projectConcepts);
        console.log("Project Questions: ", projectQuestions);

        // save project brief in the db
        const projectBriefDB = {
            // id will be auto-populated by AWS
            owner: user.attributes.email,
            title: projectTitle,
            objective: projectObjective,
            audience: projectAudience,
            type: projectType,
            method: projectMethod
        }
        console.log("Concept Project Brief before saving to DB: ")
        console.log(projectBriefDB);
        const projectBriefID = await SaveProjectBrief(projectBriefDB);
        console.log("Concept Project Brief ID: ")
        console.log(projectBriefID);

        // save the concepts to the db
        for (let i=0; i < projectConcepts.length; ++i) {            

            // save project concepts
            const conceptDB = {
                // id will be auto-populated by AWS
                projectbriefID: projectBriefID,
                description: projectConcepts[i].description,            
            }
            // save project brief to the db
            const conceptID = await SaveConcept(conceptDB);
            projectConcepts[i].id = conceptID;
        }

        // save the questions to the db
        for (let i=0; i < projectQuestions.length; ++i) {
            // save question to db
            const questionDB = {
                // id will be auto-populated by AWS
                projectbriefID: projectBriefID,
                question: projectQuestions[i].question,
                method: projectMethod,
                options: JSON.stringify(projectQuestions[i].options),
            }
            // save questionDB to DB
            const questionID = await SaveConceptQuestion(questionDB);  
            projectQuestions[i].id = questionID;
        }

        //console.log(respondents);

        setWorkflowStatus(WorkflowStatus.rloading);

        // initiate a conversation with each respondent in their LLM
        for (let i = 0 ; i < respondents.length; ++i) {
            let assistantID; 
            // get the LLM assistant for this respondent and save it
            if (projectAudience === "Panels") {
                // get the LLM Assistant for this panelist
                const assistant = await GetLLMAssistantByPanelistID(respondents[i].id);
                assistantID = assistant.assistantID;            
            } else {
                const assistant = await GetLLMAssistantByPersonaID(respondents[i].id);
                assistantID = assistant.assistantID;
            }
            console.log("LLM Assistant ID: ", assistantID);
            respondents[i].assistantID = assistantID;

            // create a new thread for this project for this respondent
            // create a new thread for this project for this respondent
            let response = await CreateThreadLLM({
                model: model, 
                temperature: 0.5, 
                mode: "assistant", 
                llm_data: {
                    assistantID: assistantID, 
                    assistantMode: "createThread",
                }
            });
            let threadID = response.threadID;
            console.log("Thread ID: ", threadID);
            // Add the thread to the DB
            const threadDB = {
                // id will be auto-populated by AWS
                owner: user.attributes.email,
                threadID: threadID,
                assistantID: assistantID,
                projectbriefID: projectBriefID,
            }
            const threadIDDB = await SaveLLMThreadDB(threadDB);
            console.log("Thread ID DB: ", threadIDDB);

            // save to this respondent
            respondents[i].threadID = threadID;

            // create a message to initiate the conversation
            // create a message in the thread for this project for this respondent
            let message = "You are respondent for Concept Testing project titled: " + projectTitle + ". For each concept, set of questions will be asked. Answer questions based on your profile and what appeals to you.";
            // pass the message to the thread to initiate the conversation
            let result = await CreateMessageLLM({
                model: model,
                temperature: 0.5,
                mode: "assistant",
                llm_data: {
                    assistantID: assistantID,
                    assistantMode: "createMessage",
                    threadID: threadID,
                    message: message,
                }
            });
            console.log("LLM response: ",   result); 

            // save to the message DB          

        }

        // create a JSON object for concept. First an empty JSON object
        //let questionAnswerDB = [];
        for (let i=0; i < projectConcepts.length; ++i) {

            let concept = projectConcepts[i].description;

            // create 2D array for partial results
            let partialResult = new Array(projectQuestions.length).fill().map(() => []);            

            // loop through each respondent
            for (let j=0; j < respondents.length;++j) {
                // get the LLM assistant for this respondent
                //console.log("Respondent: ", respondents[j]);
                let threadID = respondents[j].threadID;
                let assistantID = respondents[j].assistantID;
                
                // create a message in the thread for this project for this respondent
                let message = '';
                let result;
                //let method_prompt = '';
                /*if (projectMethod === "Qualitative") {
                    method_prompt = 'Answer the given question and provide details if necessary, without describing your profile.';
                    //message += method_prompt;
                } else {
                    method_prompt = `Each question comes with several options to select from. 
                                    The options are provied in JSON format. 
                                    Answer the question by returing JSON that represents the chosen option, for example, {"id": 1, "option": "Option 1"}
                                    If the question asks you to select multiple options, return an array of JSON objects, for example, [{"id": 1, "option": "Option 1"}, {"id": 2, "option": "Option 2"}]
                                    If the question asks you to rank the options, return an array of JSON objects ordered according to your preference, for example, [{"id": 1, "option": "Most preferred option"}, {"id": 2, "option": "Second most preferred option"}]`;
                
                    //message += 'Each question comes with several options to select from. The options are provied in JSON format. Answer the question by returing JSON that represents the chosen option, for example, {"id": 1, "option": "Option 1"} If the question asks you to select multiple options, return an array of JSON objects, for example, [{"id": 1, "option": "Option 1"}, {"id": 2, "option": "Option 2"}] If the question asks you to rank the options, return an array of JSON objects ordered according to your preference, for example, [{"id": 1, "option": "Most preferred option"}, {"id": 2, "option": "Second most preferred option"}]';
                }*/
                // create respondent profile by adding up all only those components that are not null, in a string format
                /*let respondentProfile ={
                    name: respondents[j].name,
                    age: respondents[j].age,
                    age_range: respondents[j].age_range,
                    gender: respondents[j].gender,
                    race: respondents[j].race,
                    education: respondents[j].education,
                    employment_status: respondents[j].employment_status,
                    marital_status: respondents[j].marital_status,
                    household_type: respondents[j].household_type,
                    household_size: respondents[j].household_size,
                    city: respondents[j].city,
                    state: respondents[j].state,
                    region: respondents[j].region,
                    zipcode: respondents[j].zipcode,
                    income: respondents[j].income,
                    income_range: respondents[j].income_range,
                    asset_range: respondents[j].asset_range,
                    net_worth: respondents[j].net_worth,
                    networth_range: respondents[j].networth_range,
                    interests: respondents[j].interests,
                    personality: respondents[j].personality,
                    values: respondents[j].values,
                    attitudes: respondents[j].attitudes,
                    social: respondents[j].social,
                    lifestyle: respondents[j].lifestyle,
                    backstory: respondents[j].backstory,
                    history: respondents[j].history,
                }

                let profileForLLM = '';

                for (let key in respondentProfile) {
                    if (respondentProfile[key] !== null && respondentProfile[key] !== "" && respondentProfile[key] !== "undefined") {
                        profileForLLM += `${key}: ${respondentProfile[key]}. `;
                    }
                }
                */

                // get answers for each panelist
                /*
                for (let i=0; i < respondents.length; ++i) {
                    GetInsightAnswersByPanelistID(respondents[i].id).then((result) => {
                        // convert JSON string to JSON object
                        const answerArray = JSON.parse(result.answer);
                        // save question to profileForLLM
                        profileForLLM += result.question + ": ";
                        // loop through the JSON object array and save each answer to profileForLLM
                        profileForLLM += answerArray.map((option) => {
                            return option.option + ". ";
                        }).join("");
                    });
                }*/

                /*console.log("Profile for LLM: ", profileForLLM);

                let system_prompt = 
                    "You are a respondent to a market research study. You have the following profile: \n" 
                    + profileForLLM + "\n"
                    + method_prompt;
                */
                for (let k=0; k < projectQuestions.length; ++k) {  
                    if (projectMethod === "Qualitative") {
                        message = 'Answer the given question and provide details if necessary, without describing your profile.'
                    } else {
                        message = 'Each question comes with several options to select from. The options are provied in JSON format. Answer the question by returing JSON that represents the chosen option, for example, {"id": 1, "option": "Option 1"} If the question asks you to select multiple options, return an array of JSON objects, for example, [{"id": 1, "option": "Option 1"}, {"id": 2, "option": "Option 2"}] If the question asks you to rank the options, return an array of JSON objects ordered according to your preference, for example, [{"id": 1, "option": "Most preferred option"}, {"id": 2, "option": "Second most preferred option"}]';
                    }

                    const questionOptions = JSON.stringify(projectQuestions[k].options);

                    let question_prompt = '';
                    if (projectMethod === "Qualitative") {
                        question_prompt =  'For concept: ' + concept + '- answer this question: ' + projectQuestions[k].question 
                        message += ' ' + question_prompt;
                    } else {
                        question_prompt = 'For concept: ' + concept + ', choose one or more options from the following: ' + questionOptions + ' for this question: ' +  projectQuestions[k].question;
                        message += ' ' + question_prompt;
                    } 
                    /*
                    * Using ASSISTANT MODE
                    */
                   // pass the message to the thread to answer this question
                    result = await CreateMessageLLM({
                        model: model,
                        temperature: 0.5,
                        mode: "assistant",
                        llm_data: {
                            assistantID: assistantID,
                            assistantMode: "createMessage",
                            threadID: threadID,
                            message: message,
                        }
                    });
                    console.log("LLM ASSISTANT response: ",   result);             
                    /*
                    * Using SIMPLE MODE
                    */
                   /* try {
                        result = await API.post('openaigptapi', '/chat', {
                            body: {
                            input: {
                                question: question_prompt,
                                system_prompt: system_prompt,
                                history: "",
                                model: model,                
                                mode: "simple",
                                sources: [],
                            }
                        },
                        });
                    } catch(err) {
                        console.error(err);
                    }

                    const answer = result.Answer.trim();*/
                    let answer = "";
                    if (result.length > 0){
                        if (result[0].role === "assistant") {
                            answer = result[0].message;
                        }
                    }
                    let answerParsed; 
                    if (projectMethod === "Qualitative") {
                        answerParsed = answer;
                         // save current question and answer to db
                        partialResult[k].push( {
                            concept: projectConcepts[i].description,
                            name: respondents[j].name,
                            question: projectQuestions[k].question,
                            answer: answerParsed,
                        })
                    } else {
                        answerParsed = JSON.parse(answer);
                         // save current question and answer to db
                        partialResult[k].push( {
                            concept: projectConcepts[i].description,
                            name: respondents[j].name,
                            question: projectQuestions[k].question,
                            answer: answerParsed.option,
                        })
                    }

                    //console.log("System Prompt: " + system_prompt)
                    //console.log("Question: ", projectQuestions[k].question);
                    //console.log("Options: ", projectQuestions[k].options);
                    //console.log("Answer: " + answerParsed.option); 

                    const conceptAnswerDB = {
                        // id will be auto-populated by AWS
                        answer: answer,
                        conceptsID: projectConcepts[i].id,
                        conceptquestionsID: projectQuestions[k].id,
                        projectbriefID: projectBriefID,
                    }
                    if (projectAudience === "Panels") {
                        conceptAnswerDB.panelistID = respondents[j].id;
                        conceptAnswerDB.customerpersonaID = 0;
                    } else {
                        conceptAnswerDB.customerpersonaID = respondents[j].id;
                        conceptAnswerDB.panelistID = 0;
                    }
                    const conceptAnswerID = await SaveConceptAnswer(conceptAnswerDB);
                    //console.log("Concept Answer ID: " + conceptAnswerID);                    

                }                
            }
            setProjectResults(prevArr => [...prevArr, partialResult]);   
            //console.log(partialResult);

        }

        setLoadingAnswers(false);

        //setWorkflowStatus(WorkflowStatus.results);
    }

    async function generateQuestionsForInsights(data) {
                
        console.log("Generating questions for Customer Insights");
        console.log(props.MRAssistantID);
        // get the MR Assistant
        const assistantDB = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.getLLMAssistant,
            variables: {
                id: props.MRAssistantID,
            }
        });
        console.log("Assistant: ", assistantDB.data.getLLMAssistant);
        const LLMAssistant = assistantDB.data.getLLMAssistant.assistantID;
        console.log("Assistant ID: ", LLMAssistant);

        // create a thread
        let dataToLLM={}
        dataToLLM.model = model;
        dataToLLM.temperature = 0.5;
        dataToLLM.mode = "assistant";
        dataToLLM.llm_data = {
            assistantMode: "createThread",
        }
        const threadData =  await CreateThreadLLM(dataToLLM);
        // save thread to DB
        const threadDBData = {
            threadID: threadData.threadID,
            owner: user.attributes.email,
            assistantID: LLMAssistant,
        }
        console.log("Thread in DB data: ", threadDBData)
        const threadDBID = await SaveLLMThreadDB(threadDBData).catch(error => {
            console.error('Error saving LLM Thread:', error);
            console.error('Error details:', error.errors);
        });
        console.log("Thread in DB info: ", threadDBID);
        let messageData = {};
        messageData.model = model;
        messageData.temperature = 0.5;
        messageData.mode = "assistant";
        messageData.llm_data = {
            assistantMode: "createMessage",
            threadID: threadData.threadID,
            assistantID: LLMAssistant,
            /*message: 'This is start of a customer segmentation project. Your objective is to segment the ' + n_panelists + ' respondents from a qualitative research into customer segments. ' + 
                    'You will be provided with the respondent profiles and interview transcripts one at a time. ' +
                    'You will identify key themes and patterns in the profiles and use these to create distinct personas.'
            */
        };


        setWorkflowStatus(WorkflowStatus.qloading);

        console.log(projectMethod);

        const projectPrompt = "Project title : " + projectTitle + ". Project objective : " + projectObjective + ".";

        if (projectMethod === "Qualitative") {

            /*const systemPrompt =   "You are a market researcher. " +
                                    "You are tasked with generating " + questionCount + " questions for a qualitative research project. " +
                                    "Frame the question in such a way that it is open-ended and allows the respondent to express their thoughts and feelings. " +
                                    'Each question must be returned in the following JSON format: [{"id": 1, "question": "Question 1"}...]';
            
            const dataToLLM = {
                model: model,
                question: projectPrompt,
                system_prompt: systemPrompt,
                history: "",
                sources: [],
                temperature: 0.4,
            }
            */

            messageData.llm_data.message = "You are a market researcher. " +
            "You are tasked with generating " + questionCount + " questions for a qualitative research project. " +
            "Frame the question in such a way that it is open-ended and allows the respondent to express their thoughts and feelings. " +
            'Each question must be returned in the following JSON format: [{"id": 1, "question": "Question 1"}...].' + 
            'Here is project brief and project objective: ' + projectPrompt;

            //console.log("Data to LLM: ", dataToLLM);

            console.log("Message data: ", messageData);
            let messageDataResponse = await CreateMessageLLM(messageData);
            console.log("Message response: ", messageDataResponse);
            let parsedResponseFromLLM = JSON.parse(messageDataResponse[0].message);

            //call the LLM
            //let parsedResponseFromLLM = await SimpleLLMCallJSON(dataToLLM);
            //const arrayResponsefromLLM = JSON.parse(parsedResponseFromLLM);
            console.log("Questions returned from LLM: ", parsedResponseFromLLM);
            //const formattedQuestions = format_llm_response(questionsFromLLM);
            //console.log("Questions after formatting: ");
            //console.log(formattedQuestions);
            
            setProjectQuestions(parsedResponseFromLLM);
            
            setWorkflowStatus(WorkflowStatus.interviewQuestions);

        } else {

            /*const systemPrompt =   "You are a market researcher. " +
                                    "You are tasked with generating " + questionCount + " questions for a quantitative research project. " +
                                    "Each question must come with a list of options that a respondent can select from." + 
                                    'Return each question in the following JSON format: [{"id": 1, "question": "Question 1", "options": [{"id": 1, "option": "Option description"}...]}...]. Do not include any open-ended questions in the list of options.';
            
            const dataToLLM = {
                model: model,
                question: projectPrompt,
                system_prompt: systemPrompt,
                history: "",
                sources: [],
                temperature: 0.4,
            }

            console.log("Data to LLM: ", dataToLLM);
            */
            messageData.llm_data.message = "You are a market researcher. You are tasked with generating " + questionCount + " questions for a quantitative research project. " +
            "Each question must come with a list of options that a respondent can select from." + 
            'Return each question in the following JSON format: [{"id": 1, "question": "Question 1", "options": [{"id": 1, "option": "Option description"}...]}...]. Do not include any open-ended questions in the list of options.'
            + 'Here is project brief and project objective: ' + projectPrompt;

            console.log("Message data: ", messageData);
            let messageDataResponse = await CreateMessageLLM(messageData);
            console.log("Message response: ", messageDataResponse);
            let parsedResponseFromLLM = messageDataResponse[0].message;


            //call the LLM
            /*
            * Using SIMPLE MODE
            */
            //let parsedResponseFromLLM = await SimpleLLMCallJSON(dataToLLM);
            //const arrayResponsefromLLM = Array.isArray(parsedResponseFromLLM) ? parsedResponseFromLLM : [parsedResponseFromLLM];
            console.log("Questions returned from LLM: ", parsedResponseFromLLM);
            
            setProjectQuestions(parsedResponseFromLLM);
            
            setWorkflowStatus(WorkflowStatus.surveyQuestions);

        }
    }

    async function generateResultsForInsights(data) {

        console.log("Generating results for insights");
        console.log(data);

        setLoadingAnswers(true);

        setWorkflowStatus(WorkflowStatus.rloading);
        
        // save project brief in the db
        const projectBriefDB = {
            // id will be auto-populated by AWS
            owner: user.attributes.email,
            title: projectTitle,
            objective: projectObjective,
            audience: projectAudience,
            type: projectType,
            method: projectMethod
        }
        console.log("Concept Project Brief before saving to DB: ")
        console.log(projectBriefDB);
        // save project brief to the db
        const projectBriefID = await SaveProjectBrief(projectBriefDB);
        console.log("Concept Project Brief ID: ")
        console.log(projectBriefID);

        // initiate a conversation with each respondent in their LLM
        for (let i = 0 ; i < respondents.length; ++i) {
            let assistantID; 
            // get the LLM assistant for this respondent and save it
            if (projectAudience === "Panels") {
                // get the LLM Assistant for this panelist
                const assistant = await GetLLMAssistantByPanelistID(respondents[i].id);
                assistantID = assistant.assistantID;            
            } else {
                const assistant = await GetLLMAssistantByPersonaID(respondents[i].id);
                assistantID = assistant.assistantID;
            }
            console.log("LLM Assistant ID: ", assistantID);
            respondents[i].assistantID = assistantID;

            // create a new thread for this project for this respondent
            // create a new thread for this project for this respondent
            let response = await CreateThreadLLM({
                model: model, 
                temperature: 0.5, 
                mode: "assistant", 
                llm_data: {
                    assistantID: assistantID, 
                    assistantMode: "createThread",
                }
            });
            let threadID = response.threadID;
            console.log("Thread ID: ", threadID);
            // Add the thread to the DB
            const threadDB = {
                // id will be auto-populated by AWS
                owner: user.attributes.email,
                threadID: threadID,
                assistantID: assistantID,
                projectbriefID: projectBriefID,
            }
            const threadIDDB = await SaveLLMThreadDB(threadDB);
            console.log("Thread ID DB: ", threadIDDB);

            // save to this respondent
            respondents[i].threadID = threadID;

            // create a message to initiate the conversation
            // create a message in the thread for this project for this respondent
            let message = "You are respondent for Consumer Insights project titled: " + projectTitle + ". A set of questions will be asked as part of the project. Answer questions based on your profile and what appeals to you. This messae is just to initiate this thread and questions will be asked in following messages";
            // pass the message to the thread to initiate the conversation
            let result = await CreateMessageLLM({
                model: model,
                temperature: 0.5,
                mode: "assistant",
                llm_data: {
                    assistantID: assistantID,
                    assistantMode: "createMessage",
                    threadID: threadID,
                    message: message,
                }
            });
            console.log("LLM response: ",   result); 

            // save to the message DB          

        }

        // save the questions to the db
        for (let i=0; i < projectQuestions.length; ++i) {
            // save question to db
            const questionDB = {
                // id will be auto-populated by AWS
                projectbriefID: projectBriefID,
                question: projectQuestions[i].question,
                method: projectMethod,
                options: JSON.stringify(projectQuestions[i].options),
            }
            // save questionDB to DB
            const questionID = await SaveQuestions(questionDB);  
            console.log("Question ID: " + questionID);
            projectQuestions[i].id = questionID;
        }

        console.log(respondents);

        // create 2D array for partial results
        let partialResult = new Array(projectQuestions.length).fill().map(() => []);            

        // loop through each respondent
        for (let j=0; j < respondents.length;++j) {

            let threadID = respondents[j].threadID;
            let assistantID = respondents[j].assistantID;
                
            // create a message in the thread for this project for this respondent
            let start_message = '';
            
            let result;

            let method_prompt = "";
            if (projectMethod === "Qualitative") {
                method_prompt = "Answer the given question, without describing your profile.";
                start_message += method_prompt;
            } else {
                method_prompt = `Each question comes with several options to select from. 
                                    The options are provied in JSON format. 
                                    Answer the question by returing JSON that represents the chosen option, for example, {"id": 1, "option": "Option 1"}.
                                    If the question asks you to select multiple options (e.g. "Select all that apply"), return an array of JSON objects, for example, [{"id": 1, "option": "Option 1"}, {"id": 2, "option": "Option 2"}]
                                    If the question asks you to rank the options, return an array of JSON objects ordered according to your preference, for example, [{"id": 1, "option": "Most preferred option"}, {"id": 2, "option": "Second most preferred option"}]`;
            
                start_message += method_prompt;  
            }

            // create respondent profile by adding up all only those components that are not null, in a string format
            /*let respondentProfile ={
                name: respondents[j].name,
                age: respondents[j].age,
                age_range: respondents[j].age_range,
                gender: respondents[j].gender,
                race: respondents[j].race,
                education: respondents[j].education,
                employment_status: respondents[j].employment_status,
                marital_status: respondents[j].marital_status,
                household_type: respondents[j].household_type,
                household_size: respondents[j].household_size,
                city: respondents[j].city,
                state: respondents[j].state,
                region: respondents[j].region,
                zipcode: respondents[j].zipcode,
                income: respondents[j].income,
                income_range: respondents[j].income_range,
                asset_range: respondents[j].asset_range,
                net_worth: respondents[j].net_worth,
                networth_range: respondents[j].networth_range,
                interests: respondents[j].interests,
                personality: respondents[j].personality,
                values: respondents[j].values,
                attitudes: respondents[j].attitudes,
                social: respondents[j].social,
                lifestyle: respondents[j].lifestyle,
                backstory: respondents[j].backstory,
            }

            let profileForLLM = '';

            for (let key in respondentProfile) {
                if (respondentProfile[key] !== null && respondentProfile[key] !== "" && respondentProfile[key] !== "undefined") {
                    profileForLLM += `${key}: ${respondentProfile[key]}. `;
                }
            }

            console.log("Profile for LLM: ", profileForLLM);

            let system_prompt = 
                "You are a respondent to a market research study. You have the following profile: " + "\n" 
                + profileForLLM + "\n"
                + method_prompt;

            console.log("System Prompt: ", system_prompt);*/
            
            for (let k=0; k < projectQuestions.length; ++k) {  

                console.log("Question: ", projectQuestions[k].question);
                let message = start_message;

                let question_prompt = "";
                if (projectMethod === "Qualitative") {
                    question_prompt =  "Answer this question: " + projectQuestions[k].question 
                    message += ' ' + question_prompt;
                } else {
                    const questionOptions = JSON.stringify(projectQuestions[k].options);
                    console.log("Options: ", questionOptions);
                    question_prompt = "Answer this question: " +  projectQuestions[k].question + ", by choosing one or more options from the following: " + questionOptions;
                    message += ' ' + question_prompt;
                }              

                console.log("Question Prompt: ", question_prompt);
                /*
                * Using ASSISTANT MODE
                */
                // pass the message
                result = await CreateMessageLLM({
                    model: model,
                    temperature: 0.5,
                    mode: "assistant",
                    llm_data: {
                        assistantID: assistantID,
                        assistantMode: "createMessage",
                        threadID: threadID,
                        message: message,
                    }
                });
                console.log("LLM response: ",   result);
                /*
                * Using SIMPLE MODE
                */
                /*try {
                    result = await API.post('openaigptapi', '/chat', {
                        body: {
                        input: {
                            question: question_prompt,
                            system_prompt: system_prompt,
                            history: "",
                            model: model,                
                            mode: "simple",
                            sources: [],
                        }
                    },
                    });
                } catch(err) {
                    console.error(err);
                }

                const answer = result.Answer.trim();*/
                //const answerParsed = JSON.parse(answer);
                let answer = "";
                if (result.length > 0){
                    if (result[0].role === "assistant") {
                        answer = result[0].message;
                    }
                }

                console.log("Answer: " + answer);
                //console.log("Answer parsed: " + answerParsed.option);

                // save current question and answer to db
                partialResult[k].push( {
                    name: respondents[j].name,
                    question: projectQuestions[k].question,
                    answer: answer,
                })

                console.log("Partial Result: ", partialResult[k]);

                const answerDB = {
                    // id will be auto-populated by AWS
                    answer: answer,
                    questionsID: projectQuestions[k].id,
                    projectbriefID: projectBriefID,
                }
                if (projectAudience === "Panels") {
                    answerDB.panelistID = respondents[j].id;
                    answerDB.customerpersonaID = 0;
                } else {
                    answerDB.customerpersonaID = respondents[j].id;
                    answerDB.panelistID = 0;
                }

                console.log("Answer before saving to DB: ", answerDB);
                const answerID = await SaveAnswers(answerDB);
                console.log("Answer ID: " + answerID);                    

            }                
        }
        setProjectResults(partialResult);   
        console.log(partialResult);

        setLoadingAnswers(false);
    }

    function editInterviewQuestion(id, text) {
        //console.log("edit button");
        const newQuestions = projectQuestions.map(question => 
            question.id === id ? { ...question, question: text } : question
        );
        setProjectQuestions(newQuestions);
    }

    function deleteInterviewQuestion(id) {
        let newQuestions = projectQuestions.filter(question => question.id !== id);
        newQuestions = newQuestions.map((question, index) => ({ ...question, id: index + 1 }));
        setProjectQuestions(newQuestions);
    }

    function addInterviewQuestion(newQuestion) {   
        const newQuestions = [...projectQuestions, { id: projectQuestions.length + 1, question: newQuestion }];
        setProjectQuestions(newQuestions);
        //console.log(newQuestions);
    }

    function editSurveyQuestion(id, text, options) {

        console.log("edit button clicked", id, text, options);
        const newQuestions = projectQuestions.map(question => 
            question.id === id ? { ...question, question: text, options: options } : question,
        );
        setProjectQuestions(newQuestions);
    }

    function deleteSurveyQuestion(id) {
        let newQuestions = projectQuestions.filter(question => question.id !== id);
        newQuestions = newQuestions.map((question, index) => ({ ...question, id: index + 1 }));
        setProjectQuestions(newQuestions);
    }

    function addSurveyQuestion(newQuestion, newOptions) {   
        const newQuestions = [...projectQuestions, { id: projectQuestions.length + 1, question: newQuestion, options: newOptions }];
        setProjectQuestions(newQuestions);
    }

    const handleSubmit = (data) => {
        
        console.log(data);
        setFormData(data);
        
    }

    useEffect(() => {

        if (formData === null) return;

        setProjectTitle(formData.name);
        setProjectType(formData.type);
        setProjectObjective(formData.objective);
        setProjectMethod(formData.method);
        setProjectAudience(formData.audience);
        setProjectSegment(formData.segment);
        setProjectPanel(formData.panel);
        setConceptCount(formData.conceptCount);
        setQuestionCount(formData.questionCount);

        if (formData.audience === "Personas") {
            GetPersonasBySegmentID(formData.segment.id).then((personas) => {
                setRespondents(personas);
            });
        } else {
            GetPanelistsbyPanelId(formData.panel.id).then((panelists) => {
                setRespondents(panelists);
            });
        }

        if (projectType === "Concept Testing") {
            if (formData.conceptCount > 0) {
                generateConcepts(formData);
            } else {
                setWorkflowStatus(WorkflowStatus.concepts);
            }
        }
        else if (projectType === "Customer Insights") {
            if (formData.questionCount > 0) {
                generateQuestionsForInsights(formData);
            } else {
                switch (projectMethod) {
                    case "Qualitative":
                        setWorkflowStatus(WorkflowStatus.interviewQuestions);
                        break;
                    case "Quantitative":
                        setWorkflowStatus(WorkflowStatus.surveyQuestions);
                        break;
                    default:
                        setWorkflowStatus(WorkflowStatus.interviewQuestions);
                        break;
                }
            }
        }
    }, [formData, projectType]);

    return (
        <div className="flex flex-row w-full overflow-x-auto overflow-y-auto">        
            <div className="flex flex-col w-full h-full">
                <div className="w-full h-full bg-slate-50">
                    {workflowStatus === WorkflowStatus.brief && <ProjectBrief handleSubmit={handleSubmit} panels={panels} segments={segments} />}
                    {/*concept testing*/}
                    {workflowStatus === WorkflowStatus.cloading && <div className="w-full h-full bg-slate-50 m-8 items-center content-center"> <h1 className="text-center align-middle">Generating concepts...</h1> <Loading /> </div>}
                    {workflowStatus === WorkflowStatus.concepts && <ProjectConcepts objective={projectObjective} concepts={projectConcepts} edit={editConcept} delete={deleteConcept} add={addConcept} generateQuestions={generateQuestionsForConcepts} />}
                    {workflowStatus === WorkflowStatus.interviewQuestions && projectType === 'Concept Testing' && <ProjectInterviewQuestions objective={projectObjective} questions={projectQuestions} editQuestion={editInterviewQuestion} deleteQuestion={deleteInterviewQuestion} addQuestion={addInterviewQuestion} generateResults={generateResultsForConcepts} />}
                    {workflowStatus === WorkflowStatus.surveyQuestions && projectType === 'Concept Testing' && <ProjectSurveyQuestions objective={projectObjective} questions={projectQuestions} editQuestion={editSurveyQuestion} deleteQuestion={deleteSurveyQuestion} addQuestion={addSurveyQuestion} generateResults={generateResultsForConcepts} />}
                    {workflowStatus === WorkflowStatus.rloading && projectMethod === 'Qualitative' && projectType === 'Concept Testing' && <ProjectInterviewResultsForConcepts loading={loadingAnswers} objective={projectObjective} questions={projectQuestions} results={projectResults} respondents={respondents} concepts={projectConcepts} />}
                    {workflowStatus === WorkflowStatus.rloading && projectMethod === 'Quantitative' && projectType === 'Concept Testing' && <ProjectSurveyResultsForConcepts loading={loadingAnswers} objective={projectObjective} questions={projectQuestions} results={projectResults} respondents={respondents} concepts={projectConcepts} />}
                    {/* customer understanding */}
                    {workflowStatus === WorkflowStatus.qloading && <div className="w-full h-full bg-slate-50 m-8 items-center content-center"> <h1 className="text-center align-middle">Generating questions...</h1> <Loading /> </div>}
                    {workflowStatus === WorkflowStatus.interviewQuestions && projectType === 'Customer Insights' && <ProjectInterviewQuestions objective={projectObjective} questions={projectQuestions} editQuestion={editInterviewQuestion} deleteQuestion={deleteInterviewQuestion} addQuestion={addInterviewQuestion} generateResults={generateResultsForInsights} />}
                    {workflowStatus === WorkflowStatus.surveyQuestions && projectType === 'Customer Insights' && <ProjectSurveyQuestions objective={projectObjective} questions={projectQuestions} editQuestion={editSurveyQuestion} deleteQuestion={deleteSurveyQuestion} addQuestion={addSurveyQuestion} generateResults={generateResultsForInsights} />}                    
                    {workflowStatus === WorkflowStatus.rloading && projectMethod === 'Qualitative' && projectType === 'Customer Insights' && <ProjectInterviewResultsForInsights loading={loadingAnswers} objective={projectObjective} questions={projectQuestions} results={projectResults} respondents={respondents} concepts={projectConcepts} />}
                    {workflowStatus === WorkflowStatus.rloading && projectMethod === 'Quantitative' && projectType === 'Customer Insights' && <ProjectSurveyResultsForInsights loading={loadingAnswers} objective={projectObjective} questions={projectQuestions} results={projectResults} respondents={respondents} concepts={projectConcepts} />}                    
                    </div>          
            </div>            
        </div>
    )
}

export default NewProjectWorkflow;
