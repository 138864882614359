import React from 'react';
import editIcon from "../../img/icons8-edit-30.png"

const PersonaProfile = (props) => {

    const persona = props.persona;
    const personaType = persona.personaType;
    const path = "/personas/edit/" + persona.personaID;

    function editPersona (persona) {
        //console.log("PersonaProfile.editPersona: " + persona)
        props.editPersona(persona);
    }

    return (
        <div className="flex flex-row flex-wrap w-full py-8 px-8 mt-4 mb-2 ml-4 mr-4 space-y-0 justify-start rounded-xl drop-shadow-lg sm:py-4 sm:flex sm:items-center sm:space-y-4 sm:space-x-6">
        <div className="w-full mb-4 py-8 px-8 space-y-0 bg-white justify-start rounded-xl drop-shadow-lg sm:py-4 sm:flex sm:items-center sm:space-y-4 sm:space-x-6">
            <div className="justify-items-start sm:text-left">
                <div className="justify-items-start">
                <h5 className="text-left text-slate-500 font-medium">
                    {persona.name}
                </h5>
                <hr />
                </div>
                <ul className="text-left text-slate-500 font-medium list-disc px-4">                            
                    {(persona.age) && (<li key={persona.age}>Age: {persona.age}</li>)}
                    {(persona.gender) && (<li key={persona.gender}>Gender: {persona.gender}</li>)}                             
                    {(persona.race) && (<li key={persona.race}>Race:  {persona.race }</li>)}
                    {(persona.education) && (<li key={persona.education}>Education:  {persona.education}</li>)}
                    {(persona.occupation) && (<li key={persona.occupation}>Occupation: {persona.occupation}</li>)}
                    {(persona.maritalStatus) &&(<li key={persona.maritalStatus}>Marital Status: {persona.maritalStatus}</li>)}
                    {(persona.children) && (<li key={persona.children}>Children: {persona.children}</li>)}
                    {(persona.income) && (<li key={persona.income}>Income: {persona.income}</li>)}
                    {(persona.netWorth) && (<li key={persona.netWorth}>Net Worth: {persona.netWorth}</li>)}
                    {(persona.city) && (<li key={persona.city}>City: {persona.city}</li>)}
                    {(persona.values) &&(<li key={persona.values}>Values & Beliefs: {persona.values}</li>)}
                    {(persona.lifestyle) && (<li key={persona.lifestyle}>Lifestyle & Activities: {persona.lifestyle}</li>)}
                    {(persona.attitudes) && (<li key={persona.attitudes}>Attitudes & Opinions: {persona.attitudes}</li>)}
                    {(persona.interests) && (<li key={persona.interests}>Interests & Hobbies: {persona.interests}</li>)}
                    {(persona.personality) && (<li key={persona.personality}>Personality Traits: {persona.personality}</li>)}
                    {(persona.social) && (<li key={persona.social}>Social & Cultural: {persona.social}</li>)}
                </ul>
                </div>
                </div>
                </div>
    )
}

export default PersonaProfile;