import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetPanels = async () => {

    const userInfo = await Auth.currentAuthenticatedUser();

    try {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.listPanels, 
            variables: {
                filter: {
                    owner: {eq: userInfo.attributes.email}
                }
            }
        });

        return apiData.data.listPanels.items;

    } catch (err) {
        console.log("ERROR- GetPanels: " + err)
    }

    
}

export default GetPanels;