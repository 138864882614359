import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetSegments = async () => {

    const userInfo = await Auth.currentAuthenticatedUser();
    const apiData = await API.graphql({
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: queries.listSegments,
        variables: {
            filter: {
                owner: { eq: userInfo.attributes.email }
            }
        }
    });

    const segments = apiData.data.listSegments.items;
    console.log(segments);
    return segments;
}

export default GetSegments;