import { API } from "aws-amplify";

const SimpleLLMCall = async (data) => {
    let response;
    console.log('SimpleLLMCall:', data);
    let response_format = "simple";
    if (response_format in data) {
        response_format = data.response_format;
    }
    try {
        const apiresponse = await API.post ('openaigptapi', '/chat', {
            body: {
                input: {
                    question: data.question,
                    system_prompt: data.system_prompt, 
                    history: data.history,
                    model: data.model,
                    mode: "simple",
                    sources: data.sources,
                    temperature: data.temperature,
                    response_format: response_format,
                }
            },
        });
        console.log('SimpleLLMCall:', apiresponse);
        response = apiresponse;
    }
    catch (error) {
        console.error(error);
        // Return a graceful error message
        return 'An error occurred while making the API call';
    }
    if (response && response.Answer) {
        const trimmedResponse = response.Answer.trim();
        const parsedResponse = JSON.parse(trimmedResponse);
        console.log('SimpleLLMCall:', parsedResponse);
        return parsedResponse;
    }
    else {
        // Return a graceful error message
        return 'An error occurred while processing the API response';
    }    
}

export default SimpleLLMCall;
