import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, set } from  'react-hook-form';
import { useDropzone } from 'react-dropzone';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { FormatColorReset } from '@mui/icons-material';
import * as personaoptions from '../../utils/personas/PersonaOptions';
import Loading from '../../utils/Loading';
import { DataGrid } from '@mui/x-data-grid';
import GetPanels from '../../utils/panels/GetPanels';
import GetUser from '../../utils/users/GetUser';

const PersonaBrief = (props) => {

    const navigate = useNavigate();
    const { register, handleSubmit, control, reset, setValue, watch } = useForm();
    const segmentName = watch('name');
    const segmentDescription = watch('description');

    const [selectedPersonaType, setSelectedPersonaType] = useState('Consumer');
    const [selectedResearchType, setSelectedResearchType] = useState('');
    const [selectedPanel, setSelectedPanel] = useState('');
    const [selectedProcess, setSelectedProcess] = useState('');
    

    const [selectedDemographicFields, setSelectedDemographicFields] = useState([]);
    const [selectedPsychographicFields, setSelectedPsychographicFields] = useState([]);
    const [selectedGender, setSelectedGender] = useState([]);
    const [selectedAge, setSelectedAge] = useState([]);
    const [selectedRace, setSelectedRace] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [selectedOccupation, setSelectedOccupation] = useState([]);
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState([]);
    const [selectedChildren, setSelectedChildren] = useState([]);
    const [selectedIncome, setSelectedIncome] = useState([]);
    const [selectedNetWorth, setSelectedNetWorth] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    
    //const countryOptions = countryList().getData()
    const createOptions = personaoptions.personaCreateOptions;
    const countryOptions = personaoptions.countryOptions;
    const stateOptions = personaoptions.stateOptions;
    const educationOptions = personaoptions.educationOptions;
    const netWorthOptions = personaoptions.netWorthOptions;
    const incomeOptions = personaoptions.incomeOptions;
    const maritalOptions = personaoptions.maritalOptions;
    const occupationOptions = personaoptions.occupationOptions;
    const ageOptions = personaoptions.ageOptions;
    const genderOptions = personaoptions.genderOptions;
    const raceOptions = personaoptions.raceOptions;
    const childrenOptions = personaoptions.childrenOptions;
    const [panelOptions, setPanelOptions] = useState([]);

    const [user, setUser] = useState(null);
    const [personaOrArchetype, setPersonaOrArchetype] = useState("Personas");

    const isTable = true;

    async function fetchUser() {
        const user = await GetUser();
        if (user) {
            setUser(user);
            console.log("User: ", user);
            if (user.attributes.email.includes("suntory")) {
                setPersonaOrArchetype("Archetypes");
              }      
        }
    }

    const researchTypeOptions = [
        { value: 'Qualitative', label: 'Qualitative' },
        { value: 'Quantitative', label: 'Quantitative' },
    ];

    const demographicFieldOptions = [

        { value: 'gender', label: 'Gender' , stateVar: 'setSelectedGender'},
        { value: 'age', label: 'Age', stateVar: 'setSelectedAge' },
        { value: 'education', label: 'Education', stateVar: 'setSelectedEducation' },
        { value: 'occupation', label: 'Occupation', stateVar: 'setSelectedOccupation' },
        { value: 'maritalStatus', label: 'Marital Status', stateVar: 'setSelectedMaritalStatus' },
        { value: 'income', label: 'Income', stateVar: 'setSelectedIncome' },
        { value: 'netWorth', label: 'Net Worth', stateVar: 'setSelectedNetWorth' },
        { value: 'city', label: 'City'},
        { value: 'region', label: 'Region'},
        { value: 'state', label: 'State', stateVar: 'setSelectedState' },
        { value: 'country', label: 'Country', stateVar: 'setSelectedCountry' },

      ];

    const psychographicFieldOptions = [
        {value: 'values', label: 'Values & Beliefs'},
        {value: 'lifestyle', label: 'Lifestyle & Activities'},
        {value: 'interests', label: 'Interests & Hobbies'},
        {value: 'attitudes', label: 'Attitudes & Opinions'},
        {value: 'personality', label: 'Personality Traits'},
        {value: 'social', label: 'Social & Cultural Factors'},
    ];

    const handleCancel = () => {
        setSelectedProcess();
        setSelectedDemographicFields([]);
        setSelectedPsychographicFields([]);
        setSelectedGender([]);
        setSelectedAge([]);
        setSelectedRace([]);
        setSelectedEducation([]);
        setSelectedOccupation([]);
        setSelectedMaritalStatus([]);
        setSelectedChildren([]);
        setSelectedIncome([]);
        setSelectedNetWorth([]);
        setSelectedCountry([]);
        setSelectedState([]);
        setSelectedPanel([]);
        reset();
        navigate('/');
    };

    const onSubmit = (data) => {
         
       data.creationProcess = selectedProcess;
       console.log('Form submitted with data:', data);
       props.generatePersonas(data)
    };    

    const handleCreateProcessChange = (selectedOption) => {
        setSelectedProcess(selectedOption.value);
        setSelectedDemographicFields([]);
        setSelectedPsychographicFields([]);
        setSelectedGender([]);
        setSelectedAge([]);
        setSelectedRace([]);
        setSelectedEducation([]);
        setSelectedOccupation([]);
        setSelectedMaritalStatus([]);
        setSelectedChildren([]);
        setSelectedIncome([]);
        setSelectedNetWorth([]);
        setSelectedCountry([]);
        setSelectedState([]);
        setSelectedPanel([]);
        reset();
        setValue('name', segmentName);
        setValue('description', segmentDescription);
    }

    const handleSelectedPanelChange = (selectedOption) => {
        setSelectedPanel(selectedOption);
    }

    async function fetchPanels() {
        const panels = await GetPanels();
        const panelData = panels.map(panel => ({ value: panel.name, label: panel.name, id: panel.id }));
        return panelData;
    }
    
    useEffect(() => {
        fetchUser();
        fetchPanels().then(panels => {
            setPanelOptions(panels);
        });
    }, []);

    return (
        <div className="flex flex-col w-full content-start bg-slate-50 drop-shadow-sm overflow-x-hidden">
            {/* if props.isLoading is true display Loadding page, else load usual page */}
            {props.isLoading ? <Loading /> : 
            <div className="text-left align-middle w-full">
                    <div className="p-4 border-b-2">
                        <h5 className="text-black">
                            Create New Segment
                        </h5>
                    </div>
                    
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row flex-wrap w-full">

                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Segment Name</label>
                            <Controller
                                name="name"
                                control={control} // control comes from useForm()
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <input 
                                        id="name"
                                        type="text"
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            //setSelectedName(e.target.value);
                                        }}
                                        className="content-center rounded-lg border-1 w-72 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                    />
                                )}
                            />
                        </div>

                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Segment Description</label>
                            <Controller
                                name="description"
                                control={control} // control comes from useForm()
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <input 
                                        id="name"
                                        type="text"
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            //setSelectedName(e.target.value);
                                        }}
                                        className="content-center rounded-lg border-1 w-1/2 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                    />
                                )}
                            />
                        </div>

                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Creation Process</label>
                            <Controller
                                name="creationProcess"
                                control={control}
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={createOptions}
                                        onChange={selectedOption => {
                                                handleCreateProcessChange(selectedOption)
                                                field.onChange(selectedOption.value);
                                            }
                                        }
                                        value={createOptions.find((c) => c.value === value)}
                                    />)}
                            />
                        </div>

                        {(selectedProcess === 'Panel' || selectedProcess === 'Criteria') && (
                            <div className="flex items-center w-full">
                            <label className="m-4 w-48"># of {personaOrArchetype} to Create</label>
                            <Controller
                                name="numberOfPersonas"
                                control={control} // control comes from useForm()
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <input 
                                        id="numberOfPersonas"
                                        type="number"
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            //setSelectedName(e.target.value);
                                        }}
                                        className="content-center rounded-lg border-1 w-72 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                    />
                                )}
                            />
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedProcess === 'Criteria') && (

                            <div className="flex flex-row w-full p-4 border-t-2 border-b-2">
                                <h5 className="text-black">
                                    Demographic Details
                                </h5>
                            </div>
                        )}

                        {(selectedProcess === 'Criteria') && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Select Criteria Fields</label>
                            <Controller
                                name="demographicFields"
                                control={control}
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={demographicFieldOptions}
                                        isMulti
                                        onChange={selectedOptions => {setSelectedDemographicFields(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    } 
                                    value = {Array.isArray(field.value) ? field.value.map(value => demographicFieldOptions.find((c) => c.value === value)) : []}                                    />)}
                            />
                        </div>       
                        )}
                        
                        <div>
                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'gender')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Gender</label>
                            <Controller 
                                name="gender"
                                control={control} 
                                render={({ value, field }) => ( 
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={genderOptions}
                                        isMulti
                                        onChange={selectedOptions => {setSelectedGender(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => genderOptions.find((c) => c.value === value)) : []}                                    
                                />)}
                            />                            
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'age')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Age</label>
                            <Controller 
                                name="age"
                                control={control} 
                                render={({ value, field }) => ( 
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={ageOptions}
                                        isMulti
                                        onChange={selectedOptions => {setSelectedAge(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => ageOptions.find((c) => c.value === value)) : []}                                    
                                    
                                />)}
                            />                            
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'race')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Race</label>
                            <Controller 
                                name="race"
                                control={control} 
                                render={({ value, field }) => ( 
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={raceOptions}
                                        isMulti
                                        onChange={selectedOptions => {setSelectedRace(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => raceOptions.find((c) => c.value === value)) : []}
                                />)}
                            />                            
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'education')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Education</label>
                            <Controller
                                name="education"
                                control={control}
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={educationOptions}
                                        isMulti
                                        onChange={selectedOptions => {setSelectedEducation(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => educationOptions.find((c) => c.value === value)) : []}
                                />)}
                            />
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'occupation')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Occupation</label>
                            <Controller 
                                name="occupation"
                                control={control} 
                                render={({ value, field }) => ( 
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={occupationOptions}
                                        isMulti
                                        onChange={selectedOptions => {setSelectedOccupation(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => occupationOptions.find((c) => c.value === value)) : []}
                                />)}
                            />                            
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'maritalStatus')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Marital Status</label>
                            <Controller
                                name="maritalStatus"
                                control={control}
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={maritalOptions}
                                        isMulti
                                        onChange={selectedOptions => 
                                            {setSelectedMaritalStatus(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => maritalOptions.find((c) => c.value === value)) : []}
                                />)}
                            />
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'children')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Children</label>
                            <Controller 
                                name="children"
                                control={control} 
                                render={({ value, field }) => ( 
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={childrenOptions}
                                        isMulti
                                        onChange={selectedOptions => {setSelectedChildren(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => childrenOptions.find((c) => c.value === value)) : []}
                                />)}
                            />                            
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'income')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Income ($USD)</label>
                            <Controller 
                                name="income"
                                control={control} 
                                isMulti
                                render={({ value, field }) => ( 
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={incomeOptions}
                                        isMulti
                                        onChange={selectedOptions => {
                                            setSelectedIncome(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => incomeOptions.find((c) => c.value === value)) : []}   
                                />)}
                            />                            
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'netWorth')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Net Worth ($USD)</label>
                            <Controller 
                                name="netWorth"
                                control={control} 
                                render={({ value, field }) => ( 
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={netWorthOptions}
                                        isMulti
                                        onChange={selectedOptions => {
                                            setSelectedNetWorth(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => netWorthOptions.find((c) => c.value === value)) : []}
                                />)}
                            />                            
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'country')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Country</label>
                                <Controller
                                name="country"
                                control={control}
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={countryOptions}
                                        isMulti
                                        defaultValue={countryOptions.find(option => option.value === 'United States')}
                                        onChange={selectedOptions => {setSelectedCountry(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        }
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => countryOptions.find((c) => c.value === value)) : []}
                                />)}
                            />
                            </div>
                        )}

                            {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'state')) && (
                                <div className="flex items-center w-full">
                                    <label className="m-4 w-48">State</label>
                                        <Controller
                                        name="state"
                                        control={control}
                                        render={({ value, field }) => (
                                            <Select {...field} className="w-72 min-w-max m-4"
                                                options={stateOptions}
                                                isMulti
                                                onChange={selectedOptions => {
                                                    setSelectedState(selectedOptions)
                                                    field.onChange(selectedOptions.map(option => option.value));
                                                }
                                            }
                                            value = {Array.isArray(field.value) ? field.value.map(value => stateOptions.find((c) => c.value === value)) : []}
                                        />)}
                                    />
                                    </div>
                            )}
                        </div>

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'city')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">City</label>
                            <Controller
                                name="city"
                                control={control} // control comes from useForm()
                                defaultValue=""
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <input 
                                        id="city"
                                        type="text"
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        className="content-center rounded-lg border-1 w-72 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                    />
                                )}
                            />
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedDemographicFields.some(field => field.value === 'region')) && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Region</label>
                            <Controller
                                name="region"
                                control={control} // control comes from useForm()
                                defaultValue=""
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <input 
                                        id="region"
                                        type="text"
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        className="content-center rounded-lg border-1 w-72 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                    />
                                )}
                            />
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedProcess === 'Criteria') && (

                            <div className="flex flex-row w-full p-4 border-t-2 border-b-2">
                                <h5 className="text-black">
                                    Psychographic Details
                                </h5>
                            </div>
                        )}

                        {(selectedProcess === 'Criteria') && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Select Criteria Fields</label>
                            <Controller
                                name="psychographicFields"
                                control={control}
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={psychographicFieldOptions}
                                        isMulti
                                        onChange={selectedOptions => {setSelectedPsychographicFields(selectedOptions)
                                            field.onChange(selectedOptions.map(option => option.value));
                                        } 
                                    }
                                    value = {Array.isArray(field.value) ? field.value.map(value => psychographicFieldOptions.find((c) => c.value === value)) : []}                                />)}
                            />
                        </div>       
                        )}   

                        {(selectedProcess === 'Manual' || selectedPsychographicFields.some(field => field.value === 'values')) && (
                        <div className="flex w-full">
                            <label className="m-4 w-48">Values & Beliefs</label>
                            <textarea
                                id="values"
                                name="values"
                                {...register('values')}
                                rows="4"
                                className="content-center rounded-lg border-1 w-10/12 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                placeholder="Describe the values and beliefs of the persona"
                                ></textarea>
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedPsychographicFields.some(field => field.value === 'lifestyle')) && (
                        <div className="flex w-full">
                            <label className="m-4 w-48">Lifestyle & Activities</label>
                            <textarea
                                id="lifestyle"
                                name="lifestyle"
                                {...register('lifestyle')}
                                rows="4"
                                className="content-center rounded-lg border-1 w-10/12 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                placeholder="Describe the lifestyle and activities of the persona"
                                ></textarea>
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedPsychographicFields.some(field => field.value === 'interests')) && (
                        <div className="flex w-full">
                            <label className="m-4 w-48">Interests & Hobbies</label>
                            <textarea
                                id="interests"
                                name="interests"
                                {...register('interests')}
                                rows="4"
                                className="content-center rounded-lg border-1 w-10/12 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                placeholder="Describe the interests and hobbies of the persona"
                                ></textarea>
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedPsychographicFields.some(field => field.value === 'attitudes')) && (
                        <div className="flex w-full">
                            <label className="m-4 w-48">Attitudes & Opinions</label>
                            <textarea
                                id="attitudes"
                                name="attitudes"
                                {...register('attitudes')}
                                rows="4"
                                className="content-center rounded-lg border-1 w-10/12 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                placeholder="Describe the attitudes and opinions of the persona"
                                ></textarea>
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedPsychographicFields.some(field => field.value === 'personality')) && (
                        <div className="flex w-full">
                            <label className="m-4 w-48">Personality Traits</label>
                            <textarea
                                id="personality"
                                name="personality]"
                                {...register('personality')}
                                rows="4"
                                className="content-center rounded-lg border-1 w-10/12 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                placeholder="Describe the personality traits of the persona"
                                ></textarea>
                        </div>
                        )}

                        {(selectedProcess === 'Manual' || selectedPsychographicFields.some(field => field.value === 'social')) && (
                        <div className="flex w-full">
                            <label className="m-4 w-48">Social & Cultural Factors</label>
                            <textarea
                                id="social"
                                name="social"
                                {...register('social')}
                                rows="4"
                                className="content-center rounded-lg border-1 w-10/12 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                placeholder="Describe the social status and cultural influences of the persona"
                                ></textarea>
                        </div>
                        )}

                        {(selectedProcess === 'Panel') && (
                            <div className="flex items-center w-full">
                            <label className="m-4 w-48">Select Panel</label>
                            <Controller 
                                name="panel"
                                control={control} 
                                render={({ value, field }) => ( 
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={panelOptions}
                                        onChange={(selectedOption) => {handleSelectedPanelChange(selectedOption)
                                            field.onChange(selectedOption);
                                            }
                                        }
                                        value={panelOptions.find((c) => c.value === value)}
                                    />)}
                            />                            
                            </div>
                        )}

                        
                            
                        <div className="flex flex-row w-full mb-40">
                             <button type="button" onClick={handleCancel} className="m-4 px-2 py-2 w-24 text-sm text-blue-600 bg-slate-0 font-semibold rounded-md border-1 border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                                Cancel
                            </button>
                            <button type="submit"  className="m-4 px-2 py-2 w-24 text-sm text-blue-600 bg-slate-0 font-semibold rounded-md border-1 border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                                Create
                            </button>
                        </div>

                    </form>
            </div>
            }
        </div>

  );
}

export default PersonaBrief;
