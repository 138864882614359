import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetProfilesBySourceId = async (id) => {

    let nextToken = null;
    let allProfiles = [];

    do {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.profilesBySourceID, 
            variables: {
                sourceID: id,
                limit: 100, // Specify the number of items to return in each request
                nextToken: nextToken,
            }
        });

        const profiles = apiData.data.profilesBySourceID.items;
        allProfiles = [...allProfiles, ...profiles];
        nextToken = apiData.data.profilesBySourceID.nextToken;

    } while (nextToken);

    return allProfiles;
}

export default GetProfilesBySourceId;