import {  useState, useEffect } from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
//import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PersonaGenerated from './PersonaGenerated';

const PersonaResults = (props) => {
    console.log("in PersonaResults")

    console.log("Persona List: ", props.personaList);

    const [personaList, setPersonaList] = useState(props.personaList);

    useEffect(() => {
        setPersonaList(props.personaList);
    }, [props.personaList]);

    //const [like, setLike] = useState(false)
    //const [dislike, setDislike] = useState(false)
    //const [edit, setEdit] = useState(false)
    const [feedback, setFeedback] = useState(false)
    
    // save button
    function savePersonas() {
        console.log("Personas to save:", personaList)
        props.savePersonas(personaList);
    }

    // regenerate button
    function regenerateAllPersonas() {
        // call the function to regenerate all personas
        props.regenerateAllPersonas();
    }
{/*
    // feedback pn persona button
    function markPersonaAsGood(persona) {
        setLike(true);
        props.editPreference(persona.id, "LIKE")
    }
    function markPersonaAsBad(persona) {
        setDislike(true);
        props.editPreference(persona.id, "DISLIKE")
    }
*/}
    function feedbackForm(persona) {
        setFeedback(true);
        // open the feedback form
        /*const data = {
            id: personaID,
            userFeedback: feedback
        }*/
        //UpdatePersona(data);
    }

    // regenerate this persona button
    function regeneratePersona(personaID) {
        // call the function to regenerate this persona
        props.regeneratePersona(personaID);
    }

    return (
        <div className="flex flex-col ">
            
            <h1 className="m-4 text-center">
                Review Generated Personas
            </h1>
                {personaList.map((persona) => (
                <div key={persona.id} className="flex flex-row content-start ml-4 mr-4 p-2">            
                    <PersonaGenerated
                        persona={persona}
                        regeneratePersona={regeneratePersona}
                    />
                </div>

                ))}
            <div className="flex flex-row justify-start space-x-16 m-4">
                <button type="submit"  
                        onClick={regenerateAllPersonas}
                        className="m-4 px-2 py-2 w-48 text-sm text-blue-600 bg-slate-0 font-semibold rounded-md border-1 border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                    Regenerate All
                </button>

                <button type="submit"  
                        onClick={savePersonas}
                        className="m-4 px-2 py-2 w-24 text-sm text-blue-600 bg-slate-0 font-semibold rounded-md border-1 border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                    Save
                </button>
            </div>

        </div>
               
    );
}

export default PersonaResults;
