// use state
import { useEffect, useState } from "react";
// Table component
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
// custom components
import DeleteProfiles from "../../utils/panels/DeleteProfiles";
import DeleteSources from "../../utils/panels/DeleteSources";
import { set } from "react-hook-form";

const Profiles = (props) => {

    const [profiles, setProfiles] = useState([]);
    const [sourceID, setSourceID] = useState(null);
    const [user, setUser] = useState(null);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        setProfiles(props.profiles);
        setSourceID(props.sourceID);
        setLoading(props.loading);
    }, [props.loading]);

    const columns = [
        {field: 'id', headerName: 'ID'},
        {field: 'sourceID', headerName: 'Source ID'},
        {field: 'name', headerName: 'Name', width: 100},
        {field: 'age', headerName: 'Age', width: 50, type: 'number'},
        {field: 'age_range', headerName: 'Age Range', width: 75},
        {field: 'gender', headerName: 'Gender', width: 100},
        {field: 'race', headerName: 'Race', width: 100},
        {field: 'education', headerName: 'Education', width: 200},
        {field: 'occupation', headerName: 'Occupation', width: 250},
        {field: 'employment_status', headerName: 'Employment Status', width: 150},
        {field: 'maritalStatus', headerName: 'Marital Status', width: 150},
        {field: 'household_type', headerName: 'Household Type', width: 150},
        {field: 'household_size', headerName: 'Household Size', width: 150},
        {field: 'children', headerName: 'Children', width: 75},
        {
            field: 'income',
            headerName: 'Income',
            width: 150,
            type: 'number',
            valueFormatter: (params) => {
                const valueFormatted = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0, // No decimal places
                    maximumFractionDigits: 0, // No decimal places
                }).format(params.value);
                return valueFormatted;
            },
        },
        {
            field: 'net_worth',
            headerName: 'Net Worth',
            width: 150,
            type: 'number',
            valueFormatter: (params) => {
                const valueFormatted = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0, // No decimal places
                    maximumFractionDigits: 0, // No decimal places
                }).format(params.value);
                return valueFormatted;
            },
        },
        {field: 'income_range', headerName: 'Income Range', width: 150},
        {field: 'asset_range', headerName: 'Asset Range', width: 150},
        {field: 'asset_types', headerName: 'Asset Types', width: 150},
        {field: 'networth_range', headerName: 'Net Worth Range', width: 150},
        {field: 'city', headerName: 'City', width: 150},
        {field: 'metro', headerName: 'Metro', width: 150},
        {field: 'state', headerName: 'State', width: 150},
        {field: 'region', headerName: 'Region', width: 150},
        {field: 'country', headerName: 'Country', width: 150},
        {field: 'zipcode', headerName: 'Zipcode', width: 150},
        {field: 'lifestyle', headerName: 'Lifestyle', width: 150},
        {field: 'interests', headerName: 'Interests', width: 150},
        {field: 'values', headerName: 'Values', width: 150},
        {field: 'attitudes', headerName: 'Attitudes', width: 150},
        {field: 'personality', headerName: 'Personality', width: 150},
        {field: 'social', headerName: 'Social', width: 150},
    ];

    const handleSelectionChange = (newSelection) => {
        console.log(newSelection);
        setSelectedProfiles(newSelection);
    }

    async function deleteProfiles() {
        if (window.confirm('Are you sure you want to delete these profiles?')) {
            console.log("Deleting Profiles:", selectedProfiles);
            const profileDeleteResult = await DeleteProfiles(selectedProfiles);
            const newProfiles = profiles.filter(profile => !selectedProfiles.includes(profile.id));
            setProfiles(newProfiles);
    
            if (newProfiles.length === 0) {
                const sourceDeleteResult = await DeleteSources(sourceID);
            }
        }
    }

    const checkIfAllValuesAreEmpty = (field) => {
        return profiles.every(profile => !profile[field] || profile[field] === "" || profile[field] === " ");
    }
    
    return (
        <div className="flex flex-col flex-wrap m-4 w-full h-full bg-slate-50 overflow-x-hidden">
            <div className="flex flex-col flex-wrap w-full h-full bg-slate-50">
                <div className="flex flex-col flex-wrap w-full h-full bg-slate-50">
                    {/*
                    <div className="flex w-full m-2 h-10 bg-slate-50 justify-end">
                        <button 
                            onClick={deleteProfiles} 
                            name='delete'
                            disabled={true}
                            hidden={true}
                            className="px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 disabled:bg-slate-500 disabled:text-black hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                            Delete
                        </button>     
                    </div>
                    */}
                    <div className="flex flex-col w-full h-full max-h-fit mb-2 bg-slate-50">
                        <DataGridPro
                            slots = {{ toolbar: GridToolbar }}
                            autoHeight
                            loading={loading}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        sourceID: false,
                                        name: false,
                                        age: checkIfAllValuesAreEmpty('age'),
                                        age_range: checkIfAllValuesAreEmpty('age_range'),
                                        gender: checkIfAllValuesAreEmpty('gender'),
                                        race: checkIfAllValuesAreEmpty('race'),
                                        education: checkIfAllValuesAreEmpty('education'),
                                        occupation: checkIfAllValuesAreEmpty('occupation'),
                                        employment_status: checkIfAllValuesAreEmpty('employment_status'),
                                        maritalStatus: checkIfAllValuesAreEmpty('maritalStatus'),
                                        household_type: checkIfAllValuesAreEmpty('household_type'),
                                        household_size: checkIfAllValuesAreEmpty('household_size'),
                                        children: checkIfAllValuesAreEmpty('children'),
                                        income: checkIfAllValuesAreEmpty('income'),
                                        net_worth: checkIfAllValuesAreEmpty('net_worth'),
                                        income_range: checkIfAllValuesAreEmpty('income_range'),
                                        asset_range: checkIfAllValuesAreEmpty('asset_range'),
                                        asset_types: checkIfAllValuesAreEmpty('asset_types'),
                                        networth_range: checkIfAllValuesAreEmpty('networth_range'),
                                        city: checkIfAllValuesAreEmpty('city'),
                                        metro: checkIfAllValuesAreEmpty('metro'),
                                        state: checkIfAllValuesAreEmpty('state'),
                                        region: checkIfAllValuesAreEmpty('region'),
                                        country: checkIfAllValuesAreEmpty('country'),
                                        zipcode: checkIfAllValuesAreEmpty('zipcode'),
                                        lifestyle: checkIfAllValuesAreEmpty('lifestyle'),
                                        interests: checkIfAllValuesAreEmpty('interests'),
                                        values: checkIfAllValuesAreEmpty('values'),
                                        attitudes: checkIfAllValuesAreEmpty('attitudes'),
                                        personality: checkIfAllValuesAreEmpty('personality'),
                                        social: checkIfAllValuesAreEmpty('social'),
                                    },
                                },
                            }}

                            sx={{   
                                fontWeight: 'fontWeightLight',
                                bgcolor: 'white',
                                boxShadow: 2, 
                                borderRadius: 4,
                                border: 1,
                                px: 1,
                                py: 1,
                            }}
                            rows={profiles}
                            columns={columns}
                            pagination
                            pageSize={5}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            //checkboxSelection
                            onRowSelectionModelChange={handleSelectionChange}
                        />
                    

                    </div>
            </div>
            </div>
        </div>
    )
};
  
export default Profiles;