import { API, Auth } from 'aws-amplify';

const GetUser = async () => {

    const fetchUser = async() => {
        try {
            const amplifyUser = await Auth.currentAuthenticatedUser();
            console.log(amplifyUser)
            return amplifyUser;
        } catch (error) {
            console.log(error);
        }
    }

    return fetchUser();
}

export default GetUser;