// use state
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';

// Table component
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ThemeProvider, createTheme } from '@mui/system';
// custom components
import PersonaProfile from "../../utils/personas/PersonaProfile";
import PersonaTable from "./PersonaTable";
import PersonaCard from "./PersonaCard";

const Personas = (props) => {
    
    console.log(props.personas)
    // display format
    const [displayFormat, setDisplayFormat] = useState('Table');
    // persona
    const personas = props.personas; 
    // loading
    const [isLoading, setIsLoading] = useState(props.isLoading);

    useEffect(() => {
        setIsLoading(props.isLoading);
    } , [props.isLoading])

  return (
    <div className="flex flex-col flex-wrap w-full h-full bg-slate-50 overflow-x-auto overflow-y-auto">
        <div className="w-full h-full bg-slate-50">
        <div className="flex h-10 mb-4 mr-4 mt-2 bg-slate-50 justify-end">
          {personas.length > 0 &&
            <button 
                onClick={displayFormat === 'Card' ? () => setDisplayFormat('Table') : () => setDisplayFormat('Card')} 
                name='delete' 
                className="px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 disabled:bg-slate-500 disabled:text-black hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                {displayFormat === 'Card' ? 'Table' : 'Card'} View
            </button>
            }

        </div>  
        <div className="flex w-full h-full bg-slate-50 overflow-x-auto">
        <Routes>
            {displayFormat === 'Table' && <Route path="/" element={<PersonaTable personas={personas} loading={isLoading} />} />}
            {displayFormat === 'Card' && <Route path="/" element={<PersonaCard personas={personas} />} />}
        </Routes>
        </div>
    </div>      
    </div>
  )
};
  
  export default Personas;