import React, { useRef, useState } from 'react';

function InterviewBrief(props) {
  const [text, setText] = useState('');

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const textareaRef = useRef(null)
  //const [isAnimate, setIsAnimate]  = useState(props.isLoading)
  const handleSubmit = async(event) => {
      event.preventDefault();
      const query = textareaRef.current.value;
      //setIsAnimate(true)
      props.submit(query)
      textareaRef.current.value=''
  }

  return (
    <div className="flex flex-wrap content-start bg-slate-50 drop-shadow-sm">
        <div className="w-full text-left align-middle">
            <div className="w-full">
                <div className="p-4 border-b-2">
                    <h5 className="text-black">
                        Current Interview
                    </h5>
                </div>
                <form className="flex flex-row flex-wrap w-full">
                    <div className="flex items-center w-full">
                    <label className="m-4 w-32">Objective</label>
                        <textarea
                            id="prompt-input"
                            rows="4"
                            className="w-10/12 content-center rounded-lg border-1 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                            placeholder=""
                            required
                            ref={textareaRef}></textarea>
                    </div>

                    
                </form>
                <label className="m-4 w-32"></label>
                <button onClick={handleSubmit} className="m-6 px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                    {props.buttonName}
                </button>
            </div>
        </div>
    </div> 
    
  );
}

export default InterviewBrief;
