import React, { useRef, useState, useEffect } from 'react';
import addIcon from "../../img/icons8-add-30.png";
import editIcon from "../../img/icons8-edit-30.png";
import deleteIcon from "../../img/icons8-delete-30.png";
import saveIcon from "../../img/icons8-save-30.png";
import { set } from 'react-hook-form';

const InterviewQuestions = (props) => {

    const objective = props.objective;
    const questions = props.questions;

    const [newQuestion, setNewQuestion] = useState(null);
    const [editingId, setEditingId] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);

    const handleEditQuestionChange = (value) => {
        setEditingQuestion(value);
    }

    const handleNewQuestionChange = (value) => {
        setNewQuestion(value);
    }

    function editQuestion (id) {
        const questionToEdit = questions.find(item => item.id === id);
        if (editingId === id) {
            setEditingId(null);
            props.edit(id, editingQuestion);
        } else {
            setEditingId(id);
            setEditingQuestion(questionToEdit.question);
        }
    }

    function deleteQuestion (id) {
        props.delete(id);
    }

    function addQuestion () {
        handleNewQuestionChange("");
        props.add(newQuestion);
    }

    const textareaRef = useRef(null)
    
    return (
        <div className="flex flex-col w-full content-start bg-slate-50">
            <table className="table-auto w-full border border-slate-500">
                        <thead className="content-start">
                            <tr className="border bg-slate-50">
                                <th className="px-4 py-4 text-left">
                                    <h5>Objective</h5>
                                    <p>{objective}</p>
                                </th>
                            </tr>
                            <tr className="border bg-slate-50">
                                <th className="px-4 py-4 text-left">
                                    <h5>Questions
                                    </h5>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        questions.map((item) => (
                                <tr key={item.id} className="border">
                                    <td className="text-left p-4">
                                    {editingId === item.id ? (
                                    <input 
                                        name="editQuestion"
                                        type="text"
                                        defaultValue={item.question}
                                        onChange={e => handleEditQuestionChange(e.target.value)}
                                        className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                                    </input>
                                    ) : (
                                        item.question
                                    )}
                                    </td>
                                    <td className="flex text-left p-4">
                                        <img 
                                            src={editingId === item.id ? saveIcon : editIcon}
                                            alt="Edit Question"
                                            title="Edit Question"
                                            className="w-6 h-6 ml-2 mr-2 cursor-pointer"
                                            onClick={() => editQuestion(item.id)} />
                                        <img src={deleteIcon} alt="delete" title="Delete Question" className="w-6 h-6 ml-2 mr-2 cursor-pointer" onClick={() => deleteQuestion(item.id)} />
                                    </td>
                                </tr>
                            ))}
                            
                            <tr>
                                <td className="text-left p-4">
                                <input 
                                name="newQuestion"
                                type="text"
                                placeholder="Enter Question"
                                value={newQuestion}
                                onChange={e => handleNewQuestionChange(e.target.value)}
                                className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                            </input>
                            </td>
                            <td className="p-4">
                                <img 
                                    src={addIcon} 
                                    alt="Add Question" 
                                    title="Add Question" 
                                    className="w-6 h-6 ml-2 mr-2 content-center cursor-pointer" 
                                    onClick={() => addQuestion()} />
                            </td>
                            </tr> 
                        </tbody>
                    </table>
                    <button onClick={props.submit} className="w-60 m-8 px-4 py-2 bg-slate-50 justify-center text-md text-blue-600 font-semibold rounded-md border-1 border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                    {props.buttonName}
                </button>
                </div>
    )


}

export default InterviewQuestions;