// use state
import { useState, useEffect } from "react";
// AWS
import { Auth, API } from 'aws-amplify';
// local components
import InteractionSidebar from "../navigation/InteractionSidebar";
import SurveyQuestions from "./SurveyQuestions";
import SurveyResults from "./SurveyResults";
import SurveyBrief from "./SurveyBrief";

// GraphQL
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

// Loading
//import CircularProgress from '@mui/material/CircularProgress';
import Loading from "../Loading";
import SurveyPartialResults from "./SurveyPartialResults";


// survey form
const surveyForm = {
    "surveyName": "Enter the survey objective", 
    "surveyLabelName": "Enter the survey objective",
    "intakebutton": "Generate Questions",
    "qName": "Survey Questions",
    "qButtonName": "Run Survey",
};

//let surveyQuestions;
let surveyQuestionsText;
let surveyResults;
let surveyDescription;
let surveyID;
//let surveyObjective;
// DB version
//const version = "0.0.0.1";

// survey form status
export const SurveyStatus = {
    intake:"intake",
    questions:"questions",
    results: "results",
    qloading: "qloading",
    rloading: "rloading",
}

const convType = "Surveys"

// openai model
//const model1 = "gpt-4";
const model1 = "gpt-3.5-turbo";
const model2 = "gpt-3.5-turbo";
//const model2 ="gpt-4";

const SurveyWorkflow = (props) => {
    // Survey status
    const [surveyState, setSurveyState] = useState(SurveyStatus.intake);
    // User
    const [user, setUser] = useState(null);
    // persona
    const persona = props.persona;
    // persona ID
    const personaId = persona.id; 
    // survey titles
    const [surveyTitles, setSurveyTitles] = useState([]);
    // survey results
    const [surveyResults, setSurveyResults] = useState([]);
    // survey questions
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    
    // transform survey brief titles to format for display to the component
    const transformSurveyTitles = (datainput) => {       
        
        return datainput.map(item => {
            const dateObj=new Date(item.createdAt)
            // Array of short month names
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // Format the date as "Mon day", e.g., "Aug 11"
            const formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;

            // Return the transformed object
            return {
                "id": item.id,
                "title": item.title,
                "date": formattedDate,
            };
        })
    }

    // fetch user and conversations
    useEffect(() => {

        const fetchSurveys = async() => {
            const userInfo = await Auth.currentAuthenticatedUser();
            // get personaID
            let result;            
            // get all the surveys for this persona                               
            try {
                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.listCustomerSurveyBriefs,
                    variables: {
                        filter: {
                            owner: {eq: userInfo.attributes.email},
                            customerPersonaID: {eq: personaId},
                        }
                    },                
                });
            } catch(err) {
                console.error(err);
            }

            let surveyBriefList = result.data.listCustomerSurveyBriefs.items;
            surveyBriefList.sort((a,b) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
    
                return dateB-dateA
            })
            
            console.log("List of all survey briefs for this persona")
            console.log(surveyBriefList)

            let displayTitle = transformSurveyTitles(surveyBriefList)
            //console.log("List of conversation title")
            //console.log(displayTitle)
            setSurveyTitles(displayTitle)

        }
        
        const fetchUser = async() => {
            try {
                const amplifyUser = await Auth.currentAuthenticatedUser();
                setUser(amplifyUser);
            } catch (error) {
                setUser(null);
                console.log(error);
            }
        }

        fetchUser();
        fetchSurveys();
    },[])

    const updateSurvey = async(id) => {
        console.log("-----------------------------update survey")
        
        setSurveyState(SurveyStatus.qloading);
        surveyID = id;      
        console.log(surveyID)  
        // get the survey brief corresponding to this id
        let result;
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.getCustomerSurveyBrief,
                variables: {
                    id: surveyID,
                }
            });
        } catch(err) {
            console.error(err);
        }
        // get the survey questions
        //console.log(result.data.getCustomerSurveyBrief.questions)
        setSurveyQuestions(result.data.getCustomerSurveyBrief.questions);
        surveyQuestionsText = result.data.getCustomerSurveyBrief.questionText;
        surveyDescription = result.data.getCustomerSurveyBrief.objective;
        //console.log(surveyDescription)
        //console.log(surveyQuestionsText)

        // get the survey results
        /*
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.listCustomerSurveyResults,
                variables: {
                    filter: {
                        customerSurveyBriefID: {eq: surveyID},
                    }                    
                },
            });
        } catch(err) {
            console.error(err);
        }
        const surveyResultsID = result.data.listCustomerSurveyResults.items[0].id;
        console.log("Survey Results: " + surveyResultsID)

        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.getCustomerSurveyResults,
                variables: {
                    id: surveyResultsID, 
                }
            });
        } catch(err) {
            console.error(err);
        }
        //console.log(result.data.getCustomerSurveyResults.customerSurveyAnswers)
        for (let i = 0; i < result.data.getCustomerSurveyResults.customerSurveyAnswers.length; i++) {
            console.log(result.data.getCustomerSurveyResults.customerSurveyAnswers[i].question);
            console.log(result.data.getCustomerSurveyResults.customerSurveyAnswers[i].answers)
        }*/
        
        // set the survey state to 
        setSurveyState(SurveyStatus.questions);
    }

    const newSurvey = async() => {
        surveyID = null;
        setSurveyState(SurveyStatus.intake);
        console.log("new survey");
    }

    function format_llm_answer(text) {
        // Split the text into blocks by double newline
        const blocks = text.split('\n\n');

        // Map each block to an object
        const questions = blocks.map((block, index) => {
            // Split the block into lines
            const lines = block.split('\n');
            // The first line is the question
            const question = lines[0].split('. ')[1].trim()
            // The rest of the lines are options
            const options = lines.slice(1).map(line => line.split('. ')[1].trim());
            return { id: index + 1, question, options };
        });
        console.log(questions)

        return questions;
    }



    const intakeNext = async(query) => {
        
        let response;
        let result;
        let title;

        setSurveyState(SurveyStatus.qloading);

        // get the questions from LLM
        response = await API.post('openaigptapi', '/chat', {
            body: {
                input: {
                    question: query,                    
                    model: model1,                    
                    mode: "survey_question",
                }
            },
        });

        surveyQuestionsText = response.Answer.trim();
        console.log(surveyQuestionsText)
        let surveyQDB = format_llm_answer(surveyQuestionsText);
        setSurveyQuestions(surveyQDB);
        console.log(surveyQDB)
        surveyDescription = query;

        
        // get the title for the survey
        const surveytitle = "Summarize the survey brief to set a short descriptive title (2-5 words) for this survey description: " + surveyDescription;
        
        title = await API.post('openaigptapi', '/chat', {
                body: {
                input: {
                    question: surveytitle,
                    history: "",
                    model: model2,                
                    mode: "simple",
                    sources: [],
                }
            },
            });
        const parsedDataTitle = title.Answer.trim();
        console.log(parsedDataTitle)
        

        // create entry for survey brief
        const surveyBrief = {
            // id will be auto-populated by AWS
            title: parsedDataTitle,  // title 
            owner: user.attributes.email,
            objective: surveyDescription,
            numberOfQuestions: surveyQDB.length,
            questions: surveyQDB,
            // belongs to the persona
            customerPersonaID: personaId,
            questionText: surveyQuestionsText,
        }
        console.log(surveyBrief)

        // Create a new survey brief in the dB            
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: mutations.createCustomerSurveyBrief,
                variables: {
                    input: surveyBrief,                        
                }
            });
        } catch(err) {
            console.error(err);
        }

        surveyID = result.data.createCustomerSurveyBrief.id;
        const dateObj = new Date(result.data.createCustomerSurveyBrief.createdAt)
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;

        const parsedDataTitleDisplay = {"id": surveyID, "title": parsedDataTitle, "date": formattedDate}
        setSurveyTitles(prevArr => [parsedDataTitleDisplay, ...prevArr])
        setSurveyState(SurveyStatus.questions);

    }

    function normalizeValue (value) {
        // If value is a string and contains a percentage sign
        if (typeof value === 'string' && value.includes('%')) {
            return parseInt(value, 10);
        }
        // If value is a float (less than 1), multiply by 100
        else if (value < 1) {
            return value * 100;
        }
        // Otherwise, return value as-is
        else {
            return value;
        }
    }

    function format_survey_results (jsonInput) {
        console.log(jsonInput)
        const parsed_json = JSON.parse(jsonInput);

        const results = Object.entries(parsed_json).map(([key, value], index) => {
            
            const answers = Object.entries(value.options).map(([optionKey, optionValue]) => {               
                
                return {
                    option: optionValue,
                    percent: normalizeValue(value.results[optionKey])
                };
            });

            //console.log(answers)
            return {
                id: index + 1,
                question: value.question,
                answers: answers,
            };
        });
        console.log(results)
        return results;
    }
    
    const questionsNext = async() => {
        // get the questions from LLM one by one
        let surveyResultsDBAnswer = [];
        let result;
        setSurveyState(SurveyStatus.rloading);
        // save the survey brief to the database

        const personaType = persona.type;
        let persona_details;
        if (personaType === "Business") {
            persona_details = "Persona Name: " + persona.name + ", Role: " + persona.role +
            ", Industry: " + persona.industry + ", Title: " + persona.seniority + 
            ", Education Level: " + persona.education + ", Company Size: " + persona.companySize + 
            ", Other Details:" + persona.details + ".";
        }
        else {
            persona_details = "Persona Name: " + persona.name + ", Age: " + persona.age +
            ", Gender: " + persona.gender + ", Education Level: " + persona.education + 
            ", Occupation: " + persona.occupation + ", Marital Status: " + persona.maritalStatus + 
            ", Income: " + persona.income + ", Net Worth: " + persona.netWorth + 
            ", Other Details:" + persona.details + ".";

        }

        // run the survey one question at a time
        let alphaindex = ["a", "b", "c", "d", "e", "f"];
        for (let i = 0; i < surveyQuestions.length; i++) {
            let survey_question = (i+1).toString() + ". "  + surveyQuestions[i].question + "\n ";
            for  (let j=0; j < surveyQuestions[i].options.length; j++) {
                survey_question = survey_question + alphaindex[j] + ". " + surveyQuestions[i].options[j] + "\n ";
            }
            console.log(survey_question)
            const response = await API.post('openaigptapi', '/chat', {
                body: {
                    input: {
                        question: survey_question,
                        persona: persona.name,
                        persona_details: persona_details,                  
                        model: model1,                    
                        mode: "run_survey",
                    }
                },
            });

            
            console.log(response.Answer.trim())
            const survey_answer = format_survey_results(response.Answer.trim());
            console.log(survey_answer)
            //surveyResults[i].id = i + 1;
            //surveyResults[i].question = surveyQuestions[i].question;
            setSurveyResults(prevArr => [...prevArr, survey_answer[0]])
            surveyResultsDBAnswer.push(survey_answer[0]);            
        }


        // run the survey
/*        const response = await API.post('openaigptapi', '/chat', {
            body: {
                input: {
                    question: surveyQuestionsText,
                    persona: persona.name,
                    persona_details: persona_details,                  
                    model: model1,                    
                    mode: "run_survey",
                }
            },
        });

        console.log(response.Answer.trim());
        surveyResults = format_survey_results(response.Answer.trim());
        console.log(surveyResults)

*/
        
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: mutations.updateCustomerSurveyBrief,
                variables: {
                    input: {
                        id: surveyID,
                        numberOfQuestions: surveyQuestions.length,
                        surveyQuestions: surveyQuestions,
                    }                        
                }
            })
        } catch(err) {
            console.error(err);
        }

        // create entry for survey results
        const surveyResultsDB = {
            // id will be auto-populated by AWS
            owner: user.attributes.email,
            customerSurveyBriefID: surveyID,
            customerSurveyAnswers: surveyResultsDBAnswer,
            // belongs to the persona
        }
        console.log(surveyResultsDB)

        // Create a new survey results in the dB       
        console.log(surveyResultsDB)     
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: mutations.createCustomerSurveyResults,
                variables: {
                    input: surveyResultsDB,                        
                }
            });
        } catch(err) {
            console.error(err);
        }

        setSurveyState(SurveyStatus.results);
    }

    function editQuestion(id, text, options) {
        let newQuestions;
        let newOptions;

        console.log("edit button clicked", id, text, options);

        newQuestions = surveyQuestions.map(question => 
            question.id === id ? { ...question, question: text, options: options } : question,
        );
        setSurveyQuestions(newQuestions);
    }

    function deleteQuestion(id) {
        let newQuestions = surveyQuestions.filter(question => question.id !== id);
        newQuestions = newQuestions.map((question, index) => ({ ...question, id: index + 1 }));
        setSurveyQuestions(newQuestions);
    }

    function addQuestion(newQuestion, newOptions) {   
        const newQuestions = [...surveyQuestions, { id: surveyQuestions.length + 1, question: newQuestion, options: newOptions }];
        setSurveyQuestions(newQuestions);
    }

    return (
        <div className="flex flex-row w-full">
            <div className="w-1/5 border-r-2 border-b-2 overflow-y-auto bg-slate-50">
                <InteractionSidebar conversationTitles={surveyTitles} updateConversation={updateSurvey} newChat={newSurvey} convType={convType} />
            </div>
        
            <div className="flex flex-col w-full h-full">
                <div className="w-full h-full bg-slate-50">
                    {surveyState === SurveyStatus.intake && <SurveyBrief submit={intakeNext} name={surveyForm.surveyName} labelName={surveyForm.surveyLabelName}  buttonName={surveyForm.intakebutton}/>}
                    {surveyState === SurveyStatus.questions && <SurveyQuestions submit={questionsNext} name={surveyForm.qName} objective={surveyDescription} questions={surveyQuestions} buttonName={surveyForm.qButtonName} edit={editQuestion} delete={deleteQuestion} add={addQuestion} />}
                    {surveyState === SurveyStatus.results && <SurveyResults submit={questionsNext} name={surveyForm.qName} results={surveyResults} buttonName={surveyForm.qButtonName} objective={surveyDescription} />}
                    {surveyState === SurveyStatus.qloading && <div className="w-full h-full bg-slate-50 items-center content-center"> <Loading /> </div>}
                    {surveyState === SurveyStatus.rloading && <SurveyPartialResults results={surveyResults} objective={surveyDescription} />}
                </div>          
            </div>            
        </div>
    )
}

export default SurveyWorkflow;
