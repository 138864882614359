import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const Loading = () => {
    return (
        <div className="flex flex-row flex-wrap w-full justify-center m-4">
            {/*
            <div className="flex w-full justify-center m-4">
                <h1>Loading...</h1>
            </div>
            */}
            <div className="flex w-full justify-center">
                <Box sx={{width: 800}}>
                    <Skeleton  />
                    <Skeleton animation="wave" />
                    <Skeleton  />                        
                </Box>
            </div>
        </div>
    );
}
export default Loading;