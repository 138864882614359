import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, set } from  'react-hook-form';
import { useDropzone } from 'react-dropzone';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import GetUser from '../../utils/users/GetUser';

const ProjectBrief = (props) => {

    const [text, setText] = useState('');
    const { register, control, reset, handleSubmit } = useForm();

    const [selectedProjectType, setSelectedProjectType] = useState('');
    const [selectedProjectMethod, setSelectedProjectMethod] = useState('');

    // personas
    const [selectedSegment, setSelectedSegment] = useState([]);
    const [segmentOptions, setSegmentOptions] = useState(props.segments);

    // panels
    const [panelOptions, setPanelOptions] = useState(props.panels);
    const [selectedPanel, setSelectedPanel] = useState([]);

    const [selectedAudience, setSelectedAudience] = useState('');
    const [personaOrArchetype, setPersonaOrArchetype] = useState("Personas");

    const projectTypeOptions = [
        {value: 'Customer Insights', label: 'Customer Insights'},
        {value: 'Concept Testing', label: 'Concept Testing'},
    ];

    const projectMethodOptions = [
        {value: 'Qualitative', label: 'Qualitative'},
        {value: 'Quantitative', label: 'Quantitative'},
    ];

    const audienceOptions = [
        {value: 'Personas', label: personaOrArchetype},
        {value: 'Panels', label: 'Panels'},
    ]

    const questionOptions = [
        {value: '0', label: 'None - will enter manually'},
        {value: '5', label: '5'},
        {value: '10', label: '10'},
        {value: '15', label: '15'},
        {value: '20', label: '20'},
    ];

    const conceptOptions = [
        {value: '0', label: 'None - will enter manually'},
        {value: '5', label: '5'},
        {value: '10', label: '10'},
        {value: '15', label: '15'},
        {value: '20', label: '20'},
    ];

    let isTable=true;

    useEffect(() => {

        console.log("Segments: ", props.segments);
        console.log("Panels: ", props.panels);

        const fetchUser = async () => {
            const user = await GetUser();
            if (user.attributes.email.includes("suntory")) {
                setPersonaOrArchetype("Archetypes");
            }
        }

        const segmentOptions = props.segments.map((segment) => {
            return { value: segment.name, label: segment.name, id: segment.id };
        });

        const panelOptions = props.panels.map((panel) => {
            return { value: panel.name, label: panel.name, id: panel.id };
        });

        fetchUser();
        setSegmentOptions(segmentOptions);
        setPanelOptions(panelOptions);

    }, [props.segments, props.panels]);

    const onSubmit = async(data) => {
        console.log(data);
        props.handleSubmit(data);
    };

    const handleAudienceChange = (audience) => {  
        console.log("Audience changed to: " + audience);
        setSelectedAudience(audience);
    }

    const handlePanelChange = async(panel) => {
        console.log("Panel changed to: " + panel.value);
        setSelectedPanel(panel);
    }

    const handleSegmentChange = async(segment) => {
        console.log("Segment changed to: " + segment.value);
        setSelectedSegment(segment);
    }

  return (
    <div className="flex flex-row flex-wrap w-full h-screen content-start bg-slate-50 drop-shadow-sm">
        <div className="flex flex-row w-full h-screen text-left align-middle">
            <div className="w-full h-full overflow-x-hidden">
                <div className="p-4 border-b-2">
                    <h5 className="text-black">
                        Create New Project
                    </h5>
                </div>
                <form className="flex flex-row flex-wrap w-10/12 h-fit" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center w-full">
                        <label className="m-4 w-32">Name</label>
                        <input 
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Project Name"
                            {...register('name', { required: true })}
                            required
                            className="content-center rounded-lg border-1 w-72 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                        </input>
                    </div>

                    <div className="flex items-center w-full">
                        <label className="m-4 w-32">Type</label>
                        <Controller 
                            name="type"
                            control={control} 
                            render={({ value, field }) => ( 
                                <Select {...field} className="w-72 min-w-max m-4"
                                    options={projectTypeOptions}
                                    onChange={(selectedOption) => {
                                        setSelectedProjectType(selectedOption.value)
                                        field.onChange(selectedOption.value);
                                    }}
                                    value={projectTypeOptions.find((c) => c.value === value)}
                                    required
                                />)}
                        />                            
                    </div>

                    <div className="flex items-center w-full">
                    <label className="m-4 w-32">Objective</label>
                        <textarea
                            id="prompt-input"
                            rows="2"
                            className="w-10/12 content-center rounded-lg border-1 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                            placeholder="Please enter the objective of this project in as much detail as possible"
                            required
                            {...register('objective')}></textarea>
                    </div>

                    <div className="flex items-center w-full">
                        <label className="m-4 w-32">Method</label>
                        <Controller 
                            name="method"
                            control={control} 
                            render={({ value, field }) => ( 
                                <Select {...field} className="w-72 min-w-max m-4"
                                    options={projectMethodOptions}
                                    onChange={(selectedOption) => {
                                        setSelectedProjectMethod(selectedOption.value)
                                        field.onChange(selectedOption.value);
                                    }}
                                    value={projectMethodOptions.find((c) => c.value === value)}
                                    required
                                />)}
                        />                         
                    </div>

                    <div className="flex items-center w-full">
                            <label className="m-4 w-32">Audience</label>
                            <Controller
                                name="audience"
                                control={control}
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={audienceOptions}
                                        onChange={
                                            (selectedOption) => { 
                                                handleAudienceChange(selectedOption.value);
                                                field.onChange(selectedOption.value);
                                            }
                                        } 
                                        value={audienceOptions.find((c) => c.value === value)}
                                        required
                                    />)}
                            />
                        </div>    

                        {selectedAudience === "Panels" && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-32">Panels</label>
                            <Controller
                                name="panel"
                                control={control}
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={panelOptions}
                                        onChange={
                                            (selectedOption) => { 
                                                handlePanelChange(selectedOption);
                                                field.onChange(selectedOption);
                                            }
                                        } 
                                        value={panelOptions.find((c) => c.value === value)}
                                        required
                                    />)}
                            />
                        </div>    
                        )}

                        {selectedAudience === "Personas" && (
                        <div className="flex items-center w-full">
                            <label className="m-4 w-32">{personaOrArchetype}</label>
                            <Controller
                                name="segment"
                                control={control}
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={segmentOptions}
                                        onChange={
                                            (selectedOption) => { 
                                                handleSegmentChange(selectedOption);
                                                field.onChange(selectedOption);
                                            }
                                        } 
                                        value={segmentOptions.find((c) => c.value === value)}
                                        required
                                    />)}
                            />
                        </div>    
                        )}

                        <div className="flex items-center w-full">
                        <label className="m-4 w-32"># of Questions</label>
                        <Controller 
                            name="questionCount"
                            control={control} 
                            render={({ value, field }) => ( 
                                <Select {...field} className="w-72 min-w-max m-4"
                                    options={questionOptions}
                                    onChange={(selectedOption) => {
                                        field.onChange(selectedOption.value);
                                    }}
                                    value={questionOptions.find((c) => c.value === value)}
                                    
                                />)}
                        />                            
                    </div>

                    {selectedProjectType === "Concept Testing" && (
                    <div className="flex items-center w-full">
                        <label className="m-4 w-32"># of Concepts</label>
                        <Controller 
                            name="conceptCount"
                            control={control} 
                            render={({ value, field }) => ( 
                                <Select {...field} className="w-72 min-w-max m-4"
                                    options={conceptOptions}
                                    onChange={(selectedOption) => {
                                        field.onChange(selectedOption.value);
                                    }}
                                    value={conceptOptions.find((c) => c.value === value)}
                                    
                                />)}
                        />                            
                    </div>
                    )}

                        {/*
                        {selectedAudience === "Personas" && selectedSegments !== null && (
                            personas.length > 0 ? (
                            <div className="flex w-fit overflow-x-hidden m-4">
                                <Personas personas={personas} isTable={isTable} selectedPersonas={selectedPersonas}/>
                            </div>
                            ) : (
                                <div className="flex items-center w-full">
                                    <label className="m-4 w-32"></label>
                                    <h5 className="m-4">You must create at least one persona before you can launch a qualitative project.</h5>
                                </div>
                            )
                        )}
                        */}

                    <div className="flex flex-row w-full justify-end mt-4">
                        <label className="w-32"></label>
                        <button 
                            type="submit"
                            name='next' 
                            className="mr-20 mb-10 px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 disabled:bg-slate-500 disabled:text-black hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                            Next
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div> 
    
  );
}

export default ProjectBrief;
