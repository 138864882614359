// use state
import { useEffect, useState } from "react";
// react router
import { Link } from "react-router-dom";
// AWS
import { API, Auth } from 'aws-amplify';
// GraphQL
import * as queries from '../../graphql/queries';
import { findAllByDisplayValue } from "@testing-library/react";
import { set } from "react-hook-form";
// custom components
import ProjectSidebar from "../../utils/navigation/ProjectSidebar";
import AddIcon from '@mui/icons-material/Add';
import NewProject from "./ProjectBrief";
import { DataGridPro } from '@mui/x-data-grid-pro';


const Projects = (props) => {

    // loading 
    const [loading, setLoading] = useState(true);
    // projects
    const qualProjects = props.qualProjects;
    const quantProjects = props.quantProjects;
    const conceptProjects = props.conceptProjects;
    const projects = props.projects;

    console.log("Project List: ", projects);
    console.log("Qual Projects: ", qualProjects);
    console.log("Quant Projects: ", quantProjects);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading])

    const columns = [
        {field: 'title', headerName: 'Title', width: 250},
        {field: 'objective', headerName: 'Objective', width: 400},
        {field: 'type', headerName: 'Type', width: 200},
        {field: 'method', headerName: 'Method', width: 150},
        {field: 'audience', headerName: 'Respondents', width: 200},
        {field: 'date', headerName: 'Date', width: 100},
    ]

    const rows = projects.map((project) => {
        return {
            id: project.id,
            title: project.title,
            objective: project.objective,
            type: project.type,
            method: project.method,
            audience: project.audience,
            date: project.date,
        }
    })

    return (
        <div className="flex flex-col w-full m-4 bg-slate-50 overflow-x-hidden overflow-y-hidden">
            <div className="flex flex-row flex-wrap w-full bg-slate-50">
                  <div className="flex w-full h-full bg-slate-50">
                  <DataGridPro
                        loading={loading}
                        autoHeight
                        sx={{   fontWeight: 'fontWeightLight',
                            bgcolor: 'white',
                            boxShadow: 2, 
                            borderRadius: 4,
                            border: 1,
                            px: 2,
                        }}
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                        }}
                        pagination
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        //checkboxSelection
                  />
                  </div>
            </div>
        </div>
    )
  };

  export default Projects;
