
import { Routes, Route, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import SurveyWorkflow from "../../utils/surveys/SurveyWorkflow";
import PersonaChatWorkflow from "../../utils/chats/PersonaChatWorkflow";
import PersonaOverview from "./PersonaOverview";
import InterviewWorkflow from "../../utils/interviews/InterviewWorkflow";

const Persona = (props) => {
    
    const { personaID } = useParams();
    const navigate = useNavigate();

    const persona = props.persona;
    const chatPath = `/personas/${personaID}/chat`;
    const interviewPath = `/personas/${personaID}/interview`;
    const surveyPath = `/personas/${personaID}/survey`;

    function handleNewChat() {
        navigate(chatPath);
    }

    function handleNewInterview() {
        navigate(interviewPath);
    }

    function handleNewSurvey() {
        navigate(surveyPath);
    }

    return (
        <div className="flex flex-col w-full h-fit bg-slate-50">
            <div className="mr-8 h-full w-full">
                        <Routes>
                            <Route path="/" element={<PersonaOverview persona={persona} handleNewChat={handleNewChat} handleNewInterview={handleNewInterview} handleNewSurvey={handleNewSurvey} />} />
                            <Route path="/chat" element={<PersonaChatWorkflow persona={persona} />} />
                            <Route path="/chat/:conversationID" element={<PersonaChatWorkflow persona={persona} />} />
                            <Route path="/interview" element={<InterviewWorkflow persona={persona} /> } />
                            <Route path="/interview/:conversationID" element={<InterviewWorkflow persona={persona} />} />
                            <Route path="/survey" element={<SurveyWorkflow persona={persona} />} />
                            <Route path="/survey/:conversationID" element={<SurveyWorkflow persona={persona} />} />
                        </Routes>
            </div>           
        </div>
    )
}

export default Persona;
