import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const SaveConceptQuestion = async (data) => {
    console.log("SaveConceptQuestion data")
    console.log(data)
    
    const apiData = await API.graphql({ 
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: mutations.createConceptQuestions, // change this after we know DB schema
        variables: {
            input: data,
        }
    });

    return apiData.data.createConceptQuestions.id;   
}

export default SaveConceptQuestion;