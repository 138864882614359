import Loading from "../Loading";
const InterviewPartialResults = (props) => {
    return (
        <div className="flex flex-col w-full content-start bg-slate-50">
            <div className="w-full h-full bg-slate-50 items-center content-center"> 
            </div>
            <table className="table-auto w-full border border-slate-500">
                <thead className="content-start">
                    <tr className="border bg-slate-50">
                        <th className="px-4 py-4 text-left">
                                <h5>Objective</h5>
                                </th>
                                <th className="border px-4 py-4 text-left">
                                    <p>{props.objective}</p>
                                </th>
                    </tr>
                    <tr className="border bg-slate-50">
                        <th className="px-4 py-4 border text-left">
                            <h5>Question</h5>
                        </th>
                        <th className="px-4 py-4 border text-left">
                            <h5>Answer</h5>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.results && props.results.map((item) => (
                        <tr key={item.id} className="border">
                            <td className="border text-left p-4 whitespace-pre-wrap">{item.question}
                            </td>
                            <td className="border text-left p-4 whitespace-pre-wrap">{item.answer}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Loading />
        </div>
    )
}

export default InterviewPartialResults;