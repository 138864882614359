import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetLLMAssistantByPanelistID = async (personaID) => {
    const response = await API.graphql({
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: queries.lLMAssistantsByPanelistID,
        variables: { panelistID: personaID }
    });
    return response.data.lLMAssistantsByPanelistID.items[0];
}

export default GetLLMAssistantByPanelistID;