// use state
import { useEffect, useState } from "react";

const PersonaCard = (props) => {
    
    console.log(props.personas)

    const personas = props.personas; 

  return (
      <div className="flex flex-col w-full h-full bg-slate-50 overflow-x-hidden">
          <div className="flex flex-row flex-wrap w-full h-full bg-slate-50">
          <div className="flex flex-col flex-wrap w-full h-full max-h-fit bg-slate-50 overflow-y-auto">

                    <div className="flex flex-wrap content-start space-x-4 space-y-4 p-2">            
                        {personas.map((persona) => (
                            <div key={persona.id} className="flex flex-col bg-white p-4 m-2 rounded-xl drop-shadow-lg w-full">
                                <div className="justify-items-start">
                                <h5 className="text-left text-slate-500 font-medium">
                                    {persona.name}
                                </h5>
                                <hr />
                                </div>
                                <ul className="text-left text-slate-500 font-medium list-disc px-4">      
                                    {(persona.segment) && (<li key={persona.segment}>Segment: {persona.segment}</li>)}                     
                                    {(persona.age) && (<li key={persona.age}>Age: {persona.age}</li>)}
                                    {(persona.gender) && (<li key={persona.gender}>Gender: {persona.gender}</li>)}                             
                                    {(persona.race) && (<li key={persona.race}>Race:  {persona.race }</li>)}
                                    {(persona.education) && (<li key={persona.education}>Education:  {persona.education}</li>)}
                                    {(persona.occupation) && (<li key={persona.occupation}>Occupation: {persona.occupation}</li>)}
                                    {(persona.marital_status) &&(<li key={persona.maritalStatus}>Marital Status: {persona.marital_status}</li>)}
                                    {(persona.children) && (<li key={persona.children}>Children: {persona.children}</li>)}
                                    {(persona.income) && (<li key={persona.income}>Income: {persona.income}</li>)}
                                    {(persona.netWorth) && (<li key={persona.netWorth}>Net Worth: {persona.netWorth}</li>)}
                                    {(persona.city) && (<li key={persona.city}>City: {persona.city}</li>)}
                                    {(persona.metro) && (<li key={persona.metro}>Metro: {persona.metro}</li>)}
                                    {(persona.region) && (<li key={persona.region}>Region: {persona.region}</li>)}
                                    {(persona.state) && (<li key={persona.state}>State: {persona.state}</li>)}
                                    {(persona.values) &&(<li key={persona.values}>Values & Beliefs: {persona.values}</li>)}
                                    {(persona.lifestyle) && (<li key={persona.lifestyle}>Lifestyle & Activities: {persona.lifestyle}</li>)}
                                    {(persona.attitudes) && (<li key={persona.attitudes}>Attitudes & Opinions: {persona.attitudes}</li>)}
                                    {(persona.interests) && (<li key={persona.interests}>Interests & Hobbies: {persona.interests}</li>)}
                                    {(persona.personality) && (<li key={persona.personality}>Personality Traits: {persona.personality}</li>)}
                                    {(persona.social) && (<li key={persona.social}>Social & Cultural: {persona.social}</li>)}
                                </ul>
                            </div>
                        ))}
                    </div>
          </div>
      </div>
    </div>
  )
};
  
  export default PersonaCard;