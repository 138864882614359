import { Card, Title, Subtitle, BarChart } from "@tremor/react";
import Loading from "../Loading";

const data = [
    { option: "neutrals", value: 30},
    { option: "pastels", value:30},
    { option: "bold colors", value: 40},
]

const valueFormatter = (number) => `${Intl.NumberFormat("us").format(number).toString()}%`;

const SurveyPartialResults = (props) => {
    //console.log(props.results)
    return (
        <div className="flex flex-col w-full content-start bg-slate-50">
            <div className="w-full h-full bg-slate-50 items-center content-center">  </div>
            <table className="table-auto w-full border border-slate-500">
                        <thead className="content-start">
                            <tr className="border bg-slate-50">
                            <th className="px-4 py-4 text-left">
                                    <h5>Objective</h5>
                                    <p>{props.objective}</p>
                                </th>
                                <th className="px-4 py-4 border text-left">
                                    <h5>Results</h5>
                                    </th>
                            </tr>
                        </thead>
                        <tbody>

                        
                        {props.results && props.results.map((item) => (
                            <tr key={item.id} className="border">
                                <td className="border text-left p-8">
                                    <div className="flex flex-row">
                                    
                                        <Card className="w-full" decoration="">
                                            <Title className="text-center">{item.question}</Title>
                                            <BarChart 
                                                data={item.answers}
                                                categories={["percent"]}
                                                index="option"
                                                valueFormatter={valueFormatter}
                                                variant="pie"
                                                colors={["blue"]}
                                                maxValue={100}
                                                showLegend={false}
                                                yAxisWidth={48}
                                            />
                                        </Card>
                                        
                                    </div>
                                </td>
                            </tr>
                        ))} 
                    </tbody>
            </table>
            <Loading />
        </div>
    )
}

export default SurveyPartialResults;