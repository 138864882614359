// MUI material icons
import SendIcon from '@mui/icons-material/Send';

// react
import React, { useRef, useState } from 'react';

//components
import Animate from '../utils/Animate';

const SearchInput = (props) => {
    const textareaRef = useRef(null)
    //const [isAnimate, setIsAnimate]  = useState(props.isLoading)
    const handleSubmit = async(event) => {
        event.preventDefault();
        const query = textareaRef.current.value;
        //setIsAnimate(true)
        props.updateUserMessage(query)
        textareaRef.current.value=''
    }
    //console.log(isAnimate)
    return (   
        <div>
            {/*isAnimate && <Animate /> */}

             <form className="flex flex-row border border-slate-200 mt-4 mb-4 px-2 py-2 w-full rounded-lg items-end bg-slate-200 dark:bg-slate-900 dark:border-slate-900">
                <label htmlFor="prompt-input" className="sr-only">Ask a question</label>
                <textarea
                    id="prompt-input"
                    rows="2"
                    className="w-full items-end border-0 bg-slate-200 dark:bg-slate-900 px-0 text-base align-middle text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                    placeholder="Ask a question"
                    required
                    ref={textareaRef}></textarea>

                <button type="submit" onClick={handleSubmit}
                    className="items-end rounded-lg bg-blue-600 px-4 py-2.5 text-center text-base font-medium text-slate-50 hover:bg-blue-800 focus:ring-4 focus:ring-blue-900">            
                    <SendIcon />
                </button>
            </form>

        </div> 
           
   
   ) 
}
export default SearchInput;

/*
<div className="ml-2 flex items-center py-2">
            <div>
                <button 
                type="submit"
                onClick={handleSubmit}
                className="inline-flex items-center gap-x-2 rounded-lg bg-blue-600 px-4 py-2.5 text-center text-base font-medium text-slate-50 hover:bg-blue-800 focus:ring-4 focus:ring-blue-900"
                >
                
                <SendIcon />
                </button>
                
            </div>
            </div>

*/
    