import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetProjectBriefs = async () => {

    const userInfo = await Auth.currentAuthenticatedUser();

    // transform interview brief titles to format for display to the component
    const formatInterviewBriefs = (datainput) => {       
            
        return datainput.map(item => {
            const dateObj=new Date(item.createdAt)
            // Array of short month names
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // Format the date as "Mon day", e.g., "Aug 11"
            const formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;

            // Return the transformed object
            return {
                "id": item.id,
                "title": item.title,
                "objective": item.objective,
                "type": item.type,
                "method": item.method,
                "date": formattedDate,
                "audience": item.audience,
            };
        })
    }

    let result;            
            
    // get all the interviews for this persona                               
    try {
        result = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.listProjectBriefs,
            variables: {
                filter: {
                    owner: {eq: userInfo.attributes.email},
                }
            },                
        });
    } catch(err) {
        console.error(err);
    }

    let interviewBriefList = result.data.listProjectBriefs.items;
    
    interviewBriefList.sort((a,b) => {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB-dateA
    })

    //console.log("List of all interview briefs")
    //console.log(interviewBriefList)

    const formattedInterviews = formatInterviewBriefs(interviewBriefList)
    
    return formattedInterviews;
    
}

export default GetProjectBriefs;