// use state
import { useState, useEffect } from "react";
// AWS
import { Auth, API } from 'aws-amplify';
// local components
import InteractionSidebar from "../navigation/InteractionSidebar";
import InterviewQuestions from "./InterviewQuestions";
import InterviewResults from "./InterviewResults";
import InterviewBrief from "./InterviewBrief";

// GraphQL
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

import { useParams } from "react-router-dom";

// Loading
//import CircularProgress from '@mui/material/CircularProgress';
import Loading from "../Loading";
import InterviewPartialResults from "./InterviewPartialResults";
import { set } from "react-hook-form";

const interviewForm = {
    "interviewName": "Enter the interview objective", 
    "interviewLabelName": "Enter the interview objective",
    "intakebutton": "Generate Questions",
    "qName": "Interview Questions",
    "qButtonName": "Conduct Interview",
};

//let interviewQuestions;
let interviewQuestionsText;
let interviewID;
let interviewDescription;
let interviewObjective;


// interview form status
export const InterviewStatus = {
    intake:"intake",
    questions:"questions",
    results: "results",
    qloading: "qloading",
    rloading: "rloading",
}

const convType = "Interviews"


// openai model
//const model1 = "gpt-4";
const model1 = "gpt-3.5-turbo";
const model2 = "gpt-3.5-turbo";
//const model2 ="gpt-4";

const InterviewWorkflow = (props) => {

    const [interviewState, setInterviewState] = useState(InterviewStatus.intake);
    // user
    const [user, setUser] = useState(null);
    // persona
    const persona = props.persona;
    // persona ID
    const personaId = persona.id;  
    // interview titles
    const [interviewTitles, setInterviewTitles] = useState([]);
    // params
    const { conversationID } = useParams();
    // interview results
    const [interviewResults, setInterviewResults] = useState([]);
    // set interview questions
    const [interviewQuestions, setInterviewQuestions] = useState([]);


    // transform interview brief titles to format for display to the component
    const transformInterviewTitles = (datainput) => {       
        
        return datainput.map(item => {
            const dateObj=new Date(item.createdAt)
            // Array of short month names
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // Format the date as "Mon day", e.g., "Aug 11"
            const formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;

            // Return the transformed object
            return {
                "id": item.id,
                "title": item.title,
                "date": formattedDate,
            };
        })
    }

    // fetch user and conversations
    useEffect(() => {

        const fetchInterviews = async() => {
            // get personaID
            let result;            
            
            // get all the interviews for this persona                               
            try {

                const userInfo = await Auth.currentAuthenticatedUser();

                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.listCustomerInterviewBriefs,
                    variables: {
                        filter: {
                            owner: {eq: userInfo.attributes.email},
                            customerPersonaID: {eq: personaId},
                        }
                    },                
                });
            } catch(err) {
                console.error(err);
            }

            let interviewBriefList = result.data.listCustomerInterviewBriefs.items;
            interviewBriefList.sort((a,b) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
    
                return dateB-dateA
            })
            
            console.log("List of all interview briefs for this persona")

            let displayTitle = transformInterviewTitles(interviewBriefList)
            //console.log("List of conversation title")
            //console.log(displayTitle)
            setInterviewTitles(displayTitle)

        }
        
        const fetchUser = async() => {
            try {
                const amplifyUser = await Auth.currentAuthenticatedUser();
                setUser(amplifyUser);
            } catch (error) {
                setUser(null);
                console.log(error);
            }
        }

        fetchUser();
        fetchInterviews();
    },[])


    
    const updateInterview = async(id) => {
        interviewID = id;
        setInterviewState(InterviewStatus.qloading);

        // get the interview brief corresponding to this id
        let result;
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.getCustomerInterviewBrief,
                variables: {
                    id: interviewID,
                }
            });
        } catch(err) {
            console.error(err);
        }
        // get the interview questions for this ID
        setInterviewQuestions(result.data.getCustomerInterviewBrief.questions);
        //interviewQuestions = result.data.getCustomerInterviewBrief.questions;
        interviewQuestionsText = result.data.getCustomerInterviewBrief.questionText;
        interviewObjective = result.data.getCustomerInterviewBrief.objective;

        console.log(interviewQuestions);

        // get interview results for this ID
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.listCustomerInterviewResults,
                variables: {
                    filter: {
                        customerInterviewBriefID: {eq: interviewID},
                    }                    
                },
            });
        } catch(err) {
            console.error(err);
        }

        // get the interview results for this ID
        const interviewResultsID = result.data.listCustomerInterviewResults.items[0].id;

        // get interview results for this ID
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.getCustomerInterviewResults,
                variables: {
                    id: interviewResultsID,
                }
            });
        } catch(err) {
            console.error(err);
        }

        setInterviewResults(result.data.getCustomerInterviewResults.customerInterviewAnswers);
        setInterviewState(InterviewStatus.results);



        // get the interview results for this ID
        /*
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.listInterviewResults,
                variables: {
                    filter: {
                        InterviewBriefID: {eq: interviewID},
                    }                    
                },
            });
        } catch(err) {
            console.error(err);
        }
        const interviewResultsID = result.data.listInterviewResults.items[0].id;

        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.getInterviewResults,
                variables: {
                    id: interviewResultsID,
                }
            });
        } catch(err) {
            console.error(err);
        }*/

        //setInterviewState(InterviewStatus.questions);
    }

    const newInterview = async() => {
        setInterviewState(InterviewStatus.intake);
        interviewID = null;
    }

    function format_llm_answer(text) {
        // Split the text into blocks by double newline
        const blocks = text.split('\n');

        // Map each block to an object
        const questions = blocks.map((block, index) => {

            const removeIndex = (str) => {
                // Regular expression to match a number followed by a space or a period and a space
                const regex = /^\d+\.?\s+/;
                return str.replace(regex, '');
            };
            
            return { id: index + 1, question: removeIndex(block) };
        });

        console.log(questions);

        return questions;
    }

    const intakeNext = async(query) => {
        let result;
        let response;
        let title;

        setInterviewState(InterviewStatus.qloading);

        // get the questions from LLM
        try {
            response = await API.post('openaigptapi', '/chat', {
                body: {
                    input: {
                        question: query,                    
                        model: model1,                    
                        mode: "interview_question",                    
                    }
                },
            });
        } catch(err) {
            console.error(err);
        }

        interviewQuestionsText = response.Answer.trim();  
        setInterviewQuestions(format_llm_answer(interviewQuestionsText));      
        //interviewQuestions = format_llm_answer(interviewQuestionsText);
        interviewDescription = query;
                
        // get the title for the interview brief
        const interviewtitle = "Summarize the interview brief to set a short descriptive title (2-5 words) for this interview description: " + interviewDescription;
        
        try {
            title = await API.post('openaigptapi', '/chat', {
                    body: {
                    input: {
                        question: interviewtitle,
                        history: "",
                        model: model2,                
                        mode: "simple",
                        sources: [],
                    }
                },
                });
            } catch(err) {
                console.error(err);
            }

        const parsedDataTitle = title.Answer.trim();
       
        // create entry for interview brief
        const interviewBrief = {
            // id will be auto-populated by AWS
            title: parsedDataTitle,  // title 
            owner: user.attributes.email,
            objective: interviewDescription,
            numberOfQuestions: interviewQuestions.length,
            questions: interviewQuestions,
            // belongs to the persona
            customerPersonaID: personaId,
            questionText: interviewQuestionsText,
        }

        //console.log(interviewBrief);

        // Create a new interview brief in the dB            
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: mutations.createCustomerInterviewBrief,
                variables: {
                    input: interviewBrief,                 
                }
            });
        } catch(err) {
            console.error(err);
        }

        interviewID = result.data.createCustomerInterviewBrief.id;
        const dateObj = new Date(result.data.createCustomerInterviewBrief.createdAt)
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;

        const parsedDataTitleDisplay = {"id": interviewID, "title": parsedDataTitle, "date": formattedDate}
        setInterviewTitles(prevArr => [parsedDataTitleDisplay, ...prevArr])
        setInterviewState(InterviewStatus.questions);
    }

    function format_interview_results(text) {
        // Split the text into blocks by double newline
        const blocks = text.split('\n\n');

        // Map each block to an object
        const results = blocks.map((block, index) => {
            const parts = block.split('\n');
            const questionPart = parts[0];
            const answerPart = parts[1];

            const question = questionPart.length ? questionPart.split(': ')[1] : "";
            const answer = answerPart.length ? answerPart.split(': ')[1] : "";
            
            return { id: index + 1, question: question, answer: answer };
        });

        return results;
    }
    
    
    const questionsNext = async() => {      
        // get the questions from LLM one by one
        let interviewResultsDBAnswer = [];
  
                
        setInterviewState(InterviewStatus.rloading);
        const personaType = persona.type;
        let persona_details;
        if (personaType === "Business") {
            persona_details = "Persona Name: " + persona.name + ", Role: " + persona.role +
            ", Industry: " + persona.industry + ", Title: " + persona.seniority + 
            ", Education Level: " + persona.education + ", Company Size: " + persona.companySize + 
            ", Other Details:" + persona.details + ".";
        }
        else {
            persona_details = "Persona Name: " + persona.name + ", Age: " + persona.age +
            ", Gender: " + persona.gender + ", Education Level: " + persona.education + 
            ", Occupation: " + persona.occupation + ", Marital Status: " + persona.maritalStatus + 
            ", Income: " + persona.income + ", Net Worth: " + persona.netWorth + 
            ", Other Details:" + persona.details + ".";

        }

        
        try {
            for (let i = 0; i < interviewQuestions.length; i++) {
                const response = await API.post('openaigptapi', '/chat', {
                    body: {
                        input: {
                            question: interviewQuestions[i].question,
                            persona: persona.name,
                            persona_details: persona_details,
                            model: model1,
                            mode: "run_interview",
                        }
                    },
                });
                
                //console.log(response.Answer.trim());
                //console.log("Now the detailed response");
                //console.log(response)
                // append the answer to the interview results
                const answerPart = response.Answer.trim().split('Answer: ')[1];
                //console.log(answerPart);
                const stopIndex = answerPart.indexOf("Question:");
                // if the stopindex is greater than 0, trim the answer
                const answer = stopIndex > 0 ? answerPart.substring(0, stopIndex).trim() : answerPart;        
                console.log("After getting the answer and trimming")
                console.log(answer)          
                interviewResultsDBAnswer.push({"id": i+1, "question": interviewQuestions[i].question, "answer": answer});
                setInterviewResults(prevArr => [...prevArr, {"id": i+1, "question": interviewQuestions[i].question, "answer": answer}]);
            }
        } catch(err) {
            console.error(err);
        }

        // get the questions from LLM
        /*const response = await API.post('openaigptapi', '/chat', {
            body: {
                input: {
                    question: interviewQuestionsText,
                    persona: persona.name,
                    persona_details: persona_details,           
                    model: model1,                    
                    mode: "run_interview",
                }
            },
        });

        console.log(response.Answer.trim());
        interviewResults = format_interview_results(response.Answer.trim());
        
        console.log(interviewResults);
        */
        // create db entry for interview results
        const interviewResultsDB = {
            // id will be auto-populated by AWS
            owner: user.attributes.email,            
            customerInterviewBriefID: interviewID,            
            customerInterviewAnswers: interviewResultsDBAnswer,
        }

        // create new interview results in the dB
        try {
            const result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: mutations.createCustomerInterviewResults,
                variables: {
                    input: interviewResultsDB,                 
                }
            });
        } catch(err) {
            console.error(err);
        }

        setInterviewState(InterviewStatus.results);
    }

    function editQuestion(id, text) {
        console.log("edit button");
        const newQuestions = interviewQuestions.map(question => 
            question.id === id ? { ...question, question: text } : question
        );
        setInterviewQuestions(newQuestions);
    }

    function deleteQuestion(id) {
        let newQuestions = interviewQuestions.filter(question => question.id !== id);
        newQuestions = newQuestions.map((question, index) => ({ ...question, id: index + 1 }));
        setInterviewQuestions(newQuestions);
    }

    function addQuestion(newQuestion) {   
        const newQuestions = [...interviewQuestions, { id: interviewQuestions.length + 1, question: newQuestion }];
        setInterviewQuestions(newQuestions);
        //console.log(newQuestions);
    }

    useEffect(() => {
        // Perform actions that depend on the updated interviewQuestions here
        console.log("interview questions updated");
    }, [interviewQuestions]);
    
    return (
        <div className="flex flex-row w-full">
            <div className="w-1/5 border-r-2 border-b-2 overflow-y-auto bg-slate-50">
                <InteractionSidebar conversationTitles={interviewTitles} updateConversation={updateInterview} newChat={newInterview} convType={convType} />
            </div>
        
            <div className="flex flex-col w-full h-full">
                <div className="w-full h-full bg-slate-50">
                    {interviewState === InterviewStatus.intake && <InterviewBrief submit={intakeNext} name={interviewForm.interviewName} labelName={interviewForm.interviewLabelName}  buttonName={interviewForm.intakebutton}/>}
                    {interviewState === InterviewStatus.questions && <InterviewQuestions submit={questionsNext} name={interviewForm.qName} objective={interviewObjective} questions={interviewQuestions} buttonName={interviewForm.qButtonName} edit={editQuestion} delete={deleteQuestion} add={addQuestion}/>}
                    {interviewState === InterviewStatus.results && <InterviewResults submit={questionsNext} name={interviewForm.qName} results={interviewResults} buttonName={interviewForm.qButtonName}/>}
                    {interviewState === InterviewStatus.qloading && <div className="w-full h-full bg-slate-50 items-center content-center"> <h1 className="text-center align-middle">Generating questions...</h1> <Loading /> </div>}
                    {interviewState === InterviewStatus.rloading && <InterviewPartialResults results={interviewResults} />}
                </div>          
            </div>            
        </div>
    )
}

export default InterviewWorkflow;
