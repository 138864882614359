// use state
import { useEffect, useState } from "react";
// Table component
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { ThemeProvider, createTheme } from '@mui/system';
// custom components
import PersonaProfile from "../../utils/personas/PersonaProfile";

const PersonaTable = (props) => {

    console.log("in Personas")
    
    console.log(props.personas)
    // display format
    const [displayFormat, setDisplayFormat] = useState('Card');
    // persona
    const personas = props.personas; 
    // loading 
    const [isLoading, setIsLoading] = useState(props.isLoading);
    // user
    const [user, setUser] = useState(null);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    //const [selectedPersonas, setSelectedPersonas] = useState([]);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    const theme = createTheme({
        palette: {
            background: {
              paper: '#fff',
            },
            text: {
              primary: '#173A5E',
              secondary: '#46505A',
            },
            action: {
              active: '#001E3C',
            },
            success: {
              dark: '#009688',
            },
        },
    });

    const columns = [
        {field: 'segment', headerName: 'Segment', width: 175, hide: true},
        {field: 'name', headerName: 'Name', width: 175},
        {field: 'age', headerName: 'Age', width: 100},
        {field: 'gender', headerName: 'Gender', width: 100},
        {field: 'race', headerName: 'Race', width: 150},
        {field: 'education', headerName: 'Education', width: 150},
        {field: 'occupation', headerName: 'Occupation', width: 200},
        {field: 'marital_status', headerName: 'Marital Status', width: 100},
        {field: 'children', headerName: 'Children', width: 100},
        {field: 'income', headerName: 'Income', width: 175},
        {field: 'netWorth', headerName: 'Net Worth', width: 175},
        {field: 'city', headerName: 'City', width: 150},
        {field: 'metro', headerName: 'Metro', width: 150},
        {field: 'region', headerName: 'Region', width: 150},
        {field: 'state', headerName: 'State', width: 150},
        {field: 'values', headerName: 'Values', width: 500},
        {field: 'lifestyle', headerName: 'Lifestyle', width: 500},
        {field: 'attitudes', headerName: 'Attitudes', width: 500},
        {field: 'interests', headerName: 'Interests', width: 500},
        {field: 'personality', headerName: 'Personality', width: 500},
        {field: 'social', headerName: 'Social', width: 500},
    ]

    // Before defining columns, check if all values for a field are empty
    const checkIfAllValuesAreEmpty = (field) => {
        return personas.every(persona => !persona[field] || persona[field] === "" || persona[field] === " ");
    }

    const handleSelectionChange = (newSelection) => {
        console.log(newSelection);
        //props.selectedPersonas(newSelection);
        //setSelectedPersonas(newSelection);
    }

  return (
      <div className="flex flex-col w-full h-full bg-slate-50 m-4 overflow-x-auto overflow-y-auto">
          <div className="w-full h-full bg-slate-50">
          <div className="w-full h-full bg-slate-50">
                {/*<div className="flex w-full m-2 h-10 bg-slate-50 justify-end">
                        <button 
                            onClick={deletePanelists} 
                            name='delete' 
                            className="px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 disabled:bg-slate-500 disabled:text-black hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                            Delete
                        </button>
                    </div>*/}
                        <DataGridPro
                            loading={isLoading}
                            autoHeight
                            slots = {{ toolbar: GridToolbar }}
                            sx={{   
                                    fontWeight: 'fontWeightLight',
                                    bgcolor: 'white',
                                    boxShadow: 2, 
                                    borderRadius: 4,
                                    border: 1,
                                    px: 2,
                                }}
                            rows={personas}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns status and traderName, the other columns will remain visible
                                        segment: !checkIfAllValuesAreEmpty('segment'),
                                        name: !checkIfAllValuesAreEmpty('name'),
                                        age: !checkIfAllValuesAreEmpty('age'),
                                        gender: !checkIfAllValuesAreEmpty('gender'),
                                        race: !checkIfAllValuesAreEmpty('race'),
                                        education: !checkIfAllValuesAreEmpty('education'),
                                        occupation: !checkIfAllValuesAreEmpty('occupation'),
                                        marital_status: !checkIfAllValuesAreEmpty('marital_status'),
                                        children:  !checkIfAllValuesAreEmpty('children'),
                                        income: !checkIfAllValuesAreEmpty('income'),
                                        netWorth:  !checkIfAllValuesAreEmpty('netWorth'),
                                        city: !checkIfAllValuesAreEmpty('city'),
                                        metro:  !checkIfAllValuesAreEmpty('metro'),
                                        region: !checkIfAllValuesAreEmpty('region'),
                                        state: !checkIfAllValuesAreEmpty('state'),
                                        values: !checkIfAllValuesAreEmpty('values'),
                                        lifestyle: !checkIfAllValuesAreEmpty('lifestyle'),
                                        attitudes: !checkIfAllValuesAreEmpty('attitudes'),
                                        interests: !checkIfAllValuesAreEmpty('interests'),
                                        personality: !checkIfAllValuesAreEmpty('personality'),
                                        social: !checkIfAllValuesAreEmpty('social'),
                                    },
                                  },
                            }}
                            
                            pagination
                            pageSize={10}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            //checkboxSelection
                            onRowSelectionModelChange={handleSelectionChange}
                        />
          </div>
      </div>
    </div>
  )
};
  
export default PersonaTable;