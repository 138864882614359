import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const SavePanels = async (data, createOrChange) => {
    
    if (createOrChange === "create") {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: mutations.createPanel, // change this after we know DB schema
            variables: {
                input: data,
            }
        });
    
        return apiData.data.createPanel.id;

    } else if (createOrChange === "change") {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: mutations.updatePanel, // change this after we know DB schema
            variables: {
                input: data,
            }
        });
    
        return apiData.data.updatePanel.id;
    }
    
}

export default SavePanels;