import React from 'react';
/*
function getDomain(url) {
    try {
        return new URL(url).hostname;
    }
    catch(e) {
        return url
    }
}
*/
/*function trimUrl(url, maxlength=30) {
    if (url.length <=maxlength) return url;
    return url.slice(0,maxlength) + '...'

}
*/
function TextWithLinks(props) {
    //console.log("in textwithlinks component")
    //console.log(props.text)
    //console.log(props.sources)
    //console.log(props.text)
    //console.log(props.sources)
  // Split the text into parts, where each part is either plain text or a reference number
  const parts = props.text.split(/(\[\d+\])/g);
  
  // Map over the array of parts and convert them into an array of JSX elements
  const elements = parts.map((part, index) => {
    const match = part.match(/\[(\d+)\]/);
    
    if (match) {
      const sourceIndex = parseInt(match[1], 10) - 1;
      const source = props.sources[sourceIndex].url;
      //console.log(source)
      
      if (source) {
        return (
          <a key={index} href={source} target="_blank" rel="noopener noreferrer" 
             className="text-xs relative top-[-0.5em] bg-slate-200 text-slate-900 hover:bg-slate-300 focus:ring-slate-200 dark:bg-slate-600 dark:text-white py-1 px-1 rounded dark:hover:bg-slate-400 focus:outline-none focus:ring-2 dark:focus:ring-slate-900 no-underline">
            {match[1]}
          </a>
        );
      }
    }
    
    // If part is not a reference number, render it as plain text
    return part;
  });

  // Create the sources list at the bottom
  /*const sourcesList = props.sources.map((source, index) => (
    <div key={index}>
      {index + 1}. <a href={`https://${source}`} target="_blank" rel="noopener noreferrer"> {trimUrl(source)} </a>
    </div>
  ));
    */
  return (
    <div className="w-full text-left whitespace-pre-line">
      {elements}
      {/*
        <div>
          <br />
          <strong>Sources:</strong>
          {sourcesList}
        </div>
      */}
    </div>
  );


  
  {/*return <div className="w-full text-left whitespace-pre-line">{elements}</div>;*/}
}

export default TextWithLinks;

// Example usage of the component
// import TextWithLinks from './TextWithLinks';

// const text = "Computer vision is the study of visual objects and primary tasks include segmentation [1], object tracking [2], and object detection [3].";
// const sources = ["www.google.com", "www.yahoo.com", "www.msn.com"];

// <TextWithLinks text={text} sources={sources} />
