import React, { useRef, useState, } from 'react';
import addIcon from "../../img/icons8-add-30.png";
import editIcon from "../../img/icons8-edit-30.png";
import deleteIcon from "../../img/icons8-delete-30.png";
import saveIcon from "../../img/icons8-save-30.png";

const ProjectInterviewQuestions = (props) => {
    console.log("in ProjectQuestions")
    //console.log(props)

    const objective = props.objective;
    const questions = props.questions;

    //console.log("objective", objective)
    //console.log("questions", questions)

    const [newQuestion, setNewQuestion] = useState(null);
    const [editingId, setEditingId] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);

    function handleSubmit (data) {
        //console.log();
        props.generateResults(data);
    }

    function editQuestion (id) {
        const questionToEdit = questions.find(item => item.id === id);
        if (editingId === id) {
            setEditingId(null);
            props.editQuestion(id, editingQuestion);
        } else {
            setEditingId(id);
            setEditingQuestion(questionToEdit.question);
        }
    }

    function deleteQuestion (id) {
        props.deleteQuestion(id);
    }

    function addQuestion () {
        props.addQuestion(newQuestion);
        setNewQuestion('');
    }
    
    return (
            <div className="flex flex-col w-full content-start bg-slate-50">
             <div className="flex flex-row flex-wrap m-4 w-full">
                <div className="text-left">
                    <h3>Objective: </h3>
                    <h5>{objective}</h5>
                </div>
            </div>
            <table className="table-auto w-full border border-slate-500">
                        <thead className="content-start">
                            
                            <tr className="border bg-slate-50 w-full">
                                <th className="px-4 py-4 text-left">
                                    <h3>Questions
                                    </h3>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        questions.map((item) => (
                                <tr key={item.id} className="border w-full">
                                    <td className="text-left p-4 w-11/12">
                                    {editingId === item.id ? (
                                    <input 
                                        name="editQuestion"
                                        type="text"
                                        defaultValue={item.question}
                                        onChange={e => setEditingQuestion(e.target.value)}
                                        className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                                    </input>
                                    ) : (
                                        item.question
                                    )}
                                    </td>
                                    <td className="flex text-left p-4">
                                        <img 
                                            src={editingId === item.id ? saveIcon : editIcon}
                                            alt="Edit Question"
                                            title="Edit Question"
                                            className="w-6 h-6 ml-2 mr-2 cursor-pointer"
                                            onClick={() => editQuestion(item.id)} />
                                        <img src={deleteIcon} alt="delete" title="Delete Concept" className="w-6 h-6 ml-2 mr-2 cursor-pointer" onClick={() => deleteQuestion(item.id)} />
                                    </td>
                                </tr>
                            ))}
                            
                            <tr>
                                <td className="text-left p-4 w-11/12">
                                <input 
                                name="newQuestion"
                                type="text"
                                placeholder="Enter Question"
                                value={newQuestion || ''}
                                onChange={e => setNewQuestion(e.target.value)}
                                className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                            </input>
                            </td>
                            <td className="p-4 w-full">
                                <img 
                                    src={addIcon} 
                                    alt="Add Question" 
                                    title="Add Question" 
                                    className="w-6 h-6 ml-2 mr-2 content-center cursor-pointer" 
                                    onClick={() => addQuestion()} />
                            </td>
                            </tr>                         
                        </tbody>
                    </table>

                    <div className="flex flex-row w-full justify-end content-center mt-4">
                        <button onClick={handleSubmit} className="mr-8 mb-8 px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                                        Next
                        </button>
                    </div>
                </div>
    )


}

export default ProjectInterviewQuestions;