import { useEffect, useState } from "react";
import { Auth } from 'aws-amplify';

import PersonaTable from "./PersonaTable";
import PersonaTableChat from "./PersonaTableChat";
import PersonaTableInterview from "./PersonaTableInterview";
import PersonaTableSurvey from "./PersonaTableSurvey";
import PersonaProfile from "../../utils/personas/PersonaProfile";

const PersonaOverview = (props) => {

    // persona
    const persona = props.persona;

    function handleNewChat() {
        props.handleNewChat();
    }

    function handleNewInterview() {
        props.handleNewInterview();
    }

    function handleNewSurvey() {
        props.handleNewSurvey();
    }

    function editPersona (persona) {
        props.editPersona(persona);
    }

    return (
        <div className="flex flex-col w-full h-fit bg-slate-50">
            <div className="flex flex-row flex-wrap w-full h-fit">
                {
                <PersonaProfile persona={persona} editPersona={editPersona} />

                /*<PersonaTableChat persona={persona} handleNewChat={handleNewChat} />
                <PersonaTableInterview persona={persona} handleNewInterview={handleNewInterview} />
                <PersonaTableSurvey persona={persona} handleNewSurvey={handleNewSurvey} />
                */}
            </div>
        </div>
    )
};

export default PersonaOverview;