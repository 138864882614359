import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetProfiles = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    let nextToken = null;
    let allProfiles = [];

    do {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.listProfiles, 
            variables: {
                filter: {
                    owner: {eq: userInfo.attributes.email}
                },
                limit: 100, // Specify the number of items to return in each request
                nextToken: nextToken,
            }
        });

        const profiles = apiData.data.listProfiles.items;
        allProfiles = [...allProfiles, ...profiles];
        nextToken = apiData.data.listProfiles.nextToken;
    } while (nextToken);

    return allProfiles;
}

export default GetProfiles;