import React, { useRef, useState, useEffect } from 'react';
import addIcon from "../../img/icons8-add-30.png";
import editIcon from "../../img/icons8-edit-30.png";
import deleteIcon from "../../img/icons8-delete-30.png";
import saveIcon from "../../img/icons8-save-30.png";
import { set } from 'react-hook-form';

const ProjectSurveyQuestions = (props) => {

    console.log("ProjectSurveyQuestions Props: ", props);

    const [objective, setObjective] = useState('');
    const [questions, setQuestions] = useState([]);
    const [addingState, setAddingState] = useState(false);
    const [newQuestion, setNewQuestion] = useState(null);
    const [newOptions, setNewOptions] = useState(Array(2).fill(''));

    const [editingId, setEditingId] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [editingOptions, setEditingOptions] = useState([null]);

    useEffect(() => {
        setObjective(props.objective);
        setQuestions(props.questions);
    }, [props.objective, props.questions]);

    const handleEditingQuestionChange = (newValue) => {
        setEditingQuestion(newValue);
    };

    const handleEditingOptionChange = (index, newValue) => {
        setEditingOptions(prevOptions => {
            const newOptions = [...prevOptions];
            newOptions[index] = { id: index, option: newValue }; // assuming newValue is the option
            return newOptions;
        });
    };

    const handleNewQuestionChange = (newValue) => {
        setNewQuestion(newValue);
        //console.log("New Question: " + newQuestion);
    };

    const handleNewQuestionOptionChange = (index, newValue) => {
        setNewOptions(prevOptions => {
            const newOptions = [...prevOptions];
            newOptions[index] = { id: index, option: newValue }; // assuming newValue is the option
            return newOptions;
        });
    
        /*setNewQuestion(prevQuestion => {
            const newQuestion = {...prevQuestion};
            newQuestion.options = newOptions;
            return newQuestion;
        });*/
    };

    function addQuestion () {
        if (addingState === true) {
            setAddingState(false);
            props.addQuestion(newQuestion, newOptions);
            setNewQuestion(null);
            setNewOptions(Array(5).fill(''));
        }
        else {
            setAddingState(true);
        }
    }

    function addOption () {
        setNewOptions(prevOptions => [...prevOptions, '']);
    }

    function editQuestion (id) {
        const questionToEdit = questions.find(question => question.id === id);
        if (editingId === id) {
            setEditingId(null);
            props.editQuestion(id, editingQuestion, editingOptions);
        } else {
            setEditingId(id);
            setEditingQuestion(questionToEdit.question);
            setEditingOptions(questionToEdit.options);
        }
    }

    function deleteQuestion (id) {
        props.deleteQuestion(id);
    }

    function handleSubmit (data) {
        console.log(data);
        props.generateResults(data);
    }

    return (
        <div className="flex flex-col w-full content-start bg-slate-50">
             <div className="flex flex-row flex-wrap m-4 w-full">
                <div className="text-left">
                    <h3>Objective: </h3>
                    <h5>{objective}</h5>
                </div>
            </div>
            <table className="table-auto w-full border border-slate-500">
                        <thead className="content-start">
                            <tr className="border bg-slate-50">
                                <th className="px-4 py-4 text-left">
                                    <h3>Questions
                                    </h3>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {questions.map((item) => (
                                <tr key={item.id} className="border w-full">
                                {editingId === item.id ? (
                                <td className="text-left p-4 w-11/12">
                                    <input 
                                        name="editQuestion"
                                        type="text"
                                        defaultValue={item.question}
                                        onChange={e => handleEditingQuestionChange(e.target.value)}
                                        className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                                    </input>
                                    <ol className="list-alpha px-8 py-4">
                                        {item.options.map((option, index) => (
                                            <li key={option.id} className="p-2">
                                                <input
                                                    name="editOption"
                                                    type="text"
                                                    defaultValue={option.option}
                                                    onChange={e => handleEditingOptionChange(index,e.target.value)}
                                                    className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                                                </input>
                                            </li>
                                        ))}
                                    </ol>
                                </td>
                                ) : (
                                    <td className="border text-left p-4 w-11/12">{item.question}
                                        <ol className="list-alpha px-8">
                                            {item.options.map((option) => (
                                                <li key={option.id}>{option.option}</li>
                                            ))}
                                        </ol>
                                    </td>
                                )}
                                <td className="flex text-left p-4">
                                    <img 
                                        src={editingId === item.id ? saveIcon : editIcon}
                                        alt="edit"
                                        title="Edit Question"
                                        className="w-6 h-6 ml-2 mr-2 cursor-pointer"
                                        onClick={() => editQuestion(item.id)} />
                                    <img 
                                        src={deleteIcon} 
                                        alt="delete" 
                                        title="Delete Question" 
                                        className="w-6 h-6 ml-2 mr-2 cursor-pointer" 
                                        onClick={() => deleteQuestion(item.id)} />
                                </td>
                            </tr>
                            ))}
                            {addingState === true ? (
                            <tr>
                                <td className="text-left p-4">
                                    <input 
                                        name="newQuestion"
                                        type="text"
                                        placeholder="Enter Question"
                                        onChange={e => handleNewQuestionChange(e.target.value)}
                                        className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                                    </input>
                                    <ol className="list-alpha">
                                        {newOptions.map((option, index) => (
                                            <li key={index} className="mt-2">
                                                <input
                                                    name={"newOption" + index}
                                                    type="text"
                                                    placeholder={"Enter Option"}
                                                    onChange={e => handleNewQuestionOptionChange(index,e.target.value)}
                                                    className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                                                </input>
                                            </li>
                                        ))}
                                    </ol>
                                    <p className="">
                                        <img 
                                            src={addIcon} 
                                            alt="Add Option" 
                                            title="Add Option" 
                                            className="w-6 h-6 m-2 content-start cursor-pointer inline" 
                                            onClick={() => addOption()} />
                                        Add Option 
                                    </p>
                                </td>
                                <td className="flex mt-2 content-start">
                                    <img 
                                        src={saveIcon}
                                        alt="Save Question" 
                                        title="Save Question" 
                                        className="w-6 h-6 ml-2 mr-2 content-start cursor-pointer" 
                                        onClick={() => addQuestion()} />
                                        Save Question
                                </td>
                                </tr>
                                ) : (
                                <tr>
                                    <td className="flex p-4 content-start">
                                        <img
                                            src={addIcon}
                                            alt="Add Question" 
                                            title="Add Question" 
                                            className="w-6 h-6 ml-2 mr-2 content-start cursor-pointer" 
                                            onClick={() => addQuestion()} />
                                            Add Question
                                    </td>
                                </tr>
                                )}
                        </tbody>
                    </table>
                    <div className="flex flex-row w-full justify-end content-center mt-4">
                    <button onClick={handleSubmit} className="mr-8 mb-8 px-4 py-2  bg-slate-50 justify-center text-md text-blue-600 font-semibold rounded-md border-1 border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                        Next
                    </button>
                </div>
                </div>
    )
}

export default ProjectSurveyQuestions;