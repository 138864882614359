import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetPersonasBySegmentID = async (id) => {

    let nextToken = null;
    let allPersonas = [];

    do {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.customerPersonasBySegmentID, 
            variables: {
                segmentID: id,
                limit: 100, // Specify the number of items to return in each request
                nextToken: nextToken,
            }
        });

        const personas = apiData.data.customerPersonasBySegmentID.items;
        allPersonas = [...allPersonas, ...personas];
        nextToken = apiData.data.customerPersonasBySegmentID.nextToken;

    } while (nextToken);

    return allPersonas;
}

export default GetPersonasBySegmentID;