import { API } from "aws-amplify";
//import * as queries from "../../graphql/queries";

async function getJobStatus(jobId) {
    const path = '/jobs/' + jobId;
    //let attempts = 0;
    let response;
    let delay = 1000;

    while (true) {
        response = await API.get('llmjobdevapi', path);
        console.log(response);

        if (response.status === 'completed') {
            break;
        } else {
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }

    console.log('Job completed!', response);

    return response;
}

export const CreateAssistantLLM = async (data) => {
    const model = data.model;
    const temperature = data.temperature;
    const mode = data.mode;
    const dataToLLM = data.llm_data;
    let jobID;
    let attempts = 0;
    while (attempts < 3) {
        try {
            const response = await API.post('llmjobdevapi', '/jobs', {
                body : {
                    input : {
                        model: model,
                        temperature: temperature,
                        mode: mode,
                        data: dataToLLM
                    }
                }
            });
            jobID = response.jobID;
            console.log(response);
            break;
        } catch (error) {
            console.log(error);
            attempts++;
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    if (!jobID) {
        console.log('Failed to create LLM Assistant');
        return { status: 'failed' };
    }

        
    
    // get the assistant ID
    const assistant_response = await getJobStatus(jobID);
    console.log(assistant_response);
    const assistantID_obj = JSON.parse(assistant_response.result);
    let result = { assistantID: assistantID_obj.assistantID };
    if (assistantID_obj.fileID) {
        result.fileID = assistantID_obj.fileID;
    }
    return result;
}




