import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const SaveProfiles = async (data, createOrChange) => {
    
    if (createOrChange === "create") {

        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: mutations.createProfile, // change this after we know DB schema
            variables: {
                input: data,
            }
        });
    
        return apiData.data.createProfile.id;

    } else if (createOrChange === "change") {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: mutations.updateProfile, // change this after we know DB schema
            variables: {
                input: data,
            }
        });
    
        return apiData.data.updateProfile.id;
    }
}

export default SaveProfiles;