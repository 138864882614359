import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const SaveQuestions = async (data) => {
    console.log("SaveQuestions data")
    console.log(data)
    
    const apiData = await API.graphql({ 
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: mutations.createQuestions, // change this after we know DB schema
        variables: {
            input: data,
        }
    });

    return apiData.data.createQuestions.id;   
}

export default SaveQuestions;