import { useEffect, useState } from 'react';
//import { ProgressBar, MarkerBar, DeltaBar, RangeBar, CategoryBar, Card, Flex, Text } from "@tremor/react";
import GetConceptAnswersByProjectBriefID from '../../utils/projects/GetConceptAnswersByProjectBriefID';
import Loading from '../../utils/Loading';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';

const ProjectInterviewResultsForInsights = (props) => {

    console.log("in ProjectResultsForInsights: ", props)

    const [loading, setLoading] = useState(true);
    const objective = props.objective;
    const results = props.results;
    const respondents = props.respondents;
    const questions = props.questions;

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading])    

    console.log("results", results)
    
    // parse 2D results array to get questions and answers
    let rows=[];
    for(let i = 0; i < results.length; i++) {
        let row={};
        row.question = results[i][0].question;
        row.id = i;
        for(let j = 0; j < results[i].length; j++) {
            const respondent = results[i][j].name;
            row[`answer${respondent}`] = results[i][j].answer;
        }
        // push row to rows
        rows.push(row);
    }
    console.log("rows", rows);

    // Create columns for DataGrid
    const columns = results && results.length > 0 ? [
    { field: 'question', headerName: 'Question', width: 300, align: 'left', renderCell: (params) => (
        <div style={{ textAlign: 'left' }}>
            {params.value}
        </div>
    ), },
    ...results[0].map((respondent, i) => ({
        field: `answer${respondent.name}`,
        headerName: respondent.name,
        minWidth: 600,
        align: 'left',
        renderCell: (params) => (
            <div style={{ textAlign: 'left' }}>
                {params.value}
            </div>
        ),
    })),
    ] : [];

    console.log("columns", columns);

    return (
        <div className="flex flex-col w-full m-4 bg-slate-50 overflow-y-auto">
            {/*results.map((questionsArray, i) => {
                console.log("1st Array", questionsArray);
                // Transform data into rows for DataGrid
                const rows = questionsArray.map((result, j) => {
                    console.log("Result: ", result);
                        const row = { id: j, name: result.name, question: result.question, [`answer${j}`]: result.answer };
                        console.log("row", row);
                        return row;
                });
            
                // Create columns for DataGrid
                const columns = [
                    {   field: 'question', 
                        headerName: 'Question', 
                        width: 300, 
                        align: 'left',
                    },
                    ...questionsArray.map((respondent, j) => ({
                        field: `answer${j}`,
                        headerName: respondent.name,
                        width: 600,
                        align: 'left',
                    })),
                ];
            
                return (
                    (
                    
                );
            })}*/}
            <div className="flex flex-row w-full bg-slate-50 mt-4 mb-4 overflow-x-auto">
                        <div className="flex flex-row bg-slate-50 w-full">
                            <DataGridPro
                                loading={loading}
                                getRowId={(row) => row.id}
                                autoHeight
                                getRowHeight={() => 'auto'}
                                slots = {{ toolbar: GridToolbar }}
                                sx={{   
                                    fontWeight: 'fontWeightLight',
                                    bgcolor: 'white',
                                    boxShadow: 2, 
                                    borderRadius: 4,
                                    border: 1,
                                    px: 2,
                                }}
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 20, 50, 100]}
                                //checkboxSelection
                            />
                        </div>
                        </div>
        </div>
    );
}

export default ProjectInterviewResultsForInsights;
