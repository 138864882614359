// react router
import { Routes, Route, useNavigate } from 'react-router-dom';
// state
import { useEffect, useState } from 'react';
// AWS
import { API, Auth } from 'aws-amplify';
// GraphQL
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
// persona dashnboards
import Profiles from './Profiles';
import Panel from './Panel';
import NewPanelWorkflow from './NewPanelWorkflow';
import PanelBrief from './PanelBrief';
import GetUser from '../../utils/users/GetUser';
import GetPanels from '../../utils/panels/GetPanels';
import GetPanelistsbyPanelId from '../../utils/panels/GetPanelistsbyPanelId';
import GetSources from '../../utils/panels/GetSources';
import GetProfiles from '../../utils/panels/GetProfiles';
import GetProfilesBySourceId from '../../utils/panels/GetProfilesBySourceId';
import PanelSidebar from '../../utils/navigation/PanelSidebar';
import DeleteProfiles from '../../utils/panels/DeleteProfiles';
import Panels from './Panels';
import { get, set } from 'react-hook-form';

const model = "gpt-3.5-turbo";
//const model = "gpt-4";

const PanelDashboard = (props) => {

    console.log('PanelDashboard rendered');
    console.log(props.MRAssistantID)
    
    const [user, setUser] = useState(null);
    // loading states
    const [isLoading, setIsLoading] = useState(true);
    const [loadingPanels, setLoadingPanels] = useState(true);
    const [loadingProfiles, setLoadingProfiles] = useState(true);

    const [sources, setSources] = useState([]);
    const [selectedSource, setSelectedSource] = useState([]);
    const [panels, setPanels] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [panelists, setPanelists] = useState([]);
    const [isItemSelected, setIsItemSelected] = useState(false);
    const [isTable, setIsTable] = useState(false);

    const navigate = useNavigate();

    async function getPanels() {
        const panels = await GetPanels();
        setPanels(panels || []);
        setLoadingPanels(false);
    }

    async function getSources() {
        const sources = await GetSources();
        if (sources) {
            setSources(sources);
            setSelectedSource(sources[0]);
        }
    }

    async function getProfilesBySourceID(sourceID) { 
        setLoadingProfiles(true);
        const profiles = await GetProfilesBySourceId(sourceID);
        setProfiles(profiles || []);
        setLoadingProfiles(false);
    }

    async function getPanelistsByPanelID(panelID) {
        const panelists = await GetPanelistsbyPanelId(panelID);
        if (panelists) {
            setPanelists(panelists);
        }
    }

    // grab the profiles and sources from the database
    useEffect(() => {

        getSources();
        getPanels(); 
    
    }, [])

    function updateMenu(sourceID) {
        const currentSource = sources.find(source => source.id === sourceID);
        console.log("Selected Source: ", currentSource)
        setSelectedSource(getProfilesBySourceID(sourceID));
        navigate('/panels/profiles');
    }

    function updateSubmenu(panelID) {
        getPanelistsByPanelID(panelID);
        navigate(`/panels/${panelID}`);
    }

    return (
        <div className="flex flex-row w-full min-h-screen h-full bg-slate-50">       
                    <PanelSidebar menuItems={sources} submenuItems={panels} updateMenu={updateMenu} updateSubmenu={updateSubmenu} />          
                    <Routes>
                        <Route path="/" element={<Panels panels={panels} loading={loadingPanels} />} />
                        <Route path="/profiles" element={<Profiles profiles={profiles} loading={loadingProfiles} />} />
                        <Route path="/new" element={<NewPanelWorkflow profiles={profiles} isLoading={isLoading} sources={sources}/>} />
                        <Route path="/:panelID/*" element={<Panel isTable={isTable} />} />             
                    </Routes>
            </div>    
    );
}

export default PanelDashboard;