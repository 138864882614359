import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetPersonas = async () => {
    const formatPersonas = async (personas) => {
        const userInfo = await Auth.currentAuthenticatedUser();

        return personas.map((persona) => {
            return {
                "owner": userInfo.attributes.email,
                "segmentID": persona.segmentID,
                "id": persona.id,
                "name": persona.name,
                "personaID": persona.personaID,
                "age": persona.age,
                "gender": persona.gender,
                "race": persona.race,
                "education": persona.education,
                "occupation": persona.occupation,
                "maritalStatus": persona.marital_status,
                "children": persona.children,
                "income": persona.income,
                "netWorth": persona.netWorth,
                "country": persona.country,
                "values": persona.values,
                "lifestyle": persona.lifestyle,
                "interests": persona.interests,
                "attitudes": persona.attitudes,
                "personality": persona.personality,
                "social": persona.social,
            }
        })
    }

    const userInfo = await Auth.currentAuthenticatedUser();
    const apiData = await API.graphql({
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: queries.listCustomerPersonas,
        variables: {
            filter: {
                owner: { eq: userInfo.attributes.email }
            }
        }
    });

    const personasFromAPI = apiData.data.listCustomerPersonas.items;
    const personasFormatted = await formatPersonas(personasFromAPI);

    return personasFormatted;
}

export default GetPersonas;