import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetPanelistsbyPanelId = async (panelID) => {

    let nextToken = null;
    let allPanelists = [];

    do {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.panelistsByPanelID, 
            variables: {
                panelID: panelID,
                limit: 100, // Specify the number of items to return in each request
                nextToken: nextToken,
            }
        });

        const panelists = apiData.data.panelistsByPanelID.items;
        allPanelists = [...allPanelists, ...panelists];
        nextToken = apiData.data.panelistsByPanelID.nextToken;

    } while (nextToken);

    console.log('Panelists:', allPanelists);

    return allPanelists;
}

export default GetPanelistsbyPanelId;