import React, { useEffect, useState } from 'react';

export const personaTypeOptions = [
    {value: 'Consumer', label: 'Consumer', enumVal: 'CONSUMER'},
    {value: 'Business', label: 'Business', enumVal: 'BUSINESS'},
]

export const personaCreateOptions = [
    {value: 'Manual', label: 'Enter manually', enumVal: 'MANUAL'},
    {value: 'Criteria', label: 'Define criteria', enumVal: 'CRITERIA'},
    {value: 'Panel', label: 'Use Panel', enumVal: 'PANEL'},
]

export const personaSampleOptions = [
    {value: 'Public', label: 'Public Data', enumVal: 'PUBLIC'},
    {value: 'Proprietary', label: 'Proprietary Data', enumVal: 'PROPRIETARY'},
]

export const ageOptions = [
    {value: '18-24', label: '18-24'},
    {value: '25-34', label: '25-34'},
    {value: '35-44', label: '35-44'},
    {value: '45-54', label: '45-54'},
    {value: '55-64', label: '55-64'},
    {value: '65+', label: '65+'},
]

export const raceOptions = [
    {value: 'White', label: 'White'},
    {value: 'Black', label: 'Black'},
    {value: 'Hispanic', label: 'Hispanic'},
    {value: 'Asian', label: 'Asian'},
    {value: 'Native American', label: 'Native American'},
    {value: 'Other', label: 'Other'},
]

export const childrenOptions = [
    {value: '0', label: '0'},
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3+', label: '3+'},
]

export const countryOptions = [
    {value: 'United States', label: 'United States'},
//    {value: 'United Kingdom', label: 'United Kingdom'},
//    {value: 'France', label: 'France'},
//    {value: 'Germany', label: 'Germany'},
//    {value: 'Italy', label: 'Italy'},
//    {value: 'Spain', label: 'Spain'},
//    {value: 'China', label: 'China'},
//    {value: 'Japan', label: 'Japan'},
//    {value: 'India', label: 'India'},
//    {value: 'Australia', label: 'Australia'},
//    {value: 'Brazil', label: 'Brazil'},
//    {value: 'Argentina', label: 'Argentina'},
//    {value: 'South Africa', label: 'South Africa'},
]

// US States
export const stateOptions = [
    {value: 'AL', label: 'Alabama'},
    {value: 'AK', label: 'Alaska'},
    {value: 'AZ', label: 'Arizona'},
    {value: 'AR', label: 'Arkansas'},
    {value: 'CA', label: 'California'},
    {value: 'CO', label: 'Colorado'},
    {value: 'CT', label: 'Connecticut'},
    {value: 'DE', label: 'Delaware'},
    {value: 'FL', label: 'Florida'},
    {value: 'GA', label: 'Georgia'},
    {value: 'HI', label: 'Hawaii'},
    {value: 'ID', label: 'Idaho'},
    {value: 'IL', label: 'Illinois'},
    {value: 'IN', label: 'Indiana'},
    {value: 'IA', label: 'Iowa'},
    {value: 'KS', label: 'Kansas'},
    {value: 'KY', label: 'Kentucky'},
    {value: 'LA', label: 'Louisiana'},
    {value: 'ME', label: 'Maine'},
    {value: 'MD', label: 'Maryland'},
    {value: 'MA', label: 'Massachusetts'},
    {value: 'MI', label: 'Michigan'},
    {value: 'MN', label: 'Minnesota'},
    {value: 'MS', label: 'Mississippi'},
    {value: 'MO', label: 'Missouri'},
    {value: 'MT', label: 'Montana'},
    {value: 'NE', label: 'Nebraska'},
    {value: 'NV', label: 'Nevada'},
    {value: 'NH', label: 'New Hampshire'},
    {value: 'NJ', label: 'New Jersey'},
    {value: 'NM', label: 'New Mexico'},
    {value: 'NY', label: 'New York'},
    {value: 'NC', label: 'North Carolina'},
    {value: 'ND', label: 'North Dakota'},
    {value: 'OH', label: 'Ohio'},
    {value: 'OK', label: 'Oklahoma'},
    {value: 'OR', label: 'Oregon'},
    {value: 'PA', label: 'Pennsylvania'},
    {value: 'RI', label: 'Rhode Island'},
    {value: 'SC', label: 'South Carolina'},
    {value: 'SD', label: 'South Dakota'},
    {value: 'TN', label: 'Tennessee'},
    {value: 'TX', label: 'Texas'},
    {value: 'UT', label: 'Utah'},
    {value: 'VT', label: 'Vermont'},
    {value: 'VA', label: 'Virginia'},
    {value: 'WA', label: 'Washington'},
    {value: 'WV', label: 'West Virginia'},
    {value: 'WI', label: 'Wisconsin'},
    {value: 'WY', label: 'Wyoming'},
]

export const cityOptions = [
    {value: 'Boston', label: 'Boston'},
    {value: 'Chicago', label: 'Chicago'},
    {value: 'Dallas', label: 'Dallas'},
    {value: 'Denver', label: 'Denver'},
    {value: 'Honolulu', label: 'Honolulu'},
    {value: 'Houston', label: 'Houston'},
    {value: 'Los Angeles', label: 'Los Angeles'},
    {value: 'Miami', label: 'Miami'},
    {value: 'New York', label: 'New York'},
    {value: 'Philadelphia', label: 'Philadelphia'},
    {value: 'Phoenix', label: 'Phoenix'},
    {value: 'Portland', label: 'Portland'},
    {value: 'San Diego', label: 'San Diego'},
    {value: 'San Francisco', label: 'San Francisco'},
    {value: 'Seattle', label: 'Seattle'},
    {value: 'Washington DC', label: 'Washington DC'},
]

export const industryOptions = [
    {value: 'Agriculture', label: 'Agriculture'},
    {value: 'Mining', label: 'Mining'},
    {value: 'Construction', label: 'Construction'},
    {value: 'Manufacturing', label: 'Manufacturing'},
    {value: 'Transportation', label: 'Transportation'},
    {value: 'Wholesale', label: 'Wholesale'},
    {value: 'Retail', label: 'Retail'},
    {value: 'Finance', label: 'Finance'},
    {value: 'Real Estate', label: 'Real Estate'},
    {value: 'Professional Services', label: 'Professional Services'},
    {value: 'Education', label: 'Education'},
    {value: 'Health Care', label: 'Health Care'},
    {value: 'Entertainment', label: 'Entertainment'},
    {value: 'Hospitality', label: 'Hospitality'},
    {value: 'Government', label: 'Government'},
    {value: 'Other', label: 'Other'},
]  

/*
export const OccupationOptions = () => {
    const [occupationOptions, setOccupationOptions] = useState([]);
  
    useEffect(() => {
      const fetchOccupations = async () => {
        const response = await fetch('https://services.onetcenter.org/ws/online/occupations/', {
          headers: {
            'Authorization': `Basic aW5zeXRfYWk6ODc2N3d6Yw==`
          }
        });
        const data = await response.json();
        const options = data.occupation.map((occupation) => ({
          value: occupation.code,
          label: occupation.title,
        }));
        setOccupationOptions(options);
      };
  
      fetchOccupations();
    }, []);
  
    // Use occupationOptions in your component
    return occupationOptions;
  };
*/

export const companySizeOptions = [
    {value: '1-10', label: '1-10'},
    {value: '11-50', label: '11-50'},
    {value: '51-200', label: '51-200'},
    {value: '201-500', label: '201-500'},
    {value: '501-1000', label: '501-1000'},
    {value: '1001-10000', label: '1001-10000'},
    {value: '10001+', label: '10001+'},
]

export const roleOptions = [
    {value: 'IT', label: 'IT'},
    {value: 'Marketing', label: 'Marketing'},
    {value: 'Sales', label: 'Sales'},
    {value: 'Customer Service', label: 'Customer Service'},
    {value: 'Operations', label: 'Operations'},
    {value: 'Finance', label: 'Finance'},
    {value: 'Human Resources', label: 'Human Resources'},
    {value: 'Other', label: 'Other'},
]

export const seniorityOptions = [
    {value: 'C-Level', label: 'C-Level'},
    {value: 'VP', label: 'VP'},
    {value: 'Director', label: 'Director'},
    {value: 'Manager', label: 'Manager'},
    {value: 'Supervisor', label: 'Supervisor'},
    {value: 'Staff', label: 'Staff'},
]    

export const genderOptions = [ 
    {value: 'Male', label: 'Male'},
    {value: 'Female', label: 'Female'}        
]

export const educationOptions = [ 
    {value: 'High School', label: 'High School'},
    {value: 'Associates Degree', label: 'Associates Degree'},
    {value: 'Bachelors Degree', label: 'Bachelors Degree'},
    {value: 'Masters Degree', label: 'Masters Degree'},
    {value: 'Doctorate', label: 'Doctorate'},
    {value: 'Professional Degree', label: 'Professional Degree'}        
]

export const maritalOptions = [ 
    {value: 'Single', label: 'Single'},
    {value: 'Partnered', label: 'Partnered'},
    {value: 'Married', label: 'Married'},
    {value: 'Separated', label: 'Separated'},
    {value: 'Divorced', label: 'Divorced'},
    {value: 'Widowed', label: 'Widowed'}       
]

export const incomeOptions = [
    {value: '$0-$25k', label: '$0-$25k'},
    {value: '$25k-$50k', label: '$25k-$50k'},
    {value: '$50k-$75k', label: '$50k-$75k'},
    {value: '$75k-$100k', label: '$75k-$100k'},
    {value: '$100k-$150k', label: '$100k-$150k'},
    {value: '$150k-$200k', label: '$150k-$200k'},
    {value: '$200k-$300k', label: '$200k-$300k'},
    {value: '$300k-$400k', label: '$300k-$400k'},
    {value: '$400k-$500k', label: '$400k-$500k'},
    {value: '$500k-$750k', label: '$500k-$750k'},
    {value: '$750k-$1m', label: '$750k-$1m'},
    {value: '$1m+', label: '$1m or more'},
]

export const netWorthOptions = [
    {value: '$0-$100k', label: '$0-$100k'},
    {value: '$100k-$250k', label: '$100k-$250k'},
    {value: '$250k-$500k', label: '$250k-$500k'},
    {value: '$500k-$1m', label: '$500k-$1m'},
    {value: '$1m-$5m', label: '$1m-$5m'},
    {value: '$5m-$10m', label: '$5m-$10m'},
    {value: '$10m-$25m', label: '$10m-$25m'},
    {value: '$25m-$50m', label: '$25m-$50m'},
    {value: '$50m-$100m', label: '$50m-$100m'},
    {value: '$100m+', label: '$100m or more'},
]

export const occupationOptions = [
    {value: 'Accountants and Auditors', label: 'Accountants and Auditors'},
    {value: 'Actors', label: 'Actors'},
    {value: 'Actuaries', label: 'Actuaries'},
    {value: 'Acupuncturists', label: 'Acupuncturists'},
    {value: 'Acute Care Nurses', label: 'Acute Care Nurses'},
    {value: 'Adapted Physical Education Specialists', label: 'Adapted Physical Education Specialists'},
    {value: 'Adhesive Bonding Machine Operators and Tenders', label: 'Adhesive Bonding Machine Operators and Tenders'},
    {value: 'Administrative Law Judges, Adjudicators, and Hearing Officers', label: 'Administrative Law Judges, Adjudicators, and Hearing Officers'},
    {value: 'Administrative Services Managers', label: 'Administrative Services Managers'},
    {value: 'Adult Basic and Secondary Education and Literacy Teachers and Instructors', label: 'Adult Basic and Secondary Education and Literacy Teachers and Instructors'},
    {value: 'Advertising and Promotions Managers', label: 'Advertising and Promotions Managers'},
    {value: 'Advertising Sales Agents', label: 'Advertising Sales Agents'},
    {value: 'Aerospace Engineering and Operations Technicians', label: 'Aerospace Engineering and Operations Technicians'},
    {value: 'Aerospace Engineers', label: 'Aerospace Engineers'},
    {value: 'Agents and Business Managers of Artists, Performers, and Athletes', label: 'Agents and Business Managers of Artists, Performers, and Athletes'},
    {value: 'Agricultural and Food Science Technicians', label: 'Agricultural and Food Science Technicians'},
    {value: 'Agricultural and Food Scientists', label: 'Agricultural and Food Scientists'},
    {value: 'Agricultural Engineers', label: 'Agricultural Engineers'},
    {value: 'Agricultural Equipment Operators', label: 'Agricultural Equipment Operators'},
    {value: 'Agricultural Inspectors', label: 'Agricultural Inspectors'},
    {value: 'Agricultural Sciences Teachers, Postsecondary', label: 'Agricultural Sciences Teachers, Postsecondary'},
    {value: 'Agricultural Technicians', label: 'Agricultural Technicians'},
    {value: 'Agricultural Workers, All Other', label: 'Agricultural Workers, All Other'},
    {value: 'Air Traffic Controllers', label: 'Air Traffic Controllers'},
    {value: 'Aircraft Cargo Handling Supervisors', label: 'Aircraft Cargo Handling Supervisors'},
    {value: 'Aircraft Mechanics and Service Technicians', label: 'Aircraft Mechanics and Service Technicians'},
    {value: 'Airfield Operations Specialists', label: 'Airfield Operations Specialists'},
    {value: 'Airline Pilots, Copilots, and Flight Engineers', label: 'Airline Pilots, Copilots, and Flight Engineers'},
    {value: 'Ambulance Drivers and Attendants, Except Emergency Medical Technicians', label: 'Ambulance Drivers and Attendants, Except Emergency Medical Technicians'},
    {value: 'Amusement and Recreation Attendants', label: 'Amusement and Recreation Attendants'},
    {value: 'Anesthesiologists', label: 'Anesthesiologists'},
    {value: 'Animal Breeders', label: 'Animal Breeders'},
    {value: 'Animal Control Workers', label: 'Animal Control Workers'},
    {value: 'Animal Scientists', label: 'Animal Scientists'},  
    {value: 'Animal Trainers', label: 'Animal Trainers'},
    {value: 'Anthropologists and Archeologists', label: 'Anthropologists and Archeologists'},
    {value: 'Anthropology and Archeology Teachers, Postsecondary', label: 'Anthropology and Archeology Teachers, Postsecondary'},
    {value: 'Appraisers and Assessors of Real Estate', label: 'Appraisers and Assessors of Real Estate'},
    {value: 'Arbitrators, Mediators, and Conciliators', label: 'Arbitrators, Mediators, and Conciliators'},
    {value: 'Architects, Except Landscape and Naval', label: 'Architects, Except Landscape and Naval'},
    {value: 'Architectural and Civil Drafters', label: 'Architectural and Civil Drafters'},
    {value: 'Architectural and Engineering Managers', label: 'Architectural and Engineering Managers'}, 
    {value: 'Architectural and Engineering Technicians', label: 'Architectural and Engineering Technicians'},
    {value: 'Architectural Drafters', label: 'Architectural Drafters'},
    {value: 'Archivists', label: 'Archivists'},
    {value: 'Area, Ethnic, and Cultural Studies Teachers, Postsecondary', label: 'Area, Ethnic, and Cultural Studies Teachers, Postsecondary'},
    {value: 'Art Directors', label: 'Art Directors'},
    {value: 'Art, Drama, and Music Teachers, Postsecondary', label: 'Art, Drama, and Music Teachers, Postsecondary'},
    {value: 'Artists and Related Workers, All Other', label: 'Artists and Related Workers, All Other'},
    {value: 'Assemblers and Fabricators, All Other', label: 'Assemblers and Fabricators, All Other'},
    {value: 'Astronomers', label: 'Astronomers'},
    {value: 'Athletes and Sports Competitors', label: 'Athletes and Sports Competitors'},
    {value: 'Athletic Trainers', label: 'Athletic Trainers'},
    {value: 'Atmospheric and Space Scientists', label: 'Atmospheric and Space Scientists'},
    {value: 'Atmospheric, Earth, Marine, and Space Sciences Teachers, Postsecondary', label: 'Atmospheric, Earth, Marine, and Space Sciences Teachers, Postsecondary'},
    {value: 'Audio and Video Equipment Technicians', label: 'Audio and Video Equipment Technicians'},
    {value: 'Audiologists', label: 'Audiologists'},
    {value: 'Audio-Visual and Multimedia Collections Specialists', label: 'Audio-Visual and Multimedia Collections Specialists'},
    {value: 'Auditors', label: 'Auditors'},
    {value: 'Automotive and Watercraft Service Attendants', label: 'Automotive and Watercraft Service Attendants'},
    {value: 'Automotive Body and Related Repairers', label: 'Automotive Body and Related Repairers'},
    {value: 'Automotive Glass Installers and Repairers', label: 'Automotive Glass Installers and Repairers'},
    {value: 'Automotive Master Mechanics', label: 'Automotive Master Mechanics'},
    {value: 'Automotive Service Technicians and Mechanics', label: 'Automotive Service Technicians and Mechanics'},
    {value: 'Aviation Inspectors', label: 'Aviation Inspectors'},
    {value: 'Avionics Technicians', label: 'Avionics Technicians'},
    {value: 'Baggage Porters and Bellhops', label: 'Baggage Porters and Bellhops'},
    {value: 'Bailiffs', label: 'Bailiffs'},
    {value: 'Bakers', label: 'Bakers'},
    {value: 'Barbers', label: 'Barbers'},
    {value: 'Bartenders', label: 'Bartenders'},
    {value: 'Bicycle Repairers', label: 'Bicycle Repairers'},
    {value: 'Bill and Account Collectors', label: 'Bill and Account Collectors'},
    {value: 'Billing and Posting Clerks', label: 'Billing and Posting Clerks'},
    {value: 'Biochemists and Biophysicists', label: 'Biochemists and Biophysicists'},
    {value: 'Biofuels Processing Technicians', label: 'Biofuels Processing Technicians'},
    {value: 'Bioinformatics Scientists', label: 'Bioinformatics Scientists'},
    {value: 'Biological Science Teachers, Postsecondary', label: 'Biological Science Teachers, Postsecondary'},
    {value: 'Biological Scientists, All Other', label: 'Biological Scientists, All Other'},
    {value: 'Biological Technicians', label: 'Biological Technicians'},
    {value: 'Biomedical Engineers', label: 'Biomedical Engineers'},
    {value: 'Biomedical Equipment Technicians', label: 'Biomedical Equipment Technicians'},
    {value: 'Boilermakers', label: 'Boilermakers'},
    {value: 'Bookkeeping, Accounting, and Auditing Clerks', label: 'Bookkeeping, Accounting, and Auditing Clerks'},
    {value: 'Brickmasons and Blockmasons', label: 'Brickmasons and Blockmasons'},
    {value: 'Bridge and Lock Tenders', label: 'Bridge and Lock Tenders'},
    {value: 'Broadcast News Analysts', label: 'Broadcast News Analysts'},
    {value: 'Broadcast Technicians', label: 'Broadcast Technicians'},
    {value: 'Brokerage Clerks', label: 'Brokerage Clerks'},
    {value: 'Budget Analysts', label: 'Budget Analysts'},
    {value: 'Building Cleaning Workers, All Other', label: 'Building Cleaning Workers, All Other'},
    {value: 'Building Cleaning and Pest Control Workers', label: 'Building Cleaning and Pest Control Workers'},
    {value: 'Building Inspectors', label: 'Building Inspectors'},
    {value: 'Bus and Truck Mechanics and Diesel Engine Specialists', label: 'Bus and Truck Mechanics and Diesel Engine Specialists'},
    {value: 'Bus Drivers, School or Special Client', label: 'Bus Drivers, School or Special Client'},
    {value: 'Bus Drivers, Transit and Intercity', label: 'Bus Drivers, Transit and Intercity'},
    {value: 'Business Operations Specialists, All Other', label: 'Business Operations Specialists, All Other'},
    {value: 'Business Teachers, Postsecondary', label: 'Business Teachers, Postsecondary'},
    {value: 'Butchers and Meat Cutters', label: 'Butchers and Meat Cutters'},
    {value: 'Buyers and Purchasing Agents, Farm Products', label: 'Buyers and Purchasing Agents, Farm Products'},
    {value: 'Buyers and Purchasing Agents, Manufacturing', label: 'Buyers and Purchasing Agents, Manufacturing'},
    {value: 'Buyers and Purchasing Agents, Wholesale and Retail, Except Farm Products', label: 'Buyers and Purchasing Agents, Wholesale and Retail, Except Farm Products'},
    {value: 'Cabinetmakers and Bench Carpenters', label: 'Cabinetmakers and Bench Carpenters'},
    {value: 'Camera and Photographic Equipment Repairers', label: 'Camera and Photographic Equipment Repairers'},
    {value: 'Camera Operators, Television, Video, and Motion Picture', label: 'Camera Operators, Television, Video, and Motion Picture'},
    {value: 'Cardiovascular Technologists and Technicians', label: 'Cardiovascular Technologists and Technicians'},
    {value: 'Career/Technical Education Teachers, Middle School', label: 'Career/Technical Education Teachers, Middle School'},
    {value: 'Career/Technical Education Teachers, Secondary School', label: 'Career/Technical Education Teachers, Secondary School'},
    {value: 'Cargo and Freight Agents', label: 'Cargo and Freight Agents'},
    {value: 'Carpenters', label: 'Carpenters'},
    {value: 'Carpet Installers', label: 'Carpet Installers'},
    {value: 'Cartographers and Photogrammetrists', label: 'Cartographers and Photogrammetrists'},
    {value: 'Cashiers', label: 'Cashiers'},
    {value: 'Cement Masons and Concrete Finishers', label: 'Cement Masons and Concrete Finishers'},
    {value: 'Chefs and Head Cooks', label: 'Chefs and Head Cooks'},
    {value: 'Chemical Engineers', label: 'Chemical Engineers'},
    {value: 'Chemical Equipment Operators and Tenders', label: 'Chemical Equipment Operators and Tenders'},
    {value: 'Chemical Plant and System Operators', label: 'Chemical Plant and System Operators'},
    {value: 'Chemical Technicians', label: 'Chemical Technicians'},
    {value: 'Chemistry Teachers, Postsecondary', label: 'Chemistry Teachers, Postsecondary'},
    {value: 'Chemists', label: 'Chemists'},
    {value: 'Chief Executives', label: 'Chief Executives'},
    {value: 'Child, Family, and School Social Workers', label: 'Child, Family, and School Social Workers'},
    {value: 'Childcare Workers', label: 'Childcare Workers'},
    {value: 'Chiropractors', label: 'Chiropractors'},
    {value: 'Choreographers', label: 'Choreographers'},
    {value: 'Civil Engineering Technicians', label: 'Civil Engineering Technicians'},
    {value: 'Civil Engineers', label: 'Civil Engineers'},
    {value: 'Claims Adjusters, Examiners, and Investigators', label: 'Claims Adjusters, Examiners, and Investigators'},
    {value: 'Cleaners of Vehicles and Equipment', label: 'Cleaners of Vehicles and Equipment'},
    {value: 'Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders', label: 'Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders'},
    {value: 'Clinical Data Managers', label: 'Clinical Data Managers'},
    {value: 'Clinical Nurse Specialists', label: 'Clinical Nurse Specialists'},
    {value: 'Clinical Psychologists', label: 'Clinical Psychologists'},
    {value: 'Clinical Research Coordinators', label: 'Clinical Research Coordinators'},
    {value: 'Coaches and Scouts', label: 'Coaches and Scouts'},
    {value: 'Coating, Painting, and Spraying Machine Setters, Operators, and Tenders', label: 'Coating, Painting, and Spraying Machine Setters, Operators, and Tenders'},
    {value: 'Coil Winders, Tapers, and Finishers', label: 'Coil Winders, Tapers, and Finishers'},
    {value: 'Coin, Vending, and Amusement Machine Servicers and Repairers', label: 'Coin, Vending, and Amusement Machine Servicers and Repairers'},
    {value: 'Combined Food Preparation and Serving Workers, Including Fast Food', label: 'Combined Food Preparation and Serving Workers, Including Fast Food'},
    {value: 'Commercial and Industrial Designers', label: 'Commercial and Industrial Designers'},
    {value: 'Commercial Divers', label: 'Commercial Divers'},
    {value: 'Commercial Pilots', label: 'Commercial Pilots'},
    {value: 'Communications Equipment Operators, All Other', label: 'Communications Equipment Operators, All Other'},
    {value: 'Communications Teachers, Postsecondary', label: 'Communications Teachers, Postsecondary'},
    {value: 'Community and Social Service Specialists, All Other', label: 'Community and Social Service Specialists, All Other'},
    {value: 'Community Health Workers', label: 'Community Health Workers'},
    {value: 'Community Service Managers', label: 'Community Service Managers'},
    {value: 'Compensation and Benefits Managers', label: 'Compensation and Benefits Managers'},
    {value: 'Compensation, Benefits, and Job Analysis Specialists', label: 'Compensation, Benefits, and Job Analysis Specialists'},
    {value: 'Compliance Officers', label: 'Compliance Officers'},
    {value: 'Computer Hardware Engineers', label: 'Computer Hardware Engineers'},
    {value: 'Computer Network Architects', label: 'Computer Network Architects'},
    {value: 'Computer Network Support Specialists', label: 'Computer Network Support Specialists'},
    {value: 'Computer Numerically Controlled Machine Tool Programmers, Metal and Plastic', label: 'Computer Numerically Controlled Machine Tool Programmers, Metal and Plastic'},
    {value: 'Computer Occupations, All Other', label: 'Computer Occupations, All Other'},
    {value: 'Computer Operators', label: 'Computer Operators'},
    {value: 'Computer Programmers', label: 'Computer Programmers'},
    {value: 'Computer Science Teachers, Postsecondary', label: 'Computer Science Teachers, Postsecondary'},
    {value: 'Computer Systems Analysts', label: 'Computer Systems Analysts'},
    {value: 'Computer User Support Specialists', label: 'Computer User Support Specialists'},
    {value: 'Computer-Controlled Machine Tool Operators, Metal and Plastic', label: 'Computer-Controlled Machine Tool Operators, Metal and Plastic'},
    {value: 'Concierges', label: 'Concierges'},
    {value: 'Conservation Scientists', label: 'Conservation Scientists'},
    {value: 'Construction and Building Inspectors', label: 'Construction and Building Inspectors'},
    {value: 'Construction and Related Workers, All Other', label: 'Construction and Related Workers, All Other'},
    {value: 'Construction Laborers', label: 'Construction Laborers'},
    {value: 'Construction Managers', label: 'Construction Managers'},
    {value: 'Continuous Mining Machine Operators', label: 'Continuous Mining Machine Operators'},
    {value: 'Control and Valve Installers and Repairers, Except Mechanical Door', label: 'Control and Valve Installers and Repairers, Except Mechanical Door'},
    {value: 'Conveyor Operators and Tenders', label: 'Conveyor Operators and Tenders'},
    {value: 'Cooks, All Other', label: 'Cooks, All Other'},
    {value: 'Cooks, Fast Food', label: 'Cooks, Fast Food'},
    {value: 'Cooks, Institution and Cafeteria', label: 'Cooks, Institution and Cafeteria'},
    {value: 'Cooks, Private Household', label: 'Cooks, Private Household'},
    {value: 'Cooks, Restaurant', label: 'Cooks, Restaurant'},
    {value: 'Cooks, Short Order', label: 'Cooks, Short Order'},
    {value: 'Cooling and Freezing Equipment Operators and Tenders', label: 'Cooling and Freezing Equipment Operators and Tenders'},
    {value: 'Copy Writers', label: 'Copy Writers'},
    {value: 'Correctional Officers and Jailers', label: 'Correctional Officers and Jailers'},
    {value: 'Correspondence Clerks', label: 'Correspondence Clerks'},
    {value: 'Cost Estimators', label: 'Cost Estimators'},
    {value: 'Costume Attendants', label: 'Costume Attendants'},
    {value: 'Counseling Psychologists', label: 'Counseling Psychologists'},
    {value: 'Counselors, All Other', label: 'Counselors, All Other'},
    {value: 'Counter and Rental Clerks', label: 'Counter and Rental Clerks'},
    {value: 'Counter Attendants, Cafeteria, Food Concession, and Coffee Shop', label: 'Counter Attendants, Cafeteria, Food Concession, and Coffee Shop'},
    {value: 'Couriers and Messengers', label: 'Couriers and Messengers'},
    {value: 'Court Reporters', label: 'Court Reporters'},
    {value: 'Court, Municipal, and License Clerks', label: 'Court, Municipal, and License Clerks'},
    {value: 'Craft Artists', label: 'Craft Artists'},
    {value: 'Crane and Tower Operators', label: 'Crane and Tower Operators'},
    {value: 'Credit Analysts', label: 'Credit Analysts'},
    {value: 'Credit Authorizers, Checkers, and Clerks', label: 'Credit Authorizers, Checkers, and Clerks'},
    {value: 'Credit Counselors', label: 'Credit Counselors'},
    {value: 'Criminal Justice and Law Enforcement Teachers, Postsecondary', label: 'Criminal Justice and Law Enforcement Teachers, Postsecondary'},
    {value: 'Crossing Guards', label: 'Crossing Guards'},
    {value: 'Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders', label: 'Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders'},
    {value: 'Curators', label: 'Curators'},
    {value: 'Customer Service Representatives', label: 'Customer Service Representatives'},
    {value: 'Cutters and Trimmers, Hand', label: 'Cutters and Trimmers, Hand'},
    {value: 'Cutting and Slicing Machine Setters, Operators, and Tenders', label: 'Cutting and Slicing Machine Setters, Operators, and Tenders'},
    {value: 'Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic', label: 'Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic'},
    {value: 'Dancers', label: 'Dancers'},
    {value: 'Data Entry Keyers', label: 'Data Entry Keyers'},
    {value: 'Database Administrators', label: 'Database Administrators'},
    {value: 'Dental Assistants', label: 'Dental Assistants'},
    {value: 'Dental Hygienists', label: 'Dental Hygienists'},
    {value: 'Dental Laboratory Technicians', label: 'Dental Laboratory Technicians'},
    {value: 'Dentists, All Other Specialists', label: 'Dentists, All Other Specialists'},
    {value: 'Dentists, General', label: 'Dentists, General'},
    {value: 'Dermatologists', label: 'Dermatologists'},
    {value: 'Desktop Publishers', label: 'Desktop Publishers'},
    {value: 'Detectives and Criminal Investigators', label: 'Detectives and Criminal Investigators'},
    {value: 'Diagnostic Medical Sonographers', label: 'Diagnostic Medical Sonographers'},
    {value: 'Dietetic Technicians', label: 'Dietetic Technicians'},
    {value: 'Dietitians and Nutritionists', label: 'Dietitians and Nutritionists'},
    {value: 'Dining Room and Cafeteria Attendants and Bartender Helpers', label: 'Dining Room and Cafeteria Attendants and Bartender Helpers'},
    {value: 'Directors, Religious Activities and Education', label: 'Directors, Religious Activities and Education'},
    {value: 'Dishwashers', label: 'Dishwashers'},
    {value: 'Dispatchers, Except Police, Fire, and Ambulance', label: 'Dispatchers, Except Police, Fire, and Ambulance'},
    {value: 'Door-To-Door Sales Workers, News and Street Vendors, and Related Workers', label: 'Door-To-Door Sales Workers, News and Street Vendors, and Related Workers'},
    {value: 'Drafters, All Other', label: 'Drafters, All Other'},
    {value: 'Dredge Operators', label: 'Dredge Operators'},
    {value: 'Dredge, Excavating, and Loading Machine Operators', label: 'Dredge, Excavating, and Loading Machine Operators'},
    {value: 'Drywall and Ceiling Tile Installers', label: 'Drywall and Ceiling Tile Installers'},
    {value: 'Earth Drillers, Except Oil and Gas', label: 'Earth Drillers, Except Oil and Gas'},
    {value: 'Economics Teachers, Postsecondary', label: 'Economics Teachers, Postsecondary'},
    {value: 'Economists', label: 'Economists'},
    {value: 'Editors', label: 'Editors'},
    {value: 'Education Administrators, All Other', label: 'Education Administrators, All Other'},
    {value: 'Education Administrators, Elementary and Secondary School', label: 'Education Administrators, Elementary and Secondary School'},
    {value: 'Education Administrators, Postsecondary', label: 'Education Administrators, Postsecondary'},
    {value: 'Education Administrators, Preschool and Childcare Center/Program', label: 'Education Administrators, Preschool and Childcare Center/Program'},
    {value: 'Education Teachers, Postsecondary', label: 'Education Teachers, Postsecondary'},
    {value: 'Educational, Guidance, School, and Vocational Counselors', label: 'Educational, Guidance, School, and Vocational Counselors'},
    {value: 'Electric Motor, Power Tool, and Related Repairers', label: 'Electric Motor, Power Tool, and Related Repairers'},
    {value: 'Electrical and Electronic Engineering Technicians', label: 'Electrical and Electronic Engineering Technicians'},
    {value: 'Electrical and Electronic Equipment Assemblers', label: 'Electrical and Electronic Equipment Assemblers'},
    {value: 'Electrical and Electronics Drafters', label: 'Electrical and Electronics Drafters'},
    {value: 'Electrical and Electronics Engineering Technicians', label: 'Electrical and Electronics Engineering Technicians'},
    {value: 'Electrical and Electronics Engineers', label: 'Electrical and Electronics Engineers'},
    {value: 'Electrical Power-Line Installers and Repairers', label: 'Electrical Power-Line Installers and Repairers'},
    {value: 'Electrical, Electronics, and Electromechanical Assemblers, Except Coil Winders, Tapers, and Finishers', label: 'Electrical, Electronics, and Electromechanical Assemblers, Except Coil Winders, Tapers, and Finishers'},
    {value: 'Electricians', label: 'Electricians'},
    {value: 'Electro-Mechanical Technicians', label: 'Electro-Mechanical Technicians'},
    {value: 'Electronic Equipment Installers and Repairers, Motor Vehicles', label: 'Electronic Equipment Installers and Repairers, Motor Vehicles'},
    {value: 'Electronic Home Entertainment Equipment Installers and Repairers', label: 'Electronic Home Entertainment Equipment Installers and Repairers'},
    {value: 'Electronics Engineering Technicians', label: 'Electronics Engineering Technicians'},
    {value: 'Electronics Engineers, Except Computer', label: 'Electronics Engineers, Except Computer'},
    {value: 'Elementary School Teachers, Except Special Education', label: 'Elementary School Teachers, Except Special Education'},
    {value: 'Elevator Installers and Repairers', label: 'Elevator Installers and Repairers'},
    {value: 'Eligibility Interviewers, Government Programs', label: 'Eligibility Interviewers, Government Programs'},
    {value: 'Embalmers', label: 'Embalmers'},
    {value: 'Emergency Management Directors', label: 'Emergency Management Directors'},
    {value: 'Emergency Medical Technicians and Paramedics', label: 'Emergency Medical Technicians and Paramedics'},
    {value: 'Endoscopy Technicians', label: 'Endoscopy Technicians'},
    {value: 'Energy Auditors', label: 'Energy Auditors'},
    {value: 'Energy Engineers', label: 'Energy Engineers'},
    {value: 'Engineering Teachers, Postsecondary', label: 'Engineering Teachers, Postsecondary'},
    {value: 'Engineering Technicians, Except Drafters, All Other', label: 'Engineering Technicians, Except Drafters, All Other'},
    {value: 'Engineering, All Other', label: 'Engineering, All Other'},
    {value: 'Engineers, All Other', label: 'Engineers, All Other'},
    {value: 'English Language and Literature Teachers, Postsecondary', label: 'English Language and Literature Teachers, Postsecondary'},
    {value: 'Entertainers and Performers, Sports and Related Workers, All Other', label: 'Entertainers and Performers, Sports and Related Workers, All Other'},
    {value: 'Entertainment Attendants and Related Workers, All Other', label: 'Entertainment Attendants and Related Workers, All Other'},
    {value: 'Environmental Compliance Inspectors', label: 'Environmental Compliance Inspectors'},
    {value: 'Environmental Economists', label: 'Environmental Economists'},
    {value: 'Environmental Engineering Technicians', label: 'Environmental Engineering Technicians'},
    {value: 'Environmental Engineers', label: 'Environmental Engineers'},
    {value: 'Environmental Science and Protection Technicians, Including Health', label: 'Environmental Science and Protection Technicians, Including Health'},
    {value: 'Environmental Science Teachers, Postsecondary', label: 'Environmental Science Teachers, Postsecondary'},
    {value: 'Environmental Scientists and Specialists, Including Health', label: 'Environmental Scientists and Specialists, Including Health'},
    {value: 'Epidemiologists', label: 'Epidemiologists'},
    {value: 'Etchers and Engravers', label: 'Etchers and Engravers'},
    {value: 'Excavating and Loading Machine and Dragline Operators', label: 'Excavating and Loading Machine and Dragline Operators'},
    {value: 'Executive Secretaries and Executive Administrative Assistants', label: 'Executive Secretaries and Executive Administrative Assistants'},
    {value: 'Explosives Workers, Ordnance Handling Experts, and Blasters', label: 'Explosives Workers, Ordnance Handling Experts, and Blasters'},
    {value: 'Extraction Workers, All Other', label: 'Extraction Workers, All Other'},
    {value: 'Extruding and Drawing Machine Setters, Operators, and Tenders, Metal and Plastic', label: 'Extruding and Drawing Machine Setters, Operators, and Tenders, Metal and Plastic'},
    {value: 'Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders', label: 'Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders'},
    {value: 'Fabric and Apparel Patternmakers', label: 'Fabric and Apparel Patternmakers'},
    {value: 'Fallers', label: 'Fallers'},
    {value: 'Family and General Practitioners', label: 'Family and General Practitioners'},
    {value: 'Farm and Home Management Advisors', label: 'Farm and Home Management Advisors'},
    {value: 'Farm Equipment Mechanics and Service Technicians', label: 'Farm Equipment Mechanics and Service Technicians'},
    {value: 'Farm Labor Contractors', label: 'Farm Labor Contractors'},
    {value: 'Farmers, Ranchers, and Other Agricultural Managers', label: 'Farmers, Ranchers, and Other Agricultural Managers'},
    {value: 'Fashion Designers', label: 'Fashion Designers'},
    {value: 'Fence Erectors', label: 'Fence Erectors'},
    {value: 'Fiberglass Laminators and Fabricators', label: 'Fiberglass Laminators and Fabricators'},
    {value: 'File Clerks', label: 'File Clerks'},
    {value: 'Film and Video Editors', label: 'Film and Video Editors'},
    {value: 'Financial Analysts', label: 'Financial Analysts'},
    {value: 'Financial Clerks, All Other', label: 'Financial Clerks, All Other'},
    {value: 'Financial Examiners', label: 'Financial Examiners'},
    {value: 'Financial Managers', label: 'Financial Managers'},
    {value: 'Financial Specialists, All Other', label: 'Financial Specialists, All Other'},
    {value: 'Fine Artists, Including Painters, Sculptors, and Illustrators', label: 'Fine Artists, Including Painters, Sculptors, and Illustrators'},
    {value: 'Fire Inspectors', label: 'Fire Inspectors'},
    {value: 'Fire Investigators', label: 'Fire Investigators'},
    {value: 'Firefighters', label: 'Firefighters'},
    {value: 'First-Line Supervisors of Agricultural Crop and Horticultural Workers', label: 'First-Line Supervisors of Agricultural Crop and Horticultural Workers'},
    {value: 'First-Line Supervisors of Air Crew Members', label: 'First-Line Supervisors of Air Crew Members'},
    {value: 'First-Line Supervisors of All Other Tactical Operations Specialists', label: 'First-Line Supervisors of All Other Tactical Operations Specialists'},
    {value: 'First-Line Supervisors of Animal Husbandry and Animal Care Workers', label: 'First-Line Supervisors of Animal Husbandry and Animal Care Workers'},
    {value: 'First-Line Supervisors of Aquacultural Workers', label: 'First-Line Supervisors of Aquacultural Workers'},
    {value: 'First-Line Supervisors of Construction Trades and Extraction Workers', label: 'First-Line Supervisors of Construction Trades and Extraction Workers'},
    {value: 'First-Line Supervisors of Correctional Officers', label: 'First-Line Supervisors of Correctional Officers'},
    {value: 'First-Line Supervisors of Farming, Fishing, and Forestry Workers', label: 'First-Line Supervisors of Farming, Fishing, and Forestry Workers'},
    {value: 'First-Line Supervisors of Fire Fighting and Prevention Workers', label: 'First-Line Supervisors of Fire Fighting and Prevention Workers'},
    {value: 'First-Line Supervisors of Food Preparation and Serving Workers', label: 'First-Line Supervisors of Food Preparation and Serving Workers'},
    {value: 'First-Line Supervisors of Helpers, Laborers, and Material Movers, Hand', label: 'First-Line Supervisors of Helpers, Laborers, and Material Movers, Hand'},
    {value: 'First-Line Supervisors of Housekeeping and Janitorial Workers', label: 'First-Line Supervisors of Housekeeping and Janitorial Workers'},
    {value: 'First-Line Supervisors of Landscaping, Lawn Service, and Groundskeeping Workers', label: 'First-Line Supervisors of Landscaping, Lawn Service, and Groundskeeping Workers'},
    {value: 'First-Line Supervisors of Logging Workers', label: 'First-Line Supervisors of Logging Workers'},
    {value: 'First-Line Supervisors of Mechanics, Installers, and Repairers', label: 'First-Line Supervisors of Mechanics, Installers, and Repairers'},
    {value: 'First-Line Supervisors of Non-Retail Sales Workers', label: 'First-Line Supervisors of Non-Retail Sales Workers'},
    {value: 'First-Line Supervisors of Office and Administrative Support Workers', label: 'First-Line Supervisors of Office and Administrative Support Workers'},
    {value: 'First-Line Supervisors of Personal Service Workers', label: 'First-Line Supervisors of Personal Service Workers'},
    {value: 'First-Line Supervisors of Police and Detectives', label: 'First-Line Supervisors of Police and Detectives'},
    {value: 'First-Line Supervisors of Production and Operating Workers', label: 'First-Line Supervisors of Production and Operating Workers'},
    {value: 'First-Line Supervisors of Protective Service Workers, All Other', label: 'First-Line Supervisors of Protective Service Workers, All Other'},
    {value: 'First-Line Supervisors of Retail Sales Workers', label: 'First-Line Supervisors of Retail Sales Workers'},
    {value: 'First-Line Supervisors of Transportation and Material-Moving Machine and Vehicle Operators', label: 'First-Line Supervisors of Transportation and Material-Moving Machine and Vehicle Operators'},
    {value: 'Fish and Game Wardens', label: 'Fish and Game Wardens'},
    {value: 'Fishers and Related Fishing Workers', label: 'Fishers and Related Fishing Workers'},
    {value: 'Fitness Trainers and Aerobics Instructors', label: 'Fitness Trainers and Aerobics Instructors'},
    {value: 'Flight Attendants', label: 'Flight Attendants'},
    {value: 'Floor Layers, Except Carpet, Wood, and Hard Tiles', label: 'Floor Layers, Except Carpet, Wood, and Hard Tiles'},
    {value: 'Floor Sanders and Finishers', label: 'Floor Sanders and Finishers'},
    {value: 'Floral Designers', label: 'Floral Designers'},
    {value: 'Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders', label: 'Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders'},
    {value: 'Food Batchmakers', label: 'Food Batchmakers'},
    {value: 'Food Cooking Machine Operators and Tenders', label: 'Food Cooking Machine Operators and Tenders'},
    {value: 'Food Preparation and Serving Related Workers, All Other', label: 'Food Preparation and Serving Related Workers, All Other'},
    {value: 'Food Preparation Workers', label: 'Food Preparation Workers'},
    {value: 'Food Science Technicians', label: 'Food Science Technicians'},
    {value: 'Food Scientists and Technologists', label: 'Food Scientists and Technologists'},
    {value: 'Food Servers, Nonrestaurant', label: 'Food Servers, Nonrestaurant'},
    {value: 'Food Service Managers', label: 'Food Service Managers'},
    {value: 'Foreign Language and Literature Teachers, Postsecondary', label: 'Foreign Language and Literature Teachers, Postsecondary'},
    {value: 'Forensic Science Technicians', label: 'Forensic Science Technicians'},
    {value: 'Forest and Conservation Technicians', label: 'Forest and Conservation Technicians'},
    {value: 'Forest and Conservation Workers', label: 'Forest and Conservation Workers'},
    {value: 'Forest Fire Fighting and Prevention Supervisors', label: 'Forest Fire Fighting and Prevention Supervisors'},
    {value: 'Forest Fire Inspectors and Prevention Specialists', label: 'Forest Fire Inspectors and Prevention Specialists'},
    {value: 'Forest Firefighters', label: 'Forest Firefighters'},
    {value: 'Foresters', label: 'Foresters'},
    {value: 'Forestry and Conservation Science Teachers, Postsecondary', label: 'Forestry and Conservation Science Teachers, Postsecondary'},
    {value: 'Forging Machine Setters, Operators, and Tenders, Metal and Plastic', label: 'Forging Machine Setters, Operators, and Tenders, Metal and Plastic'},
    {value: 'Foundry Mold and Coremakers', label: 'Foundry Mold and Coremakers'},
    {value: 'Fundraisers', label: 'Fundraisers'},
    {value: 'Funeral Attendants', label: 'Funeral Attendants'},
    {value: 'Funeral Service Managers', label: 'Funeral Service Managers'},
    {value: 'Funeral Service Workers, All Other', label: 'Funeral Service Workers, All Other'},
    {value: 'Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders', label: 'Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders'},
    {value: 'Furniture Finishers', label: 'Furniture Finishers'},
    {value: 'Gaming and Sports Book Writers and Runners', label: 'Gaming and Sports Book Writers and Runners'},
    {value: 'Gaming Cage Workers', label: 'Gaming Cage Workers'},
    {value: 'Gaming Change Persons and Booth Cashiers', label: 'Gaming Change Persons and Booth Cashiers'},
    {value: 'Gaming Dealers', label: 'Gaming Dealers'},
    {value: 'Gaming Managers', label: 'Gaming Managers'},
    {value: 'Gaming Service Workers, All Other', label: 'Gaming Service Workers, All Other'},
    {value: 'Gaming Supervisors', label: 'Gaming Supervisors'},
    {value: 'Gaming Surveillance Officers and Gaming Investigators', label: 'Gaming Surveillance Officers and Gaming Investigators'},
    {value: 'Gas Compressor and Gas Pumping Station Operators', label: 'Gas Compressor and Gas Pumping Station Operators'},
    {value: 'Gas Plant Operators', label: 'Gas Plant Operators'},
    {value: 'General and Operations Managers', label: 'General and Operations Managers'},
    {value: 'Genetic Counselors', label: 'Genetic Counselors'},
    {value: 'Geodetic Surveyors', label: 'Geodetic Surveyors'},
    {value: 'Geographers', label: 'Geographers'},
    {value: 'Geography Teachers, Postsecondary', label: 'Geography Teachers, Postsecondary'},
    {value: 'Geological and Petroleum Technicians', label: 'Geological and Petroleum Technicians'},
    {value: 'Geological Sample Test Technicians', label: 'Geological Sample Test Technicians'},
    {value: 'Geoscientists, Except Hydrologists and Geographers', label: 'Geoscientists, Except Hydrologists and Geographers'},
    {value: 'Glaziers', label: 'Glaziers'},
    {value: 'Graders and Sorters, Agricultural Products', label: 'Graders and Sorters, Agricultural Products'},
    {value: 'Graduate Teaching Assistants', label: 'Graduate Teaching Assistants'},
    {value: 'Graphic Designers', label: 'Graphic Designers'},
    {value: 'Grinding and Polishing Workers, Hand', label: 'Grinding and Polishing Workers, Hand'},
    {value: 'Grinding, Lapping, Polishing, and Buffing Machine Tool Setters, Operators, and Tenders, Metal and Plastic', label: 'Grinding, Lapping, Polishing, and Buffing Machine Tool Setters, Operators, and Tenders, Metal and Plastic'},
    {value: 'Grounds Maintenance Workers, All Other', label: 'Grounds Maintenance Workers, All Other'},
    {value: 'Hairdressers, Hairstylists, and Cosmetologists', label: 'Hairdressers, Hairstylists, and Cosmetologists'},
    {value: 'Hazardous Materials Removal Workers', label: 'Hazardous Materials Removal Workers'},
    {value: 'Health and Safety Engineers, Except Mining Safety Engineers and Inspectors', label: 'Health and Safety Engineers, Except Mining Safety Engineers and Inspectors'},
    {value: 'Health Diagnosing and Treating Practitioners, All Other', label: 'Health Diagnosing and Treating Practitioners, All Other'},
    {value: 'Health Educators', label: 'Health Educators'},
    {value: 'Health Specialties Teachers, Postsecondary', label: 'Health Specialties Teachers, Postsecondary'},
    {value: 'Health Technologists and Technicians, All Other', label: 'Health Technologists and Technicians, All Other'},
    {value: 'Healthcare Practitioners and Technical Workers, All Other', label: 'Healthcare Practitioners and Technical Workers, All Other'},
    {value: 'Healthcare Social Workers', label: 'Healthcare Social Workers'},
    {value: 'Healthcare Support Workers, All Other', label: 'Healthcare Support Workers, All Other'},
    {value: 'Healthcare Support Workers, All Other', label: 'Healthcare Support Workers, All Other'},
    {value: 'Hearing Aid Specialists', label: 'Hearing Aid Specialists'},
    {value: 'Heating and Air Conditioning Mechanics and Installers', label: 'Heating and Air Conditioning Mechanics and Installers'},
    {value: 'Heavy and Tractor-Trailer Truck Drivers', label: 'Heavy and Tractor-Trailer Truck Drivers'},
    {value: 'Helpers--Brickmasons, Blockmasons, Stonemasons, and Tile and Marble Setters', label: 'Helpers--Brickmasons, Blockmasons, Stonemasons, and Tile and Marble Setters'},
    {value: 'Helpers--Carpenters', label: 'Helpers--Carpenters'},
    {value: 'Helpers--Electricians', label: 'Helpers--Electricians'},
    {value: 'Helpers--Extraction Workers', label: 'Helpers--Extraction Workers'},
    {value: 'Helpers--Installation, Maintenance, and Repair Workers', label: 'Helpers--Installation, Maintenance, and Repair Workers'},
    {value: 'Helpers--Painters, Paperhangers, Plasterers, and Stucco Masons', label: 'Helpers--Painters, Paperhangers, Plasterers, and Stucco Masons'},
    {value: 'Helpers--Pipelayers, Plumbers, Pipefitters, and Steamfitters', label: 'Helpers--Pipelayers, Plumbers, Pipefitters, and Steamfitters'},
    {value: 'Helpers--Production Workers', label: 'Helpers--Production Workers'},
    {value: 'Helpers--Roofers', label: 'Helpers--Roofers'},
    {value: 'Highway Maintenance Workers', label: 'Highway Maintenance Workers'},
    {value: 'Historians', label: 'Historians'},
    {value: 'History Teachers, Postsecondary', label: 'History Teachers, Postsecondary'},
    {value: 'Hoist and Winch Operators', label: 'Hoist and Winch Operators'},
    {value: 'Home Appliance Repairers', label: 'Home Appliance Repairers'},
    {value: 'Home Economics Teachers, Postsecondary', label: 'Home Economics Teachers, Postsecondary'},
    {value: 'Home Health Aides', label: 'Home Health Aides'},
    {value: 'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop', label: 'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop'},
    {value: 'Hotel, Motel, and Resort Desk Clerks', label: 'Hotel, Motel, and Resort Desk Clerks'},
    {value: 'Human Resources Assistants, Except Payroll and Timekeeping', label: 'Human Resources Assistants, Except Payroll and Timekeeping'},
    {value: 'Human Resources Managers', label: 'Human Resources Managers'},
    {value: 'Human Resources Specialists', label: 'Human Resources Specialists'},
    {value: 'Hydrologists', label: 'Hydrologists'},
    {value: 'Industrial Engineering Technicians', label: 'Industrial Engineering Technicians'},
    {value: 'Industrial Engineers', label: 'Industrial Engineers'},
    {value: 'Industrial Machinery Mechanics', label: 'Industrial Machinery Mechanics'},
    {value: 'Industrial Production Managers', label: 'Industrial Production Managers'},
    {value: 'Industrial Truck and Tractor Operators', label: 'Industrial Truck and Tractor Operators'},
    {value: 'Industrial-Organizational Psychologists', label: 'Industrial-Organizational Psychologists'},
    {value: 'Information and Record Clerks, All Other', label: 'Information and Record Clerks, All Other'},
    {value: 'Information Security Analysts', label: 'Information Security Analysts'},
    {value: 'Information Technology Project Managers', label: 'Information Technology Project Managers'},
    {value: 'Inspectors, Testers, Sorters, Samplers, and Weighers', label: 'Inspectors, Testers, Sorters, Samplers, and Weighers'},
    {value: 'Instructional Coordinators', label: 'Instructional Coordinators'},
    {value: 'Insulation Workers, Floor, Ceiling, and Wall', label: 'Insulation Workers, Floor, Ceiling, and Wall'},
    {value: 'Insulation Workers, Mechanical', label: 'Insulation Workers, Mechanical'},
    {value: 'Insurance Adjusters, Examiners, and Investigators', label: 'Insurance Adjusters, Examiners, and Investigators'},
    {value: 'Insurance Appraisers, Auto Damage', label: 'Insurance Appraisers, Auto Damage'},
    {value: 'Insurance Claims and Policy Processing Clerks', label: 'Insurance Claims and Policy Processing Clerks'},
    {value: 'Insurance Sales Agents', label: 'Insurance Sales Agents'},
    {value: 'Insurance Underwriters', label: 'Insurance Underwriters'},
    {value: 'Intelligence Analysts', label: 'Intelligence Analysts'},
    {value: 'Interior Designers', label: 'Interior Designers'},
    {value: 'Interpreter and Translator', label: 'Interpreter and Translator'},
    {value: 'Interviewers, Except Eligibility and Loan', label: 'Interviewers, Except Eligibility and Loan'},
    {value: 'Investment Fund Managers', label: 'Investment Fund Managers'},
    {value: 'Janitors and Cleaners, Except Maids and Housekeeping Cleaners', label: 'Janitors and Cleaners, Except Maids and Housekeeping Cleaners'},
    {value: 'Jewelers and Precious Stone and Metal Workers', label: 'Jewelers and Precious Stone and Metal Workers'},
    {value: 'Judges, Magistrate Judges, and Magistrates', label: 'Judges, Magistrate Judges, and Magistrates'},
    {value: 'Judicial Law Clerks', label: 'Judicial Law Clerks'},
    {value: 'Kindergarten Teachers, Except Special Education', label: 'Kindergarten Teachers, Except Special Education'},
    {value: 'Labor Relations Specialists', label: 'Labor Relations Specialists'},
    {value: 'Laborers and Freight, Stock, and Material Movers, Hand', label: 'Laborers and Freight, Stock, and Material Movers, Hand'},
    {value: 'Landscape Architects', label: 'Landscape Architects'},
    {value: 'Landscaping and Groundskeeping Workers', label: 'Landscaping and Groundskeeping Workers'},
    {value: 'Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic', label: 'Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic'},
    {value: 'Laundry and Dry-Cleaning Workers', label: 'Laundry and Dry-Cleaning Workers'},
    {value: 'Law Teachers, Postsecondary', label: 'Law Teachers, Postsecondary'},
    {value: 'Lawyers', label: 'Lawyers'},
    {value: 'Layout Workers, Metal and Plastic', label: 'Layout Workers, Metal and Plastic'},
    {value: 'Legal Secretaries', label: 'Legal Secretaries'},
    {value: 'Legal Support Workers, All Other', label: 'Legal Support Workers, All Other'},
    {value: 'Legislators', label: 'Legislators'},
    {value: 'Librarians', label: 'Librarians'},
    {value: 'Library Assistants, Clerical', label: 'Library Assistants, Clerical'},
    {value: 'Library Science Teachers, Postsecondary', label: 'Library Science Teachers, Postsecondary'},
    {value: 'Library Technicians', label: 'Library Technicians'},
    {value: 'Licensed Practical and Licensed Vocational Nurses', label: 'Licensed Practical and Licensed Vocational Nurses'},
    {value: 'Life Scientists, All Other', label: 'Life Scientists, All Other'},
    {value: 'Life, Physical, and Social Science Technicians, All Other', label: 'Life, Physical, and Social Science Technicians, All Other'},
    {value: 'Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers', label: 'Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers'},
    {value: 'Light Truck or Delivery Services Drivers', label: 'Light Truck or Delivery Services Drivers'},
    {value: 'Light Technicians', label: 'Light Technicians'},
    {value: 'Loadding Machine Operators, Underground Mining', label: 'Loadding Machine Operators, Underground Mining'},
    {value: 'Line Installers and Repairers', label: 'Line Installers and Repairers'},
    {value: 'Loan Interviewers and Clerks', label: 'Loan Interviewers and Clerks'},
    {value: 'Loan Officers', label: 'Loan Officers'},
    {value: 'Locker Room, Coatroom, and Dressing Room Attendants', label: 'Locker Room, Coatroom, and Dressing Room Attendants'},
    {value: 'Locksmiths and Safe Repairers', label: 'Locksmiths and Safe Repairers'},
    {value: 'Locomotive Engineers', label: 'Locomotive Engineers'},
    {value: 'Locomotive Firers', label: 'Locomotive Firers'},
    {value: 'Lodging Managers', label: 'Lodging Managers'},
    {value: 'Log Graders and Scalers', label: 'Log Graders and Scalers'},
    {value: 'Logging Equipment Operators', label: 'Logging Equipment Operators'},
    {value: 'Logisticians', label: 'Logisticians'},
    {value: 'Logistics Analysts', label: 'Logistics Analysts'},
    {value: 'Logistics Engineers', label: 'Logistics Engineers'},
    {value: 'Logistics Managers', label: 'Logistics Managers'},
    {value: 'Loss Prevention Managers', label: 'Loss Prevention Managers'},
    {value: 'Low Vision Therapists, Orientation and Mobility Specialists, and Vision Rehabilitation Therapists', label: 'Low Vision Therapists, Orientation and Mobility Specialists, and Vision Rehabilitation Therapists'},
    {value: 'Machine Feeders and Offbearers', label: 'Machine Feeders and Offbearers'},
    {value: 'Machinists', label: 'Machinists'},
    {value: 'Magnetic Resonance Imaging Technologists', label: 'Magnetic Resonance Imaging Technologists'},
    {value: 'Maids and Housekeeping Cleaners', label: 'Maids and Housekeeping Cleaners'},
    {value: 'Mail Clerks and Mail Machine Operators, Except Postal Service', label: 'Mail Clerks and Mail Machine Operators, Except Postal Service'},
    {value: 'Maintenance and Repair Workers, General', label: 'Maintenance and Repair Workers, General'},
    {value: 'Maintenance Workers, Machinery', label: 'Maintenance Workers, Machinery'},
    {value: 'Makeup Artists, Theatrical and Performance', label: 'Makeup Artists, Theatrical and Performance'},
    {value: 'Management Analysts', label: 'Management Analysts'},
    {value: 'Managers, All Other', label: 'Managers, All Other'},
    {value: 'Manicurists and Pedicurists', label: 'Manicurists and Pedicurists'},
    {value: 'Manufactured Building and Mobile Home Installers', label: 'Manufactured Building and Mobile Home Installers'},
    {value: 'Manufacturing Engineering Technologists', label: 'Manufacturing Engineering Technologists'},
    {value: 'Manufacturing Engineers', label: 'Manufacturing Engineers'},
    {value: 'Manufacturing Production Technicians', label: 'Manufacturing Production Technicians'},
    {value: 'Mapping Technicians', label: 'Mapping Technicians'},
    {value: 'Marine Architects', label: 'Marine Architects'},
    {value: 'Marine Engineers', label: 'Marine Engineers'},
    {value: 'Marine Engineers and Naval Architects', label: 'Marine Engineers and Naval Architects'},
    {value: 'Market Research Analysts and Marketing Specialists', label: 'Market Research Analysts and Marketing Specialists'},
    {value: 'Marketing Managers', label: 'Marketing Managers'},
    {value: 'Marriage and Family Therapists', label: 'Marriage and Family Therapists'},
    {value: 'Massage Therapists', label: 'Massage Therapists'},
    {value: 'Material Moving Workers, All Other', label: 'Material Moving Workers, All Other'},
    {value: 'Materials Engineers', label: 'Materials Engineers'},
    {value: 'Materials Scientists', label: 'Materials Scientists'},
    {value: 'Mathematical Science Occupations, All Other', label: 'Mathematical Science Occupations, All Other'},
    {value: 'Mathematical Science Teachers, Postsecondary', label: 'Mathematical Science Teachers, Postsecondary'},
    {value: 'Mathematical Technicians', label: 'Mathematical Technicians'},
    {value: 'Mathematicians', label: 'Mathematicians'},
    {value: 'Meat, Poultry, and Fish Cutters and Trimmers', label: 'Meat, Poultry, and Fish Cutters and Trimmers'},
    {value: 'Mechanical Door Repairers', label: 'Mechanical Door Repairers'},
    {value: 'Mechanical Drafters', label: 'Mechanical Drafters'},
    {value: 'Mechanical Engineering Technicians', label: 'Mechanical Engineering Technicians'},
    {value: 'Mechanical Engineers', label: 'Mechanical Engineers'},
    {value: 'Mechatronics Engineers', label: 'Mechatronics Engineers'},
    {value: 'Medical and Clinical Laboratory Technicians', label: 'Medical and Clinical Laboratory Technicians'},
    {value: 'Medical and Clinical Laboratory Technologists', label: 'Medical and Clinical Laboratory Technologists'},
    {value: 'Medical and Health Services Managers', label: 'Medical and Health Services Managers'},
    {value: 'Medical Appliance Technicians', label: 'Medical Appliance Technicians'},
    {value: 'Medical Assistants', label: 'Medical Assistants'},
    {value: 'Medical Equipment Preparers', label: 'Medical Equipment Preparers'},
    {value: 'Medical Equipment Repairers', label: 'Medical Equipment Repairers'},
    {value: 'Medical Records and Health Information Technicians', label: 'Medical Records and Health Information Technicians'},
    {value: 'Medical Scientists, Except Epidemiologists', label: 'Medical Scientists, Except Epidemiologists'},
    {value: 'Medical Secretaries', label: 'Medical Secretaries'},
    {value: 'Medical Transcriptionists', label: 'Medical Transcriptionists'},
    {value: 'Medical, Dental, and Ophthalmic Laboratory Technicians', label: 'Medical, Dental, and Ophthalmic Laboratory Technicians'},
    {value: 'Meeting, Convention, and Event Planners', label: 'Meeting, Convention, and Event Planners'},
    {value: 'Mental Health and Substance Abuse Social Workers', label: 'Mental Health and Substance Abuse Social Workers'},
    {value: 'Mental Health Counselors', label: 'Mental Health Counselors'},
    {value: 'Merchandise Displayers and Window Trimmers', label: 'Merchandise Displayers and Window Trimmers'},
    {value: 'Metal Workers and Plastic Workers, All Other', label: 'Metal Workers and Plastic Workers, All Other'},
    {value: 'Metal-Refining Furnace Operators and Tenders', label: 'Metal-Refining Furnace Operators and Tenders'},
    {value: 'Meter Readers, Utilities', label: 'Meter Readers, Utilities'},
    {value: 'Microbiologists', label: 'Microbiologists'},
    {value: 'Middle School Teachers, Except Special and Career/Technical Education', label: 'Middle School Teachers, Except Special and Career/Technical Education'},
    {value: 'Millwrights', label: 'Millwrights'},
    {value: 'Mine Cutting and Channeling Machine Operators', label: 'Mine Cutting and Channeling Machine Operators'},
    {value: 'Mining and Geological Engineers, Including Mining Safety Engineers', label: 'Mining and Geological Engineers, Including Mining Safety Engineers'},
    {value: 'Mining Machine Operators, All Other', label: 'Mining Machine Operators, All Other'},
    {value: 'Mining Machine Operators, Continuous Mining', label: 'Mining Machine Operators, Continuous Mining'},
    {value: 'Mining Machine Operators, Underground Mining', label: 'Mining Machine Operators, Underground Mining'},
    {value: 'Mixing and Blending Machine Setters, Operators, and Tenders', label: 'Mixing and Blending Machine Setters, Operators, and Tenders'},
    {value: 'Mobile Heavy Equipment Mechanics, Except Engines', label: 'Mobile Heavy Equipment Mechanics, Except Engines'},
    {value: 'Model Makers, Metal and Plastic', label: 'Model Makers, Metal and Plastic'},
    {value: 'Model Makers, Wood', label: 'Model Makers, Wood'},
    {value: 'Models', label: 'Models'},
    {value: 'Molders, Shapers, and Casters, Except Metal and Plastic', label: 'Molders, Shapers, and Casters, Except Metal and Plastic'},
    {value: 'Molding, Coremaking, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic', label: 'Molding, Coremaking, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic'},
    {value: 'Morticians, Undertakers, and Funeral Directors', label: 'Morticians, Undertakers, and Funeral Directors'},
    {value: 'Motion Picture Projectionists', label: 'Motion Picture Projectionists'},
    {value: 'Motor Vehicle Operators, All Other', label: 'Motor Vehicle Operators, All Other'},
    {value: 'Motorboat Mechanics and Service Technicians', label: 'Motorboat Mechanics and Service Technicians'},
    {value: 'Motorcycle Mechanics', label: 'Motorcycle Mechanics'},
    {value: 'Multimedia Artists and Animators', label: 'Multimedia Artists and Animators'},
    {value: 'Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic', label: 'Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic'},
    {value: 'Municipal Clerks', label: 'Municipal Clerks'},
    {value: 'Museum Technicians and Conservators', label: 'Museum Technicians and Conservators'},
    {value: 'Music Directors and Composers', label: 'Music Directors and Composers'},
    {value: 'Musical Instrument Repairers and Tuners', label: 'Musical Instrument Repairers and Tuners'},
    {value: 'Musicians and Singers', label: 'Musicians and Singers'},
    {value: 'Natural Sciences Managers', label: 'Natural Sciences Managers'},
    {value: 'Network and Computer Systems Administrators', label: 'Network and Computer Systems Administrators'},
    {value: 'Neurodiagnostic Technologists', label: 'Neurodiagnostic Technologists'},
    {value: 'Neurologists', label: 'Neurologists'},
    {value: 'New Accounts Clerks', label: 'New Accounts Clerks'},
    {value: 'News Analysts, Reporters and Correspondents', label: 'News Analysts, Reporters and Correspondents'},
    {value: 'Non-Destructive Testing Specialists', label: 'Non-Destructive Testing Specialists'},
    {value: 'Nuclear Engineers', label: 'Nuclear Engineers'},
    {value: 'Nuclear Medicine Physicians', label: 'Nuclear Medicine Physicians'},
    {value: 'Nuclear Medicine Technologists', label: 'Nuclear Medicine Technologists'},
    {value: 'Nuclear Monitoring Technicians', label: 'Nuclear Monitoring Technicians'},
    {value: 'Nuclear Power Reactor Operators', label: 'Nuclear Power Reactor Operators'},
    {value: 'Nuclear Technicians', label: 'Nuclear Technicians'},
    {value: 'Nursery and Greenhouse Managers', label: 'Nursery and Greenhouse Managers'},
    {value: 'Nursery Workers', label: 'Nursery Workers'},
    {value: 'Nursing Assistants', label: 'Nursing Assistants'},
    {value: 'Nursing Instructors and Teachers, Postsecondary', label: 'Nursing Instructors and Teachers, Postsecondary'},
    {value: 'Obstetricians and Gynecologists', label: 'Obstetricians and Gynecologists'},
    {value: 'Occupational Health and Safety Specialists', label: 'Occupational Health and Safety Specialists'},
    {value: 'Occupational Health and Safety Technicians', label: 'Occupational Health and Safety Technicians'},
    {value: 'Occupational Therapists', label: 'Occupational Therapists'},
    {value: 'Occupational Therapy Aides', label: 'Occupational Therapy Aides'},
    {value: 'Occupational Therapy Assistants', label: 'Occupational Therapy Assistants'},
    {value: 'Office and Administrative Support Workers, All Other', label: 'Office and Administrative Support Workers, All Other'},
    {value: 'Office Clerks, General', label: 'Office Clerks, General'},
    {value: 'Office Machine Operators, Except Computer', label: 'Office Machine Operators, Except Computer'},
    {value: 'Operating Engineers and Other Construction Equipment Operators', label: 'Operating Engineers and Other Construction Equipment Operators'},
    {value: 'Operations Research Analysts', label: 'Operations Research Analysts'},
    {value: 'Ophthalmic Laboratory Technicians', label: 'Ophthalmic Laboratory Technicians'},
    {value: 'Ophthalmic Medical Technicians', label: 'Ophthalmic Medical Technicians'},
    {value: 'Ophthalmic Medical Technologists', label: 'Ophthalmic Medical Technologists'},
    {value: 'Opticians, Dispensing', label: 'Opticians, Dispensing'},
    {value: 'Optometrists', label: 'Optometrists'},
    {value: 'Oral and Maxillofacial Surgeons', label: 'Oral and Maxillofacial Surgeons'},
    {value: 'Order Clerks', label: 'Order Clerks'},
    {value: 'Order Fillers, Wholesale and Retail Sales', label: 'Order Fillers, Wholesale and Retail Sales'},
    {value: 'Orthodontists', label: 'Orthodontists'},
    {value: 'Orthoptists', label: 'Orthoptists'},
    {value: 'Orthotists and Prosthetists', label: 'Orthotists and Prosthetists'},
    {value: 'Outdoor Power Equipment and Other Small Engine Mechanics', label: 'Outdoor Power Equipment and Other Small Engine Mechanics'},
    {value: 'Packaging and Filling Machine Operators and Tenders', label: 'Packaging and Filling Machine Operators and Tenders'},
    {value: 'Packers and Packagers, Hand', label: 'Packers and Packagers, Hand'},
    {value: 'Painters, Construction and Maintenance', label: 'Painters, Construction and Maintenance'},
    {value: 'Painters, Transportation Equipment', label: 'Painters, Transportation Equipment'},
    {value: 'Painting, Coating, and Decorating Workers', label: 'Painting, Coating, and Decorating Workers'},
    {value: 'Paper Goods Machine Setters, Operators, and Tenders', label: 'Paper Goods Machine Setters, Operators, and Tenders'},
    {value: 'Paperhangers', label: 'Paperhangers'},
    {value: 'Paralegals and Legal Assistants', label: 'Paralegals and Legal Assistants'},
    {value: 'Parking Enforcement Workers', label: 'Parking Enforcement Workers'},
    {value: 'Parking Lot Attendants', label: 'Parking Lot Attendants'},
    {value: 'Parts Salespersons', label: 'Parts Salespersons'},
    {value: 'Patternmakers, Metal and Plastic', label: 'Patternmakers, Metal and Plastic'},
    {value: 'Patternmakers, Wood', label: 'Patternmakers, Wood'},
    {value: 'Paving, Surfacing, and Tamping Equipment Operators', label: 'Paving, Surfacing, and Tamping Equipment Operators'},
    {value: 'Payroll and Timekeeping Clerks', label: 'Payroll and Timekeeping Clerks'},
    {value: 'Pediatricians, General', label: 'Pediatricians, General'},
    {value: 'Personal Care Aides', label: 'Personal Care Aides'},
    {value: 'Personal Care and Service Workers, All Other', label: 'Personal Care and Service Workers, All Other'},
    {value: 'Personal Financial Advisors', label: 'Personal Financial Advisors'},
    {value: 'Pest Control Workers', label: 'Pest Control Workers'},
    {value: 'Pesticide Handlers, Sprayers, and Applicators, Vegetation', label: 'Pesticide Handlers, Sprayers, and Applicators, Vegetation'},
    {value: 'Petroleum Engineers', label: 'Petroleum Engineers'},
    {value: 'Petroleum Pump System Operators, Refinery Operators, and Gaugers', label: 'Petroleum Pump System Operators, Refinery Operators, and Gaugers'},
    {value: 'Pharmacists', label: 'Pharmacists'},
    {value: 'Pharmacy Aides', label: 'Pharmacy Aides'},
    {value: 'Pharmacy Technicians', label: 'Pharmacy Technicians'},
    {value: 'Philosophy and Religion Teachers, Postsecondary', label: 'Philosophy and Religion Teachers, Postsecondary'},
    {value: 'Phlebotomists', label: 'Phlebotomists'},
    {value: 'Photographers', label: 'Photographers'},
    {value: 'Photographic Process Workers and Processing Machine Operators', label: 'Photographic Process Workers and Processing Machine Operators'},
    {value: 'Physical Scientists, All Other', label: 'Physical Scientists, All Other'},
    {value: 'Physical Therapist Aides', label: 'Physical Therapist Aides'},
    {value: 'Physical Therapist Assistants', label: 'Physical Therapist Assistants'},
    {value: 'Physical Therapists', label: 'Physical Therapists'},
    {value: 'Physician Assistants', label: 'Physician Assistants'},
    {value: 'Physicians and Surgeons, All Other', label: 'Physicians and Surgeons, All Other'},
    {value: 'Physicists', label: 'Physicists'},
    {value: 'Physics Teachers, Postsecondary', label: 'Physics Teachers, Postsecondary'},
    {value: 'Pile-Driver Operators', label: 'Pile-Driver Operators'},
    {value: 'Pipelayers', label: 'Pipelayers'},
    {value: 'Plant and System Operators, All Other', label: 'Plant and System Operators, All Other'},
    {value: 'Plasterers and Stucco Masons', label: 'Plasterers and Stucco Masons'},
    {value: 'Plating and Coating Machine Setters, Operators, and Tenders, Metal and Plastic', label: 'Plating and Coating Machine Setters, Operators, and Tenders, Metal and Plastic'},
    {value: 'Plumbers, Pipefitters, and Steamfitters', label: 'Plumbers, Pipefitters, and Steamfitters'},
    {value: 'Podiatrists', label: 'Podiatrists'},
    {value: 'Poets, Lyricists and Creative Writers', label: 'Poets, Lyricists and Creative Writers'},
    {value: 'Police and Sheriff\'s Patrol Officers', label: 'Police and Sheriff\'s Patrol Officers'},
    {value: 'Police, Fire, and Ambulance Dispatchers', label: 'Police, Fire, and Ambulance Dispatchers'},
    {value: 'Political Science Teachers, Postsecondary', label: 'Political Science Teachers, Postsecondary'},
    {value: 'Political Scientists', label: 'Political Scientists'},
    {value: 'Postal Service Clerks', label: 'Postal Service Clerks'},
    {value: 'Postal Service Mail Carriers', label: 'Postal Service Mail Carriers'},
    {value: 'Postal Service Mail Sorters, Processors, and Processing Machine Operators', label: 'Postal Service Mail Sorters, Processors, and Processing Machine Operators'},
    {value: 'Postmasters and Mail Superintendents', label: 'Postmasters and Mail Superintendents'},
    {value: 'Power Distributors and Dispatchers', label: 'Power Distributors and Dispatchers'},
    {value: 'Power Plant Operators', label: 'Power Plant Operators'},
    {value: 'Precision Agriculture Technicians', label: 'Precision Agriculture Technicians'},
    {value: 'Precision Instrument and Equipment Repairers, All Other', label: 'Precision Instrument and Equipment Repairers, All Other'},
    {value: 'Prepress Technicians and Workers', label: 'Prepress Technicians and Workers'},
    {value: 'Preschool Teachers, Except Special Education', label: 'Preschool Teachers, Except Special Education'},
    {value: 'Pressers, Textile, Garment, and Related Materials', label: 'Pressers, Textile, Garment, and Related Materials'},
    {value: 'Printing Press Operators', label: 'Printing Press Operators'},
    {value: 'Private Detectives and Investigators', label: 'Private Detectives and Investigators'},
    {value: 'Probation Officers and Correctional Treatment Specialists', label: 'Probation Officers and Correctional Treatment Specialists'},
    {value: 'Procurement Clerks', label: 'Procurement Clerks'},
    {value: 'Producers', label: 'Producers'},
    {value: 'Production Occupations', label: 'Production Occupations'},
    {value: 'Production, Planning, and Expediting Clerks', label: 'Production, Planning, and Expediting Clerks'},
    {value: 'Proofreaders and Copy Markers', label: 'Proofreaders and Copy Markers'},
    {value: 'Property, Real Estate, and Community Association Managers', label: 'Property, Real Estate, and Community Association Managers'},
    {value: 'Prosthodontists', label: 'Prosthodontists'},
    {value: 'Protective Service Workers, All Other', label: 'Protective Service Workers, All Other'},
    {value: 'Psychiatric Aides', label: 'Psychiatric Aides'},
    {value: 'Psychiatric Technicians', label: 'Psychiatric Technicians'},
    {value: 'Psychiatrists', label: 'Psychiatrists'},
    {value: 'Psychologists, All Other', label: 'Psychologists, All Other'},
    {value: 'Psychology Teachers, Postsecondary', label: 'Psychology Teachers, Postsecondary'},
    {value: 'Public Address System and Other Announcers', label: 'Public Address System and Other Announcers'},
    {value: 'Public Relations and Fundraising Managers', label: 'Public Relations and Fundraising Managers'},
    {value: 'Public Relations Specialists', label: 'Public Relations Specialists'},
    {value: 'Pump Operators, Except Wellhead Pumpers', label: 'Pump Operators, Except Wellhead Pumpers'},
    {value: 'Purchasing Agents, Except Wholesale, Retail, and Farm Products', label: 'Purchasing Agents, Except Wholesale, Retail, and Farm Products'},
    {value: 'Purchasing Managers', label: 'Purchasing Managers'},
    {value: 'Quality Control Analysts', label: 'Quality Control Analysts'},
    {value: 'Quality Control Inspectors', label: 'Quality Control Inspectors'},
    {value: 'Quality Control Systems Managers', label: 'Quality Control Systems Managers'},
    {value: 'Radiation Therapists', label: 'Radiation Therapists'},
    {value: 'Radio and Television Announcers', label: 'Radio and Television Announcers'},
    {value: 'Radio Frequency Identification Device Specialists', label: 'Radio Frequency Identification Device Specialists'},
    {value: 'Radio Mechanics', label: 'Radio Mechanics'},
    {value: 'Radio Operators', label: 'Radio Operators'},
    {value: 'Radiologic Technicians', label: 'Radiologic Technicians'},
    {value: 'Radiologic Technologists', label: 'Radiologic Technologists'},
    {value: 'Rail Car Repairers', label: 'Rail Car Repairers'},
    {value: 'Rail Transportation Workers, All Other', label: 'Rail Transportation Workers, All Other'},
    {value: 'Rail Yard Engineers, Dinkey Operators, and Hostlers', label: 'Rail Yard Engineers, Dinkey Operators, and Hostlers'},
    {value: 'Rail-Track Laying and Maintenance Equipment Operators', label: 'Rail-Track Laying and Maintenance Equipment Operators'},
    {value: 'Railroad Brake, Signal, and Switch Operators', label: 'Railroad Brake, Signal, and Switch Operators'},
    {value: 'Railroad Conductors and Yardmasters', label: 'Railroad Conductors and Yardmasters'},
    {value: 'Railroad Occupations', label: 'Railroad Occupations'},
    {value: 'Railroad Switch Operators and Locomotive Firers', label: 'Railroad Switch Operators and Locomotive Firers'},
    {value: 'Railroad Yard Workers', label: 'Railroad Yard Workers'},
    {value: 'Range Managers', label: 'Range Managers'},
    {value: 'Real Estate Brokers', label: 'Real Estate Brokers'},
    {value: 'Real Estate Sales Agents', label: 'Real Estate Sales Agents'},
    {value: 'Receptionists and Information Clerks', label: 'Receptionists and Information Clerks'},
    {value: 'Recreation and Fitness Studies Teachers, Postsecondary', label: 'Recreation and Fitness Studies Teachers, Postsecondary'},
    {value: 'Recreation Workers', label: 'Recreation Workers'},
    {value: 'Recreational Therapists', label: 'Recreational Therapists'},
    {value: 'Recreational Vehicle Service Technicians', label: 'Recreational Vehicle Service Technicians'},
    {value: 'Recycling and Reclamation Workers', label: 'Recycling and Reclamation Workers'},
    {value: 'Recycling Coordinators', label: 'Recycling Coordinators'},
    {value: 'Refuse and Recyclable Material Collectors', label: 'Refuse and Recyclable Material Collectors'},
    {value: 'Registered Nurses', label: 'Registered Nurses'},
    {value: 'Rehabilitation Counselors', label: 'Rehabilitation Counselors'},
    {value: 'Reinforcing Iron and Rebar Workers', label: 'Reinforcing Iron and Rebar Workers'},
    {value: 'Religious Workers, All Other', label: 'Religious Workers, All Other'},
    {value: 'Remote Sensing Scientists and Technologists', label: 'Remote Sensing Scientists and Technologists'},
    {value: 'Reporters and Correspondents', label: 'Reporters and Correspondents'},
    {value: 'Reservation and Transportation Ticket Agents and Travel Clerks', label: 'Reservation and Transportation Ticket Agents and Travel Clerks'},
    {value: 'Residential Advisors', label: 'Residential Advisors'},
    {value: 'Respiratory Therapists', label: 'Respiratory Therapists'},
    {value: 'Respiratory Therapy Technicians', label: 'Respiratory Therapy Technicians'},
    {value: 'Retail Salespersons', label: 'Retail Salespersons'},
    {value: 'Riggers', label: 'Riggers'},
    {value: 'Rock Splitters, Quarry', label: 'Rock Splitters, Quarry'},
    {value: 'Rolling Machine Setters, Operators, and Tenders, Metal and Plastic', label: 'Rolling Machine Setters, Operators, and Tenders, Metal and Plastic'},
    {value: 'Roof Bolters, Mining', label: 'Roof Bolters, Mining'},
    {value: 'Roofers', label: 'Roofers'},
    {value: 'Rotary Drill Operators, Oil and Gas', label: 'Rotary Drill Operators, Oil and Gas'},
    {value: 'Rough Carpenters', label: 'Rough Carpenters'},
    {value: 'Roustabouts, Oil and Gas', label: 'Roustabouts, Oil and Gas'},
    {value: 'Sailors and Marine Oilers', label: 'Sailors and Marine Oilers'},
    {value: 'Sales and Related Occupations', label: 'Sales and Related Occupations'},
    {value: 'Sales Engineers', label: 'Sales Engineers'},
    {value: 'Sales Managers', label: 'Sales Managers'},
    {value: 'Sales Representatives, Services, All Other', label: 'Sales Representatives, Services, All Other'},
    {value: 'Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products', label: 'Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products'},
    {value: 'Sawing Machine Setters, Operators, and Tenders, Wood', label: 'Sawing Machine Setters, Operators, and Tenders, Wood'},
    {value: 'Secondary School Teachers, Except Special and Career/Technical Education', label: 'Secondary School Teachers, Except Special and Career/Technical Education'},
    {value: 'Secretaries and Administrative Assistants, Except Legal, Medical, and Executive', label: 'Secretaries and Administrative Assistants, Except Legal, Medical, and Executive'},
    {value: 'Securities, Commodities, and Financial Services Sales Agents', label: 'Securities, Commodities, and Financial Services Sales Agents'},
    {value: 'Security and Fire Alarm Systems Installers', label: 'Security and Fire Alarm Systems Installers'},
    {value: 'Security Guards', label: 'Security Guards'},
    {value: 'Segmental Pavers', label: 'Segmental Pavers'},
    {value: 'Self-Enrichment Education Teachers', label: 'Self-Enrichment Education Teachers'},
    {value: 'Semiconductor Processors', label: 'Semiconductor Processors'},
    {value: 'Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders', label: 'Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders'},
    {value: 'Septic Tank Servicers and Sewer Pipe Cleaners', label: 'Septic Tank Servicers and Sewer Pipe Cleaners'},
    {value: 'Service Unit Operators, Oil, Gas, and Mining', label: 'Service Unit Operators, Oil, Gas, and Mining'},
    {value: 'Set and Exhibit Designers', label: 'Set and Exhibit Designers'},
    {value: 'Sewing Machine Operators', label: 'Sewing Machine Operators'},
    {value: 'Shampooers', label: 'Shampooers'},
    {value: 'Sheet Metal Workers', label: 'Sheet Metal Workers'},
    {value: 'Sheriffs and Deputy Sheriffs', label: 'Sheriffs and Deputy Sheriffs'},
    {value: 'Ship and Boat Captains and Operators', label: 'Ship and Boat Captains and Operators'},
    {value: 'Ship Engineers', label: 'Ship Engineers'},
    {value: 'Ship, Boat, and Barge Mates', label: 'Ship, Boat, and Barge Mates'},
    {value: 'Shipwrights and Marine Oilers', label: 'Shipwrights and Marine Oilers'},
    {value: 'Shoe and Leather Workers and Repairers', label: 'Shoe and Leather Workers and Repairers'},
    {value: 'Signal and Track Switch Repairers', label: 'Signal and Track Switch Repairers'},
    {value: 'Skincare Specialists', label: 'Skincare Specialists'},
    {value: 'Slaughterers and Meat Packers', label: 'Slaughterers and Meat Packers'},
    {value: 'Slot Supervisors', label: 'Slot Supervisors'},
    {value: 'Social and Community Service Managers', label: 'Social and Community Service Managers'},
    {value: 'Social and Human Service Assistants', label: 'Social and Human Service Assistants'},
    {value: 'Social Science Research Assistants', label: 'Social Science Research Assistants'},
    {value: 'Social Sciences Teachers, Postsecondary, All Other', label: 'Social Sciences Teachers, Postsecondary, All Other'},
    {value: 'Social Scientists and Related Workers, All Other', label: 'Social Scientists and Related Workers, All Other'},
    {value: 'Social Work Teachers, Postsecondary', label: 'Social Work Teachers, Postsecondary'},
    {value: 'Social Workers, All Other', label: 'Social Workers, All Other'},
    {value: 'Sociologists', label: 'Sociologists'},
    {value: 'Sociology Teachers, Postsecondary', label: 'Sociology Teachers, Postsecondary'},
    {value: 'Software Developers, Applications', label: 'Software Developers, Applications'},
    {value: 'Software Developers, Systems Software', label: 'Software Developers, Systems Software'},
    {value: 'Software Quality Assurance Analysts and Testers', label: 'Software Quality Assurance Analysts and Testers'},
    {value: 'Soil and Plant Scientists', label: 'Soil and Plant Scientists'},
    {value: 'Soil and Water Conservationists', label: 'Soil and Water Conservationists'},
    {value: 'Solar Energy Installation Managers', label: 'Solar Energy Installation Managers'},
    {value: 'Solar Energy Systems Engineers', label: 'Solar Energy Systems Engineers'},
    {value: 'Solar Photovoltaic Installers', label: 'Solar Photovoltaic Installers'},
    {value: 'Solderers and Brazers', label: 'Solderers and Brazers'},
    {value: 'Sound Engineering Technicians', label: 'Sound Engineering Technicians'},
    {value: 'Special Education Teachers, All Other', label: 'Special Education Teachers, All Other'},
    {value: 'Special Education Teachers, Kindergarten and Elementary School', label: 'Special Education Teachers, Kindergarten and Elementary School'},
    {value: 'Special Education Teachers, Middle School', label: 'Special Education Teachers, Middle School'},
    {value: 'Special Education Teachers, Preschool', label: 'Special Education Teachers, Preschool'},
    {value: 'Special Education Teachers, Secondary School', label: 'Special Education Teachers, Secondary School'},
    {value: 'Speech-Language Pathologists', label: 'Speech-Language Pathologists'},
    {value: 'Sports Medicine Physicians', label: 'Sports Medicine Physicians'},
    {value: 'Stationary Engineers and Boiler Operators', label: 'Stationary Engineers and Boiler Operators'},
    {value: 'Statistical Assistants', label: 'Statistical Assistants'},
    {value: 'Statisticians', label: 'Statisticians'},
    {value: 'Steamfitters', label: 'Steamfitters'},
    {value: 'Stock Clerks and Order Fillers', label: 'Stock Clerks and Order Fillers'},
    {value: 'Stonemasons', label: 'Stonemasons'},
    {value: 'Storage and Distribution Managers', label: 'Storage and Distribution Managers'},
    {value: 'Structural Iron and Steel Workers', label: 'Structural Iron and Steel Workers'},
    {value: 'Structural Metal Fabricators and Fitters', label: 'Structural Metal Fabricators and Fitters'},
    {value: 'Substance Abuse and Behavioral Disorder Counselors', label: 'Substance Abuse and Behavioral Disorder Counselors'},
    {value: 'Subway and Streetcar Operators', label: 'Subway and Streetcar Operators'},
    {value: 'Surgeons', label: 'Surgeons'},
    {value: 'Surgical Technologists', label: 'Surgical Technologists'},
    {value: 'Survey Researchers', label: 'Survey Researchers'},
    {value: 'Surveying and Mapping Technicians', label: 'Surveying and Mapping Technicians'},
    {value: 'Surveyors', label: 'Surveyors'},
    {value: 'Switchboard Operators, Including Answering Service', label: 'Switchboard Operators, Including Answering Service'},
    {value: 'Tailors, Dressmakers, and Custom Sewers', label: 'Tailors, Dressmakers, and Custom Sewers'},
    {value: 'Talent Directors', label: 'Talent Directors'},
    {value: 'Tapers', label: 'Tapers'},
    {value: 'Tax Examiners and Collectors, and Revenue Agents', label: 'Tax Examiners and Collectors, and Revenue Agents'},
    {value: 'Tax Preparers', label: 'Tax Preparers'},
    {value: 'Taxi Drivers and Chauffeurs', label: 'Taxi Drivers and Chauffeurs'},
    {value: 'Teacher Assistants', label: 'Teacher Assistants'},
    {value: 'Teachers and Instructors, All Other', label: 'Teachers and Instructors, All Other'},
    {value: 'Team Assemblers', label: 'Team Assemblers'},
    {value: 'Technical Writers', label: 'Technical Writers'},
    {value: 'Telecommunications Engineering Specialists', label: 'Telecommunications Engineering Specialists'},
    {value: 'Telecommunications Equipment Installers and Repairers, Except Line Installers', label: 'Telecommunications Equipment Installers and Repairers, Except Line Installers'},
    {value: 'Telecommunications Line Installers and Repairers', label: 'Telecommunications Line Installers and Repairers'},
    {value: 'Telemarketers', label: 'Telemarketers'},
    {value: 'Telephone Operators', label: 'Telephone Operators'},
    {value: 'Tellers', label: 'Tellers'},
    {value: 'Terrazzo Workers and Finishers', label: 'Terrazzo Workers and Finishers'},
    {value: 'Textile Bleaching and Dyeing Machine Operators and Tenders', label: 'Textile Bleaching and Dyeing Machine Operators and Tenders'},
    {value: 'Textile Cutting Machine Setters, Operators, and Tenders', label: 'Textile Cutting Machine Setters, Operators, and Tenders'},
    {value: 'Textile Knitting and Weaving Machine Setters, Operators, and Tenders', label: 'Textile Knitting and Weaving Machine Setters, Operators, and Tenders'},
    {value: 'Textile Winding, Twisting, and Drawing Out Machine Setters, Operators, and Tenders', label: 'Textile Winding, Twisting, and Drawing Out Machine Setters, Operators, and Tenders'},
    {value: 'Textile, Apparel, and Furnishings Workers, All Other', label: 'Textile, Apparel, and Furnishings Workers, All Other'},
    {value: 'Therapists, All Other', label: 'Therapists, All Other'},
    {value: 'Tile and Marble Setters', label: 'Tile and Marble Setters'},
    {value: 'Timing Device Assemblers and Adjusters', label: 'Timing Device Assemblers and Adjusters'},
    {value: 'Tire Builders', label: 'Tire Builders'},
    {value: 'Tire Repairers and Changers', label: 'Tire Repairers and Changers'},
    {value: 'Title Examiners, Abstractors, and Searchers', label: 'Title Examiners, Abstractors, and Searchers'},
    {value: 'Tool and Die Makers', label: 'Tool and Die Makers'},
    {value: 'Tool Grinders, Filers, and Sharpeners', label: 'Tool Grinders, Filers, and Sharpeners'},
    {value: 'Tour Guides and Escorts', label: 'Tour Guides and Escorts'},
    {value: 'Traffic Technicians', label: 'Traffic Technicians'},
    {value: 'Training and Development Managers', label: 'Training and Development Managers'},
    {value: 'Training and Development Specialists', label: 'Training and Development Specialists'},
    {value: 'Transit and Railroad Police', label: 'Transit and Railroad Police'},
    {value: 'Transportation Attendants, Except Flight Attendants', label: 'Transportation Attendants, Except Flight Attendants'},
    {value: 'Transportation Inspectors', label: 'Transportation Inspectors'},
    {value: 'Transportation Managers', label: 'Transportation Managers'},
    {value: 'Transportation Security Screeners', label: 'Transportation Security Screeners'},
    {value: 'Transportation Workers, All Other', label: 'Transportation Workers, All Other'},
    {value: 'Transportation, Storage, and Distribution Managers', label: 'Transportation, Storage, and Distribution Managers'},
    {value: 'Travel Agents', label: 'Travel Agents'},
    {value: 'Travel Guides', label: 'Travel Guides'},
    {value: 'Treasurers and Controllers', label: 'Treasurers and Controllers'},
    {value: 'Tree Trimmers and Pruners', label: 'Tree Trimmers and Pruners'},
    {value: 'Umpires, Referees, and Other Sports Officials', label: 'Umpires, Referees, and Other Sports Officials'},
    {value: 'Upholsterers', label: 'Upholsterers'},
    {value: 'Urban and Regional Planners', label: 'Urban and Regional Planners'},
    {value: 'Urologists', label: 'Urologists'},
    {value: 'Ushers, Lobby Attendants, and Ticket Takers', label: 'Ushers, Lobby Attendants, and Ticket Takers'},
    {value: 'Validation Engineers', label: 'Validation Engineers'},
    {value: 'Veterinarians', label: 'Veterinarians'},
    {value: 'Veterinary Assistants and Laboratory Animal Caretakers', label: 'Veterinary Assistants and Laboratory Animal Caretakers'},
    {value: 'Veterinary Technologists and Technicians', label: 'Veterinary Technologists and Technicians'},
    {value: 'Video Game Designers', label: 'Video Game Designers'},
    {value: 'Vocational Education Teachers, Postsecondary', label: 'Vocational Education Teachers, Postsecondary'},
    {value: 'Waiters and Waitresses', label: 'Waiters and Waitresses'},
    {value: 'Watch Repairers', label: 'Watch Repairers'},
    {value: 'Water and Wastewater Treatment Plant and System Operators', label: 'Water and Wastewater Treatment Plant and System Operators'},
    {value: 'Water Resource Specialists', label: 'Water Resource Specialists'},
    {value: 'Water Transportation Occupations', label: 'Water Transportation Occupations'},
    {value: 'Water/Wastewater Engineers', label: 'Water/Wastewater Engineers'},
    {value: 'Weatherization Installers and Technicians', label: 'Weatherization Installers and Technicians'},
    {value: 'Web Administrators', label: 'Web Administrators'},
    {value: 'Web Developers', label: 'Web Developers'},
    {value: 'Weighers, Measurers, Checkers, and Samplers, Recordkeeping', label: 'Weighers, Measurers, Checkers, and Samplers, Recordkeeping'},
    {value: 'Welders, Cutters, Solderers, and Brazers', label: 'Welders, Cutters, Solderers, and Brazers'},
    {value: 'Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders', label: 'Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders'},
    {value: 'Wellhead Pumpers', label: 'Wellhead Pumpers'},
    {value: 'Wholesale and Retail Buyers, Except Farm Products', label: 'Wholesale and Retail Buyers, Except Farm Products'},
    {value: 'Wind Energy Engineers', label: 'Wind Energy Engineers'},
    {value: 'Wind Turbine Service Technicians', label: 'Wind Turbine Service Technicians'},
    {value: 'Woodworkers, All Other', label: 'Woodworkers, All Other'},
    {value: 'Woodworking Machine Setters, Operators, and Tenders, Except Sawing', label: 'Woodworking Machine Setters, Operators, and Tenders, Except Sawing'},
    {value: 'Word Processors and Typists', label: 'Word Processors and Typists'},
    {value: 'Writers and Authors', label: 'Writers and Authors'},
    {value: 'Zoologists and Wildlife Biologists', label: 'Zoologists and Wildlife Biologists'},
    {value: 'Zoology Teachers, Postsecondary', label: 'Zoology Teachers, Postsecondary'},
    {value: 'Other', label: 'Other'},
]