import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const SaveLLMAssistantDB = async (data) => {
    const apiData = await API.graphql({ 
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: mutations.createLLMAssistant, // change this after we know DB schema
        variables: {
            input: data,
        }
    });

    return apiData.data.createLLMAssistant.id;
}

export default SaveLLMAssistantDB;