import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PersonaSidebar from "../../utils/navigation/PersonaSidebar";
import NewPersonaWorkflow from './NewPersonaWorkflow';
import Persona from './Persona';
import Personas from './Personas';
import GetUser from '../../utils/users/GetUser';
import GetSegments from '../../utils/personas/GetSegments';
import GetPersonasBySegmentID from '../../utils/personas/GetPersonasBySegmentID';

const PersonaDashboard = (props) => {
    console.log(props.MRAssistantID)

    const navigate = useNavigate();

    const [state, setState] = useState({
        user: null,
        isLoading: true,
        segments: [],
        personas: [],
        selectedSegment: [],
        selectedPersona: [],
        selectedPersonas: [],
    });

    useEffect(() => {
        fetchData();
    }, [])  

    const fetchData = async () => {
        setState(prevState => ({ ...prevState, isLoading: true }));
    
        const fetchedUser = await GetUser();    
        const fetchedSegments = await fetchSegments(fetchedUser);
        const fetchedPersonas = await fetchPersonas(fetchedUser, fetchedSegments);
    
        console.log("Loading: ", state.isLoading)
        console.log("User: ", fetchedUser)
        console.log("Segments: ", fetchedSegments);
        console.log("Personas: ", fetchedPersonas);
    
        setState(prevState => ({
            ...prevState,
            segments: fetchedSegments,
            personas: fetchedPersonas,
            selectedPersonas: fetchedPersonas,
            isLoading: false
        }));
    }

    async function fetchSegments(user) {
        const segments = await GetSegments(user);
        return segments;
    }

    async function fetchPersonas(user, segments) {
        let allPersonas = [];
    
        for (let segment of segments) {
            const personas = await GetPersonasBySegmentID(segment.id);
            personas.map((persona) => {
                persona.segment = segment.name;
            });
            allPersonas = [...allPersonas, ...personas];
        }
    
        return allPersonas;
    }

    const createNewPersona = async(data) => {
        setState(prevState => ({ ...prevState, selectedSegment: data.segmentID }));
        navigate ("/segments/new");
        fetchData();
    }   

    function updateMenu(segment) {
        const selectedPersonas = state.personas.filter(persona => persona.segmentID === segment.id);
        setState(prevState => ({ ...prevState, selectedSegment: segment, selectedPersonas: selectedPersonas}));
        navigate("/segments");
    }

    function updateSubmenu(persona) {
        setState(prevState => ({ ...prevState, selectedPersona: persona }));
        navigate("/segments/" + persona.id);
    }

    return (
        <div className="flex flex-row w-full min-h-screen h-full bg-slate-50">                
            <PersonaSidebar selectedMenuItem={state.selectedSegment} menuItems={state.segments} submenuItems={state.personas} updateMenu={updateMenu} updateSubmenu={updateSubmenu} /> 
            <Routes>
                <Route path="/new" element={<NewPersonaWorkflow createNewPersona={createNewPersona} MRAssistantID={props.MRAssistantID}/>} />
                <Route path="/:personaID/*" element={<Persona persona={state.selectedPersona} />} />
                <Route path="/*" element={state.isLoading ? <div className="flex flex-row w-full justify-center m-4"><h3 className="text-center">Loading...</h3></div>  : <Personas personas={state.selectedPersonas} />}/>
            </Routes>
        </div>   
    );
}

export default PersonaDashboard;