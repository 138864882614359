import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetSources = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    const apiData = await API.graphql({ 
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: queries.listSources, // change this after we know DB schema
        variables: {
            filter: {
                owner: {eq: userInfo.attributes.email}
            }
        }
    });

    return apiData.data.listSources.items;
}

export default GetSources;