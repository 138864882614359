import { API } from "aws-amplify";
//import * as queries from '../../graphql/queries';

async function getJobStatusWithBackoff(jobId) {
    const path = '/jobs/' + jobId;
    //let attempts = 0;
    let response;
    let delay = 1000;

    while (true) {
        response = await API.get('llmjobdevapi', path);
        console.log(response);

        if (response.status === 'completed') {
            //console.log('Job completed!', response);
            break;
        } else {
            console.log('Job not yet completed');
            //attempts++;
            //let delay = Math.min(Math.pow(2, attempts) * 1000, 30000);
            //let delay = 1000;
            //console.log(`Retrying in ${delay}ms...`);
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }
    console.log('Job completed!', response);

    return response;
}

async function getJobStatus(jobId) {
    const path = '/jobs/' + jobId;
    let time = 0;
    let response;

    while (true) {
        if (time > 120000) {
            console.log('Job taking too long');
            response = {status: 'failed'};
            break;
        }
        response = await API.get('llmjobdevapi', path);
        //console.log(response);

        if (response.status === 'completed') {
            console.log('Job completed!', response);
            break;
        } else {
            //console.log('Job not yet completed');
            
            let delay = 1000;
            // console.log(`Retrying in ${delay}ms...`);
            time += delay;
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }

    console.log("Time taken: ", time/1000, " seconds");

    return response;

}

export const CreateMessageLLM = async (data) => {
    const model = data.model;
    const temperature = data.temperature;
    const mode = data.mode;
    const dataToLLM = data.llm_data;
    let jobID;
    let attempts = 0;
    while (attempts < 3) {
        try {
            const response = await API.post('llmjobdevapi', '/jobs', {
                body : {
                    input : {
                        model: model,
                        temperature: temperature,
                        mode: mode,
                        data: dataToLLM
                    }
                }
            });
            jobID = response.jobID;
            console.log(response);
            break;
        } catch (error) {
            console.log(error);
            attempts++;
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    if (!jobID) {
        console.log('Failed to create message');
        return{ status: 'failed'};
    }

    // messages
    //let message_response = await getJobStatusWithBackoff(jobID);
    let message_response = await getJobStatus(jobID);
    if (message_response.status === 'failed') {
        console.log("Job failed");
        return({ status: 'failed' });
    }
    //console.log(response);
    let messages_response_str = message_response.result;
    console.log(messages_response_str);
    if (messages_response_str === null || messages_response_str === undefined) {
        // list messages
        await API.post('llmjobdevapi', '/jobs', {
            body : {
                input : {
                    model: model,
                    temperature: temperature,
                    mode: mode,
                    data: {
                        assistantMode: "listMessages",
                        threadID: dataToLLM.threadID,
                    }
                }
            }
        }).then(response => {
            jobID = response.jobID;
            console.log(response);
        }).catch(error => {
            console.log(error);
        });

        message_response = await getJobStatusWithBackoff(jobID);
        if (message_response.status === 'failed') {
            console.log("Job failed");
            return({error: 'Job failed'});
        }
        messages_response_str = message_response.result;
        console.log(messages_response_str);
        if (messages_response_str === null || messages_response_str === undefined) {
            return({error: 'No messages found'});
        }
    }
    
    const messages_response = JSON.parse(messages_response_str).messages;
    messages_response.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    console.log(messages_response);
    let result = [];
    for (let i = 0; i < messages_response.length; i++) {
        result.push({messageID: messages_response[i].id, 
            role: messages_response[i].role, 
            message: messages_response[i].content[0].text.value,
            LLM_created: messages_response[i].created_at,
        });
    }

    return result;
}