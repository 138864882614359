// chat message components
import UserMessage from './UserMessage';
import AgentMessage from './AgentMessage';

// loading component
//import  CircularProgress from '@mui/material/CircularProgress';
import Loading from '../Loading';

const ChatWindow = (props) => {
    if (Array.isArray(props.conversations) && props.conversations.length === 0) {
        return (
            <div className="flex flex-row w-full overflow-y-auto text-slate-900" >
            <div className="w-full">  
        <div className='bg-slate-50 px-4 py-4 items-center'>
            <h5 className="text-left align-middle"> Current Chat </h5>
        </div>
        </div>
        </div>
        );
    }

    return(
        <div className="flex flex-row w-full overflow-y-auto text-slate-900" >
            <div className="w-full">
                <div className='bg-slate-50 px-4 py-4 items-center'>
                    <h5 className="text-left align-middle"> Current Chat </h5>
                </div>
                
                {props.conversations.map((item) => (
                    item.role === "user" ? 
                            <UserMessage key={item.id} message={item.message} /> : 
                            <AgentMessage key={item.id} message={item.message} userFeedback={props.userFeedback} 
                                      copiedMessage={props.copiedMessage} id={item.id} sources={item.sources} like={item.like} dislike={item.dislike}/>                      
                ))}
                {props.isLoading && (
                    <div>
                        <Loading />
                    </div>
                ) }
            </div>               
        </div>     
    );
};

export default ChatWindow;