import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link, NavLink, useNavigate } from "react-router-dom"
//import { Button } from '@aws-amplify/ui-react'
import MenuIcon from '@mui/icons-material/Menu';
import GetUser from '../../utils/users/GetUser';

const navigation = [
  {name: 'Segments', href: '/segments'},
  {name: 'Projects', href: '/projects'},
]

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar(props) {  

  const [user, setUser] = useState(null);
  const [personaOrArchetype, setPersonaOrArchetype] = useState("Personas");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await GetUser();
      if (user) {
        setUser(user);
        if (user.attributes.email.includes("suntory")) {
          setPersonaOrArchetype("Archetypes");
        }
      }
    }
    fetchUser();
  },[]);

  return (
    <>
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 justify-center m-2">
                  <Link to="/">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src={props.logo}
                    alt={props.altlogo}
                  />
                  </Link>
                  <Link to="/">
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src={props.logo}
                    alt={props.altlogo}
                  />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">

                {/* Panels */}
                <Menu as="div" className="relative mt-2 ml-4">
                        <div>
                          <Menu.Button className="relative flex max-w-xs w-32 px-2 py-2 justify-center rounded-full bg-gray-800 text-sm text-white hover:ring-1 hover:ring-white focus:outline-1 focus:ring-1 focus:ring-white focus:ring-offset-0 focus:ring-offset-gray-800">
                            <span className="absolute" />
                            <span className="sr-only">Open Panels Menu</span>
                            <span className="">Panels</span>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-0 right-0 z-50 mt-2 py-0 w-36 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item key="New Panel">                                            
                              {({active}) => (
                                <button onClick={() => navigate("/panels/new")}
                                  className={classNames(active ? 'flex px-4 py-2 bg-gray-200 no-underline rounded-md justify-start' : '',
                                  'flex block px-4 py-2 w-full text-sm text-gray-700 no-underline rounded-md justify-start')}
                                >
                                  New Panel
                                </button>
                              )}                                                                            
                            </Menu.Item>       
                            <Menu.Item key="View Panels">                                            
                              {({active}) => (
                                <button onClick={() => navigate("/panels")}
                                className={classNames(active ? 'flex px-4 py-2 bg-gray-200 no-underline rounded-md justify-start' : '',
                                'flex block px-4 py-2 w-full text-sm text-gray-700 no-underline rounded-md justify-start')}
                              >
                                  View Panels
                                </button>
                              )}                                                                            
                            </Menu.Item>                      
                          </Menu.Items>
                        </Transition>
                </Menu>

                {/* Segments */}
                <Menu as="div" className="relative mt-2 mr-4">
                        <div>
                          <Menu.Button className="relative flex max-w-xs w-32 px-2 py-2 justify-center rounded-full bg-gray-800 text-sm text-white hover:ring-1 hover:ring-white focus:outline-1 focus:ring-1 focus:ring-white focus:ring-offset-0 focus:ring-offset-gray-800">
                            <span className="absolute" />
                            <span className="sr-only">Open Segments Menu</span>
                            <span className="">Segments</span>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-0 right-0 z-50 mt-2 py-0 w-48 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5">
                            <Menu.Item key="New Segment">                                            
                              {({active}) => (
                                <button onClick={() => navigate("/segments/new")}
                                className={classNames(active ? 'flex px-4 py-2 bg-gray-200 w-full no-underline rounded-md justify-start' : '',
                                'flex block px-4 py-2 w-full text-sm text-gray-700 no-underline rounded-md justify-start')}

                                >
                                  New Segment
                                </button>
                              )}                                                                            
                            </Menu.Item>       
                            <Menu.Item key="View Segments">                                            
                              {({active}) => (
                                <button onClick={() => navigate("/segments")}
                                className={classNames(active ? 'flex px-4 py-2 bg-gray-200 w-full no-underline rounded-md justify-start' : '',
                                'flex block px-4 py-2 w-full text-sm text-gray-700 no-underline rounded-md justify-start')}
                                >
                                  View Segments
                                </button>
                              )}                                                                            
                            </Menu.Item>                      
                          </Menu.Items>
                        </Transition>
                </Menu>

                {/* Projects */}
                <Menu as="div" className="relative mt-2 mr-4">
                        <div>
                          <Menu.Button className="relative flex max-w-xs w-32 px-2 py-2 justify-center rounded-full bg-gray-800 text-sm text-white hover:ring-1 hover:ring-white focus:outline-1 focus:ring-1 focus:ring-white focus:ring-offset-0 focus:ring-offset-gray-800">
                            <span className="absolute" />
                            <span className="sr-only">Open Projects Menu</span>
                            <span className="">Projects</span>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-0 right-0 z-50 mt-2 py-0 w-36 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item key="New Project">                                            
                              {({active}) => (
                                <button onClick={() => navigate("/projects/new")}
                                className={classNames(active ? 'flex px-4 py-2 bg-gray-200 w-full no-underline rounded-md justify-start' : '',
                                'flex block px-4 py-2 w-full text-sm text-gray-700 no-underline rounded-md justify-start')}
                                >
                                  New Project
                                </button>
                              )}                                                                            
                            </Menu.Item>       
                            <Menu.Item key="View Projects">                                            
                              {({active}) => (
                                <button onClick={() => navigate("/projects")}
                                className={classNames(active ? 'flex px-4 py-2 bg-gray-200 w-full no-underline rounded-md justify-start' : '',
                                'flex block px-4 py-2 w-full text-sm text-gray-700 no-underline rounded-md justify-start')}
                                >
                                  View Projects
                                </button>
                              )}                                                                            
                            </Menu.Item>                      
                          </Menu.Items>
                        </Transition>
                </Menu>
                
                </div>

                {/*
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}                        
                        className={({isActive}) => {
                            return 'no-underline rounded-md px-3 py-2 text-sm font-medium ' + 
                            (isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white')
                        }}                        
                      >
                        {item.name}
                      </NavLink>
                    ))}
                      */}
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  type="button"
                  className="hidden rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Right Menu - user profile */}
                <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <MenuIcon className="text-white"/>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item key="Sign Out">                                            
                              {({active}) => (
                                <button onClick={props.signOut}
                                  className={classNames(active ? 'bg-gray-100 no-underline' : '',
                                  'block px-4 py-2 text-sm text-gray-700 no-underline')}
                                >
                                  Sign Out
                                </button>
                              )}                                                                            
                            </Menu.Item>                            
                          </Menu.Items>
                        </Transition>
                </Menu>
                {/*<Button onClick={props.signOut} color="#fff" >Sign Out</Button>*/}
                
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <NavLink
                key={item.name}
                to={item.href}                        
                className={({isActive}) => {
                    return 'no-underline rounded-md px-3 py-2 text-sm font-medium' + 
                    (isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white')
                }}                        
              >
                {item.name}
              </NavLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    <div className="bg-gray-300 h-full">
        {props.children}
    </div>
    </>
  );
}
