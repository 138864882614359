import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, set, get } from  'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Auth, API, Storage } from 'aws-amplify';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { FormatColorReset, Save } from '@mui/icons-material';
import * as personaoptions from '../../utils/personas/PersonaOptions';
import Loading from '../../utils/Loading';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import GetPanels from '../../utils/panels/GetPanels';
import GetSources from '../../utils/panels/GetSources';
import SaveSources from '../../utils/panels/SaveSources';
import SavePanels from '../../utils/panels/SavePanels';
import SaveProfiles from '../../utils/panels/SaveProfiles';
import SavePanelists from '../../utils/panels/SavePanelists';
import GetProfiles from '../../utils/panels/GetProfiles';
import GetProfilesBySourceId from '../../utils/panels/GetProfilesBySourceId';
import Profiles from './Profiles';
import Papa from 'papaparse';
import SimpleLLMCall from '../../utils/llmcall/SimpleLLMCall';

const model="gpt-3.5-turbo";

const PanelBrief = (props) => {

    console.log("Entered Project Brief");
    
    console.log("Sources: ", props.sources);

    const navigate = useNavigate();
    const { register, handleSubmit, control, reset, setValue } = useForm();
  
    const [selectedPersonaType, setSelectedPersonaType] = useState('Consumer');
    const [selectedResearchType, setSelectedResearchType] = useState('');
    const [selectedProcess, setSelectedProcess] = useState('');
    const [selectedDataSource, setSelectedDataSource] = useState('');
    const [selectedProfileIDs, setSelectedProfileIDs] = useState([]);
    const [selectedBackstory, setSelectedBackstory] = useState('');

    const [sources, setSources] = useState(props.sources);
    const [user, setUser] = useState(null); // user is null if not logged in
    const [dataSourceOptions, setDataSourceOptions] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const backstoriesOptions = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
    ]

    const addNewSource = (newSource) => {
        const options = dataSourceOptions.slice(0, -1);
        console.log(newSource)
        setDataSourceOptions([...options, newSource, { value: 'Add New', label: 'Add New', id: '0' }]);
    }

    async function fetchProfilesBySourceId(sourceID) {
        //console.log(sourceID);
        setIsLoading(true);
        const profiles = await GetProfilesBySourceId(sourceID);
        setProfiles(profiles);
        setIsLoading(false);
        //console.log(profiles);
    }

    // set data source options
    useEffect(() => {
        const sourceOptions = props.sources.map((source) => {
            return { value: source.name, label: source.name, id: source.id }
        });
        setDataSourceOptions([...sourceOptions, { value: 'Add New', label: 'Add New', id: '0' }]);
    }, [props.sources]);
    
    // grab the profiles and sources from the database
    useEffect(() => {
        // get authenticated user
        const fetchUser = async() => {
            try {
                const amplifyUser = await Auth.currentAuthenticatedUser();
                setUser(amplifyUser);
                //console.log(amplifyUser);

            } catch (error) {
                setUser(null);
                console.log(error);
            }
        }   

        fetchUser();

    }, [])

    const onDrop = useCallback((acceptedFiles) => {

        if (acceptedFiles.length > 0) {
            props.createProfiles(acceptedFiles);
        }
        //setIsLoading(true);

        /*
                
                const profileUX = updatedProfiles.map((profile) => {      
                    
                    return {
                        id: profile.id,
                        sourceName: nameDes,
                        sourceID: sourceID, 
                        name: profile.name, 
                        age: profile.age,
                        gender: profile.gender,
                        race: profile.race,
                        education: profile.education,
                        occupation: profile.occupation, 
                        maritalStatus: profile.marital_status, 
                        children: profile.children, 
                        income: profile.income, 
                        netWorth: profile.net_worth, 
                        city: profile.city, 
                        metro: profile.metro,
                        region: profile.region,
                        state: profile.state                    
                    }
                })
                // set selected profiles
                console.log(profileUX)
                setProfiles(profileUX);

                // set sources
                setSources([...sources, {id: sourceID, name: nameDes, description: nameDes, owner: owner}])

                // update the display
                addNewSource({ name: nameDes, label: nameDes, id: sourceID});

                // set the selected source
                setSelectedDataSource({ name: nameDes, label: nameDes, id: sourceID});

                // change the controller/select value
                setValue("dataSource", { name: nameDes, label: nameDes, id: sourceID});

                // get the profiles for this new data source
                setProfiles(fetchProfilesBySourceId(sourceID));

                setIsLoading(false);
                */
            
        }, []);
  

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
        } = useDropzone({
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'], 
            'application/zip': ['.zip']    
        },
        onDrop: onDrop,

    });

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
            {errors.map(e => (
                <li key={e.code}>{e.message}</li>
            ))}
            </ul>
        </li>
    ));
       
    const handleCancel = () => {
        reset();
        navigate('/');
    };

    const handleProfileSelectionChange = (newSelection) => {
        //console.log(newSelection);
        setSelectedProfileIDs(newSelection);
    }

    const onSubmit = async (data) => {

        // get the selected profiles
        const selectedProfiles = profiles.filter((profile) => {
            return selectedProfileIDs.includes(profile.id);
        });

        // add selectedProfiles to data
        data.profiles = selectedProfiles;
        console.log(data);
        props.createPanel(data);
        
    }

    const handleDataSourceChange = (selectedOption) => {
        setSelectedDataSource(selectedOption);
        console.log(selectedOption);
        if (selectedOption.value !== 'Add New') {
            fetchProfilesBySourceId(selectedOption.id);
            console.log(profiles);
        }
    }

    const handleBackstorySelection = (selectedOption) => {
        setSelectedBackstory(selectedOption);
        console.log(selectedOption);
    }

    const columns = [
        {field: 'id', headerName: 'ID'},
        {field: 'sourceID', headerName: 'Source ID'},
        {field: 'name', headerName: 'Name', width: 100},
        {field: 'age', headerName: 'Age', width: 50, type: 'number'},
        {field: 'age_range', headerName: 'Age Range', width: 100},
        {field: 'gender', headerName: 'Gender', width: 100},
        {field: 'race', headerName: 'Race', width: 100},
        {field: 'education', headerName: 'Education', width: 200},
        {field: 'employment_status', headerName: 'Employment Status', width: 200},
        {field: 'occupation', headerName: 'Occupation', width: 250},
        {field: 'marital_status', headerName: 'Marital Status', width: 150},
        {field: 'household_type', headerName: 'Household Type', width: 150},
        {field: 'household_size', headerName: 'Household Size', width: 150},
        {field: 'children', headerName: 'Children', width: 75},
        {
            field: 'income',
            headerName: 'Income',
            width: 150,
            type: 'number',
            valueFormatter: (params) => {
                const valueFormatted = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0, // No decimal places
                    maximumFractionDigits: 0, // No decimal places
                }).format(params.value);
                return valueFormatted;
            },
        },
        {
            field: 'net_worth',
            headerName: 'Net Worth',
            width: 150,
            type: 'number',
            valueFormatter: (params) => {
                const valueFormatted = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0, // No decimal places
                    maximumFractionDigits: 0, // No decimal places
                }).format(params.value);
                return valueFormatted;
            },
        },
        {field: 'asset_range', headerName: 'Asset Range', width: 200},
        {field: 'asset_types', headerName: 'Asset Types', width: 250},
        {field: 'income_range', headerName: 'Income Range', width: 200},
        {field: 'networth_range', headerName: 'Net Worth Range', width: 200},
        {field: 'city', headerName: 'City', width: 150},
        {field: 'metro', headerName: 'Metro', width: 150},
        {field: 'state', headerName: 'State', width: 150},
        {field: 'region', headerName: 'Region', width: 150},
        {field: 'country', headerName: 'Country', width: 150},
        {field: 'zipcode', headerName: 'Zipcode', width: 150},
        {field: 'backstory', headerName: 'Backstory', width: 500},
        {field: 'values', headerName: 'Values', width: 500},
        {field: 'lifestyle', headerName: 'Lifestyle', width: 500},
        {field: 'attitudes', headerName: 'Attitudes', width: 500},
        {field: 'interests', headerName: 'Interests', width: 500},
        {field: 'personality', headerName: 'Personality', width: 500},
        {field: 'social', headerName: 'Social', width: 500},
    ];

    return (
        <div className="flex flex-col w-full h-full content-start bg-slate-50">
            <div className="flex flex-col w-full h-full text-left">
                    <div className="px-4 py-4 border-b-2">
                        <h5 className="text-black">
                            Create New Panel
                        </h5>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="flex items-center w-full">
                            <label className="m-4 w-48">Panel Source</label>
                            <Controller
                                name="dataSource"
                                control={control}
                                defaultValue=""
                                render={({ value, field }) => (
                                    <Select {...field} className="w-72 min-w-max m-4"
                                        options={dataSourceOptions}                                        
                                        onChange={selectedOption => {
                                                handleDataSourceChange(selectedOption)
                                                field.onChange(selectedOption);
                                            }
                                        }
                                        value={dataSourceOptions.find((c) => c.value === value)}
                                    />)}
                            />
                        </div>

                        {selectedDataSource.value !== 'Add New' && selectedDataSource.value !== null && (
                            <div className="flex items-center w-full">
                                <label className="m-4 w-48">Panel Name</label>
                                <Controller
                                    name="name"
                                    control={control} // control comes from useForm()
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <input 
                                            id="name"
                                            type="text"
                                            value={field.value}
                                            required
                                            onChange={(e) => {
                                                field.onChange(e);
                                                //setSelectedName(e.target.value);
                                            }}
                                            className="content-center rounded-lg border-1 w-72 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                        />
                                    )}
                                />
                            </div>
                        )}

                        {selectedDataSource.value !== 'Add New' && selectedDataSource.value !== null && (
                            <div className="flex items-center w-full">
                                <label className="m-4 w-48">Panel Description</label>
                                <Controller
                                    name="description"
                                    control={control} // control comes from useForm()
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <input 
                                            id="description"
                                            type="text"
                                            value={field.value}
                                            required
                                            onChange={(e) => {
                                                field.onChange(e);
                                                //setSelectedName(e.target.value);
                                            }}
                                            className="content-center rounded-lg border-1 w-208 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                        />
                                    )}
                                />
                            </div>
                        )}

                        {selectedDataSource.value !== 'Add New' && selectedDataSource.value !== null && (
                            <div className="flex items-center w-full">
                                <label className="m-4 w-48">Create Backstories?</label>
                                <Controller
                                    name="backstory"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field, value }) => (
                                        <Select {...field} 
                                            options={backstoriesOptions}                                        
                                            onChange={selectedOption => {
                                                    field.onChange(selectedOption);
                                                    handleBackstorySelection(selectedOption);
                                                }
                                        }
                                        value={backstoriesOptions.find((c) => c.value === value)}
                                        className="content-center rounded-lg border-1 w-48 m-4 bg-slate-0 dark:bg-slate-900 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                                    />)}
                                />
                            </div>
                        )}

                        {selectedDataSource.value === 'Add New' && (
                            <div className="flex items-center w-full justify-center p-4 border-t-2 border-b-2 bg-white">
                            <section className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <p>Drag 'n' drop your research file here, or click here to select the file</p>
                            <p>1. Interview transcripts should be in a ZIP archive format, containing the interview transcripts in PDF format, and a CSV file with a row for each interviewee. The columns should reference fields like First Name, Last Name, Gender, Age, Income, etc. with the last field titled "Interview_File" with the name of the interview transcript PDF for that interviewee.</p>
                            <p>2. Survey responses should be in a single CSV file, with a row for each respondent. The columns should reference fields like First Name, Last Name, Gender, Age, Race, Education, Occupation, Income, Assets, Networth, etc.</p>
                            </div>
                            {/*
                            <aside className="w-full p-4">
                              <h4>Selected files</h4>
                              <ul>{acceptedFileItems}</ul>
                              <h4>Rejected files</h4>
                              <ul>{fileRejectionItems}</ul>
                        </aside>
                        */}
                          </section>
                          </div>
                        )}

                        {selectedDataSource !== '' && selectedDataSource.value !== 'Add New' && (
                        <div className="flex flex-row w-10/12 bg-slate-50 m-4 overflow-x-auto">
                                    <DataGridPro
                                        slots = {{ toolbar: GridToolbar }}
                                        autoHeight
                                        loading={isLoading}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 5 },
                                            },
                                            columns: {
                                            columnVisibilityModel: {
                                                id: false,
                                                sourceID: false,
                                                name: false,
                                            },
                                            },
                                        }}

                                        sx={{   
                                            fontWeight: 'fontWeightLight',
                                            bgcolor: 'white',
                                            boxShadow: 2, 
                                            borderRadius: 4,
                                            border: 1,
                                            px: 2,
                                        }}
                                        rows={profiles}
                                        columns={columns}
                                        pagination
                                        pageSize={5}
                                        pageSizeOptions={[5, 10, 20, 50, 100]}
                                        checkboxSelection
                                        onRowSelectionModelChange={handleProfileSelectionChange}                        
                                    />
                                </div>
                        )}

                        <div className="flex flex-row w-10/12 m-4">
                            <div className="flex w-full justify-start">
                                <button type="button" onClick={handleCancel} className="px-2 py-2 w-24 text-sm text-blue-600 bg-slate-0 font-semibold rounded-md border-1 border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                                    Cancel
                                </button>
                            </div>
                            <div className="flex w-full justify-end">
                                <button type="submit"  className="px-2 py-2 w-24 text-sm text-blue-600 bg-slate-0 font-semibold rounded-md border-1 border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                                    Create
                                </button>
                            </div>
                        </div>
                    </form>
            </div>
        </div>
  );
}

export default PanelBrief;


