import { useEffect, useState } from 'react';
//import { ProgressBar, MarkerBar, DeltaBar, RangeBar, CategoryBar, Card, Flex, Text } from "@tremor/react";
import GetConceptAnswersByProjectBriefID from '../../utils/projects/GetConceptAnswersByProjectBriefID';
import Loading from '../../utils/Loading';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';

const ProjectInterviewResultsForConcepts = (props) => {
    console.log("in ProjectResultsForConcepts: ", props)

    const [loading, setLoading] = useState(true);
    const objective = props.objective;
    const results = props.results;
    const respondents = props.respondents;
    const concepts = props.concepts;
    const questions = props.questions;

    console.log("results");
    console.log(results)

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading])    

    return (
        <div className="flex flex-col w-full m-4 bg-slate-50 overflow-y-auto">
            {results.map((conceptResults, i) => {
                console.log("conceptResults");
                console.log(conceptResults);

                const rows=[];
                
                for (let j=0; j<conceptResults.length; j++) {
                    let row={};
                    row.question = conceptResults[j][0].question;
                    row.id = j;
                    for (let k=0; k<conceptResults[j].length; k++) {
                        const respondent = conceptResults[j][k].name;
                        console.log("row.question", row.question);                        
                        row[`answer${respondent}`] = conceptResults[j][k].answer;
                    }
                    rows.push(row);
                }

                console.log("rows");
                console.log(rows);

                // Transform data into rows for DataGrid
                /*const rows = conceptResults[0].map((result, j) => {
                    const row = { id: j, question: result.question };
                    conceptResults.forEach((questionResults, k) => {
                        if (questionResults[j].question === row.question) {
                            row[`answer${k}`] = questionResults[j].answer;
                        }
                    });
                    return row;
                });*/
            
                // Create columns for DataGrid
                const columns = [
                    {   field: 'question', 
                        headerName: 'Question', 
                        type: 'string',
                        width: 300, 
                        align: 'left',
                        renderCell: (params) => (
                            <div style={{ textAlign: 'left' }}>
                                {params.value}
                            </div>
                        ),
                    },
                    ...respondents.map((r, j) => ({
                        field: `answer${r.name}`,
                        headerName: r.name,
                        width: 600,
                        align: 'left',
                        renderCell: (params) => (
                            <div style={{ textAlign: 'left' }}>
                                {params.value}
                            </div>
                        ),
                    })),
                ];
                console.log("columns");
                console.log(columns);
            
                return (
                    <div key={i} className="flex flex-row flex-wrap w-full bg-slate-50 mt-4 mb-4 overflow-x-auto">

                        <div className="flex flex-row bg-slate-500 text-left w-full text-white px-4 py-2 mb-4 rounded-xl">
                            <h4>{concepts[i].description}</h4>
                        </div>

                        <div className="flex flex-row bg-slate-50 w-full">
                            <DataGridPro
                                autoHeight
                                getRowHeight={() => 'auto'}
                                slots = {{ toolbar: GridToolbar }}
                                sx={{   
                                    fontWeight: 'fontWeightLight',
                                    bgcolor: 'white',
                                    boxShadow: 2, 
                                    borderRadius: 4,
                                    border: 1,
                                    px: 2,
                                }}
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 20, 50, 100]}
                                //checkboxSelection
                            />
                        </div>
                        </div>
                );
            })}
            
            {loading && <Loading />}
        </div>
    );
}

export default ProjectInterviewResultsForConcepts;
/*
    return (
        <div className="flex flex-col w-full justify-center bg-slate-50 overflow-y-auto">
            {results.map((conceptResults, i) => (
                <div key={i} className="m-4 overflow-x-auto overflow-y-auto">
                    <div className="bg-slate-500 text-center text-white p-4">
                    <h4>{concepts[i].description}</h4>
                    </div>
                    <div className="bg-slate-50 m-0">
                    <table className="table-auto justify-center w-full mb-4 border-slate-200 rounded-xl">
                        <thead className="bg-slate-200">
                            <tr className="">
                                <th className=""></th>
                                {respondents.map((r, j) => (
                                    <th key={j} className="p-2 border-2 border-slate-50">{r.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {conceptResults[0].map((result, j) => (
                                <tr key={j}>
                                    <td className="bg-slate-200 border-b-slate-50 border-2 p-2 w-48 text-left"><b>{result.question}</b></td>
                                    {conceptResults.map((questionResults, k) => (
                                        <td key={k} className="border-2 border-slate-200 p-2 text-left">{questionResults[j].answer}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                
                </div>
                
            ))}

            {loading && <Loading />}

        </div>
    );
*/
