// use state
import { useEffect, useState } from "react";
// use params
import { useNavigate, useParams } from "react-router-dom";
// Table component
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
// custom components
import DeletePanelists from "../../utils/panels/DeletePanelists";
import DeletePanels from "../../utils/panels/DeletePanels";
import GetPanelistsbyPanelId from "../../utils/panels/GetPanelistsbyPanelId";

const Panel = (props) => {

    const navigate = useNavigate();
    const panelID = useParams().panelID;
    const [panelists, setPanelists] = useState([]); 
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [displayFormat, setDisplayFormat] = useState('Card');

    useEffect(() => {
        async function fetchData() {
            const panelists = await GetPanelistsbyPanelId(panelID);
            console.log('Panelists:', panelists);
            setPanelists(panelists);
            setIsLoading(false);
        }
        fetchData();
    }, [panelID, displayFormat]);

    const columns = [
        {field: 'id', headerName: 'ID'},
        {field: 'panelID', headerName: 'Panel ID'},
        {field: 'name', headerName: 'Name', width: 100},
        {field: 'age', headerName: 'Age', width: 50, type: 'number'},
        {field: 'age_range', headerName: 'Age Range', width: 100},
        {field: 'gender', headerName: 'Gender', width: 100},
        {field: 'race', headerName: 'Race', width: 100},
        {field: 'education', headerName: 'Education', width: 200},
        {field: 'employment_status', headerName: 'Employment Status', width: 200},
        {field: 'occupation', headerName: 'Occupation', width: 250},
        {field: 'marital_status', headerName: 'Marital Status', width: 150},
        {field: 'household_type', headerName: 'Household Type', width: 150},
        {field: 'household_size', headerName: 'Household Size', width: 150},
        {field: 'children', headerName: 'Children', width: 75},
        {field: 'income_range', headerName: 'Income Range', width: 150},
        {field: 'asset_range', headerName: 'Asset Range', width: 150},
        {field: 'networth_range', headerName: 'Net Worth Range', width: 150},
        {
            field: 'income',
            headerName: 'Income',
            width: 150,
            type: 'number',
            valueFormatter: (params) => {
                const valueFormatted = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0, // No decimal places
                    maximumFractionDigits: 0, // No decimal places
                }).format(params.value);
                return valueFormatted;
            },
        },
        {
            field: 'net_worth',
            headerName: 'Net Worth',
            width: 150,
            type: 'number',
            valueFormatter: (params) => {
                const valueFormatted = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0, // No decimal places
                    maximumFractionDigits: 0, // No decimal places
                }).format(params.value);
                return valueFormatted;
            },
        },
        {field: 'city', headerName: 'City', width: 150},
        {field: 'metro', headerName: 'Metro', width: 150},
        {field: 'state', headerName: 'State', width: 150},
        {field: 'region', headerName: 'Region', width: 150},
        {field: 'zipcode', headerName: 'Zipcode', width: 150},
        {field: 'backstory', headerName: 'Backstory', width: 500},
        {field: 'values', headerName: 'Values', width: 500},
        {field: 'lifestyle', headerName: 'Lifestyle', width: 500},
        {field: 'attitudes', headerName: 'Attitudes', width: 500},
        {field: 'interests', headerName: 'Interests', width: 500},
        {field: 'personality', headerName: 'Personality', width: 500},
        {field: 'social', headerName: 'Social', width: 500},
    ];

    const handleSelectionChange = (newSelection) => {
        setSelectedItems(newSelection);
    }

    async function deletePanelists() {
        if (window.confirm('Are you sure you want to delete these panelists?')) {
            const panelistDeleteResult = await DeletePanelists(selectedItems);
            setPanelists(panelists.filter(panelist => !selectedItems.includes(panelist.id)));
        }
        if (panelists.length === 0) {
            const panelDeleteResult = await DeletePanels(panelID);
            navigate('/panels');
        }
    }

    return (
        <div className="flex flex-col flex-wrap m-8 w-full min-h-screen h-full bg-slate-50 overflow-x-hidden overflow-y-auto">
        <div className="flex flex-col flex-wrap w-full h-full bg-slate-50 overflow-y-auto">
            <div className="flex flex-col flex-wrap w-full h-full max-h-fit bg-slate-50 overflow-y-auto">
                    {/*<div className="flex w-full m-2 h-10 bg-slate-50 justify-end">
                        <button 
                            onClick={deletePanelists} 
                            name='delete' 
                            className="px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 disabled:bg-slate-500 disabled:text-black hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                            Delete
                        </button>
                    </div>*/}
                    <div className="flex h-10 mb-4 mr-4 mt-2 bg-slate-50 justify-end">
                        <button 
                            onClick={displayFormat === 'Card' ? () => setDisplayFormat('Table') : () => setDisplayFormat('Card')} 
                            name='delete' 
                            className="px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 disabled:bg-slate-500 disabled:text-black hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                            {displayFormat === 'Card' ? 'Table' : 'Card'} View
                        </button>
                    </div>
                    {/* if props to table is true use data grid else use persona list */}
                {displayFormat === 'Table' ? (
                    <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-auto bg-slate-50">
                    <DataGridPro
                        slots = {{ toolbar: GridToolbar}}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                                panelID: false,
                                name: false,
                              },
                            },
                        }}

                        sx={{   
                            fontWeight: 'fontWeightLight',
                            bgcolor: 'white',
                            boxShadow: 2, 
                            borderRadius: 4,
                            border: 1,
                            px: 2,
                        }}
                        pagination
                        autoHeight
                        rows={panelists}
                        columns={columns}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        //checkboxSelection
                        onRowSelectionModelChange={handleSelectionChange}                        
                    />
                    </div>
                ): (
                    <div className="flex flex-wrap content-start space-x-4 space-y-4 p-2">            
                        {panelists.map((panelist) => (
                            <div key={panelist.id} className="flex flex-col bg-white p-4 m-2 rounded-xl drop-shadow-lg w-full">
                                <div className="justify-items-start">
                                    <h4 className="text-left font-medium">
                                        {(panelist.age) && panelist.age + ' year old '}
                                        {(panelist.marital_status) && panelist.marital_status}
                                        {(panelist.race) && ' ' + panelist.race}
                                        {(panelist.gender) && ' ' + panelist.gender}
                                        {(panelist.city) && ' from ' + panelist.city}
                                        {(panelist.state) && ', ' + panelist.state}
                                    </h4>
                                    <hr />
                                <div className="flex flex-row w-full bg-white p-2">
                                <div className="flex flex-row flex-wrap w-1/4 bg-white p-2">
                                    <ul className="text-left font-medium list-disc px-4">
                                        {(panelist.age) && (<li><b>Age:</b> {panelist.age}</li>)}
                                        {(panelist.age_range) && (<li><b>Age Range:</b> {panelist.age_range}</li>)}
                                        {(panelist.gender) && (<li><b>Gender:</b> {panelist.gender}</li>)}
                                        {(panelist.race) && (<li><b>Race:</b> {panelist.race}</li>)}
                                        {(panelist.education) && (<li><b>Education:</b>  {panelist.education}</li>)}
                                        {(panelist.occupation) && (<li><b>Occupation:</b> {panelist.occupation}</li>)}
                                        {(panelist.marital_status) && (<li><b>Marital Status:</b> {panelist.marital_status}</li>)}
                                        {(panelist.household_type) && (<li><b>Household Type:</b> {panelist.household_type}</li>)}
                                        {(panelist.household_size) && (<li><b>Household Size:</b> {panelist.household_size}</li>)}
                                        {(panelist.children || panelist.children === 0) && (<li><b>Children:</b> {panelist.children}</li>)}
                                        {(panelist.income) && (<li><b>Income:</b> {panelist.income}</li>)}
                                        {(panelist.income_range) && (<li><b>Income Range:</b> {panelist.income_range}</li>)}
                                        {(panelist.asset_range) && (<li><b>Asset Range:</b> {panelist.asset_range}</li>)}
                                        {(panelist.net_worth) && (<li><b>Net Worth:</b> {panelist.net_worth}</li>)}
                                        {(panelist.networth_range) && (<li><b>Net Worth Range:</b> {panelist.networth_range}</li>)}
                                        {(panelist.city) && (<li><b>City:</b> {panelist.city}</li>)}
                                        {(panelist.metro) && (<li><b>Metro:</b> {panelist.metro}</li>)}
                                        {(panelist.region) && (<li><b>Region:</b> {panelist.region}</li>)}
                                        {(panelist.state) && (<li><b>State:</b> {panelist.state}</li>)}
                                        {(panelist.zipcode) && (<li><b>Zipcode:</b> {panelist.zipcode}</li>)}  
                                    </ul>
                                </div>
                                <div className="flex w-3/4 bg-white px-4 py-2 border-l-2">
                                    <ul className="text-left font-medium list-disc px-4">
                                        {(panelist.backstory) && (<li><b>Backstory:</b> {panelist.backstory}</li>)}
                                        {(panelist.values) && (<li><b>Values & Beliefs:</b> {panelist.values}</li>)}
                                        {(panelist.lifestyle) && (<li><b>Lifestyle & Activities:</b> {panelist.lifestyle}</li>)}
                                        {(panelist.attitudes) && (<li><b>Attitudes & Opinions:</b> {panelist.attitudes}</li>)}
                                        {(panelist.interests) && (<li><b>Interests & Hobbies:</b> {panelist.interests}</li>)}
                                        {(panelist.personality) && (<li><b>Personality Traits:</b> {panelist.personality}</li>)}
                                        {(panelist.social) && (<li><b>Social & Cultural:</b> {panelist.social}</li>)}
                                    </ul>
                                </div>
                                </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            </div>
        </div>
    )
};
  
export default Panel;