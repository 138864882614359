import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetConceptAnswersByProjectBriefID = async (projectBriefID) => {

    console.log("GetConceptAnswersByProjectBriefID")

    let items = [];
    let nextToken;

    do {
        const result = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.conceptAnswersByProjectbriefID,
            variables: {
                projectbriefID: projectBriefID,
                nextToken,
            }
        });
        items = items.concat(result.data.conceptAnswersByProjectbriefID.items);
        nextToken = result.data.conceptAnswersByProjectbriefID.nextToken;
    } while (nextToken);

    // get the items from the result
    const conceptAnswers = items;
    console.log("conceptAnswers")
    console.log(conceptAnswers.length)

    // get the project brief from projectBriefID
    let projectBrief;
    let projectBriefResult;
    try {
        projectBriefResult = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.getProjectBrief,
            variables: {
                id: projectBriefID,
            }
        });
        
    } catch(err) {
        console.error(err);
    }
    projectBrief = projectBriefResult.data.getProjectBrief;
    console.log("projectBrief")
    console.log(projectBrief)

    // we have to loop through the items and get concept, concept questions and panelist/persona name
    // we have to return 3D array of objects with the following structure:
    // i,j,k: concept, panelist/persona, concept question
    // result [i][j][k] = {concept: <>, name: <>, question: <>, answer: <>}

    // 1. get all concepts from projectBriefID
    let conceptsResult;
    try {
        conceptsResult = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.conceptsByProjectbriefID,
            variables: {
                projectbriefID: projectBriefID,
            }
        });
        
    } catch(err) {
        console.error(err);
    }
    const concepts = conceptsResult.data.conceptsByProjectbriefID.items;
    console.log("concepts");
    console.log(concepts);
    // 2. get all the concept questions from projectBriefID
    let conceptQuestions;
    let conceptQuestionsResult
    try {
        conceptQuestionsResult = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.conceptQuestionsByProjectbriefID,
            variables: {
                projectbriefID: projectBriefID,
            }
        });
        
    } catch(err) {
        console.error(err);
    }
    conceptQuestions = conceptQuestionsResult.data.conceptQuestionsByProjectbriefID.items;
    conceptQuestions.forEach((question, index) => {
        if (question.options) {
            try {
                conceptQuestions[index].options = JSON.parse(question.options);
            } catch(err) {
                console.error("Inavlid JSON:", err);
            }
        }
    });
    console.log("conceptsQuestions");
    console.log(conceptQuestions);
    // 3. get all the respondents from conceptAnswers
    let respondents = [];
    conceptAnswers.forEach((conceptAnswer) => {
        if (projectBrief.audience === "Panels") {            
            // check if the panelistID is already in the respondents
            if (!respondents.some(item => item.id === conceptAnswer.panelistID)) {                
                respondents.push({id: conceptAnswer.panelistID});               
            }
        } else {
            if (!respondents.some(item => item.id === conceptAnswer.customerpersonaID)) {
                respondents.push({id: conceptAnswer.customerpersonaID});                
            }
        }        
    });
    console.log("respondents - get the IDs");
    console.log(respondents);    
    
    for (let i=0; i<respondents.length; i++) {
        if (projectBrief.audience === "Panels") {
            // get the panelist name
            let panelistResult;
            try {
                panelistResult = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.getPanelist,
                    variables: {
                        id: respondents[i].id,
                    }
                });
                
            } catch(err) {
                console.error(err);
            }
            const panelist = panelistResult.data.getPanelist;
            console.log("panelist")
            console.log (panelist)
            respondents[i].name = panelist.name;
        } else {
            // get the persona name
            let personaResult;
            try {
                personaResult = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.getCustomerPersona,
                    variables: {
                        id: respondents[i].id,
                    }
                });
                
            } catch(err) {
                console.error(err);
            }
            
            const persona = personaResult.data.getCustomerPersona;
            console.log("persona")
            console.log (persona)
            respondents[i].name = persona.name;    
        }
    }
    console.log("respondents - get IDS and names");
    console.log(respondents);

    const conceptResult = [];
    // iterate through the concept Answers
    for (let i=0; i<concepts.length; i++) {
        conceptResult[i] = [];
        for (let j=0; j<conceptQuestions.length; j++) {
            conceptResult[i][j] = [];
            for (let k=0; k<respondents.length; k++) {
                conceptResult[i][j][k] = {};
                conceptResult[i][j][k].concept = concepts[i].description;
                conceptResult[i][j][k].name = respondents[k].name;
                conceptResult[i][j][k].question = conceptQuestions[j].question;
                conceptResult[i][j][k].answer = "";
                
                for (let l=0; l<conceptAnswers.length; l++) {

                    if (conceptAnswers[l].conceptsID === concepts[i].id && 
                        conceptAnswers[l].conceptquestionsID === conceptQuestions[j].id &&
                        (conceptAnswers[l].panelistID === respondents[k].id ||
                        conceptAnswers[l].customerpersonaID === respondents[k].id)) {
                            conceptResult[i][j][k].answer = conceptAnswers[l].answer;
                            console.log("conceptResult[i][j][k] answer: ", conceptResult[i][j][k].answer);
                    }
                }
            }
        }
    }

    console.log("conceptResult");
    console.log(conceptResult);
    
   /*
    const objective = props.objective;
    const results = props.results;
    const respondents = props.respondents;
    const concepts = props.concepts;
    const questions = props.questions;
    */
    //debug
    //const conceptResult = [];
    return {results: conceptResult, respondents: respondents, concepts: concepts, questions: conceptQuestions, objective: projectBrief.objective};

};

export default GetConceptAnswersByProjectBriefID;
