import {  useState, useEffect } from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
//import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';

// Persona component
const PersonaGenerated = (props) => {

    const [persona, setPersona] = useState(props.persona);
    const [feedback, setFeedback] = useState(false)

    useEffect(() => {   
        setPersona(props.persona);
    }, []);

    function feedbackForm(persona) {
        setFeedback(true);
        // open the feedback form
        /*const data = {
            id: personaID,
            userFeedback: feedback
        }*/
        //UpdatePersona(data);
    }
    {/*
    const regeneratethisPersona = () => {
        props.regeneratePersona(persona.id);
    };
*/}

    // render the persona
    return (
        <div className='flex flex-col w-full m-2'>
            {/* persona details */}
            <div key={persona.id} className="flex flex-col bg-white p-4 rounded-xl drop-shadow-lg w-full">
                        <div className="flex justify-between items-center">
                            <h5 className="text-left text-slate-500 font-medium">
                                {persona.name}
                            </h5>
                            <div className="flex flex-row justify-end space-x-4">
                                {/*<button className={edit ? "rounded-lg bg-slate-300 text-blue-500 dark:bg-slate-500 dark:text-blue-300 items-center text-center m-1 p-1" : "rounded-lg  items-center text-center m-1 p-1 hover:text-blue-600"}  
                                    onClick={() => editPersona(persona.id)}> 
                                    <EditOutlinedIcon />
                                </button>*/}
                                <button disabled={true} className={feedback ? "rounded-lg bg-slate-300 text-blue-500 dark:bg-slate-500 dark:text-blue-300 items-center text-center m-1 p-1" : "rounded-lg  items-center text-center text-slate-500 m-1 p-1 hover:text-blue-600"} type="button" onClick={() => feedbackForm(persona)}> <FeedbackOutlinedIcon /> </button>
                                <button disabled={true} className="rounded-lg  items-center text-center text-slate-500 m-1 p-1 hover:text-blue-600" > <AutorenewIcon /> </button>
                            </div>
                        </div>
                        <hr />
                        <ul className="text-left text-slate-500 font-medium list-disc px-4">                            
                            {(persona.age) && (<li key={persona.age}>Age: {persona.age}</li>)}
                            {(persona.gender) && (<li key={persona.gender}>Gender: {persona.gender}</li>)}                             
                            {(persona.race) && (<li key={persona.race}>Race:  {persona.race }</li>)}
                            {(persona.education) && (<li key={persona.education}>Education:  {persona.education}</li>)}
                            {(persona.occupation) && (<li key={persona.occupation}>Occupation: {persona.occupation}</li>)}
                            {(persona.maritalStatus) &&(<li key={persona.maritalStatus}>Marital Status: {persona.maritalStatus}</li>)}
                            {persona.children !== null && persona.children !== undefined && (<li key={persona.children}>Children: {persona.children}</li>)}                            
                            {(persona.income) && (<li key={persona.income}>Income: {persona.income}</li>)}
                            {(persona.netWorth) && (<li key={persona.netWorth}>Net Worth: {persona.netWorth}</li>)}
                            {(persona.city) && (<li key={persona.city}>City: {persona.city}</li>)}
                            {(persona.state) && (<li key={persona.state}>State: {persona.state}</li>)}
                            {(persona.country) && (<li key={persona.country}>Country: {persona.country}</li>)}
                            {(persona.values) &&(<li key={persona.values}>Values & Beliefs: {persona.values}</li>)}
                            {(persona.lifestyle) && (<li key={persona.lifestyle}>Lifestyle & Activities: {persona.lifestyle}</li>)}
                            {(persona.attitudes) && (<li key={persona.attitudes}>Attitudes & Opinions: {persona.attitudes}</li>)}
                            {(persona.interests) && (<li key={persona.interests}>Interests & Hobbies: {persona.interests}</li>)}
                            {(persona.personality) && (<li key={persona.personality}>Personality Traits: {persona.personality}</li>)}
                            {(persona.social) && (<li key={persona.social}>Social & Cultural: {persona.social}</li>)}
                        </ul>
                            
                    </div>
        </div>
    );
};

export default PersonaGenerated;