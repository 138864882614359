import { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';

const PanelSidebar = (props) => {

    const [selectedButton, setSelectedButton] = useState(null);
    const [selectedSubmenu, setSelectedSubmenu] = useState(null);    

    const onClickMenu = async(id) => {
        if (selectedButton === id) {
            setSelectedButton(null);
        } else {
            setSelectedButton(id);
            setSelectedSubmenu(null);
        }
        props.updateMenu(id);
    }

    const onClickSubmenu = async(id) => {
        setSelectedSubmenu(id);
        props.updateSubmenu(id);
    }

    return(
        <div className="flex flex-col flex-wrap">
            {/* Sidebar */}
            <aside className='flex flex-col border-r-2 min-h-screen h-full overflow-y-auto'>
            <div className="flex flex-col w-full h-full bg-slate-50 min-h-screen overflow-y-auto">                
                
                {/* Menu Items */}
                <div className="flex flex-col w-48 h-full overflow-y-auto px-2 py-2 dark:border-slate-700" >
                    {props.menuItems.map((item) => (
                        <div key={item.id}> 
                            <button onClick={() => onClickMenu(item.id)}
                            className={selectedButton === item.id ? 
                                'flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 focus:outline-none bg-slate-200' :
                                'flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 focus:outline-none hover:bg-slate-200'
                            }
                            >
                                <h1 className="text-sm font-medium capitalize text-slate-700">{item.name}</h1>
                            </button>
                            {props.submenuItems ? props.submenuItems.filter(subitem => subitem.sourceID === item.id).map((subitem) => (
                                <div key={subitem.id}>
                                <button key={subitem.id} onClick={() => onClickSubmenu(subitem.id)} 
                                    className={ selectedButton === item.id ? (selectedSubmenu === subitem.id ? 
                                    'flex w-full flex-col gap-y-2 rounded-lg px-8 py-2 text-left transition-colors duration-200 focus:outline-none bg-blue-200':                                    
                                    'flex w-full flex-col gap-y-2 rounded-lg px-8 py-2 text-left transition-colors duration-200 focus:outline-none hover:bg-blue-200')
                                     :
                                    'hidden'}
                                >
                                    <h1 className="text-sm font-sm capitalize text-slate-700 " > {subitem.name}</h1>
                                </button>   
                                </div>                             
                                )) : null
                            }

                        
                        </div>
                    ))}                        
                </div>     
            </div>
            </aside>
        </div>
    )
};

export default PanelSidebar;