
import TextWithLinks from '../TextWithLinks';

// useState and fragment
import { useState } from 'react';
import AgentPanel from '../AgentPanel';

const AgentMessage = (props) => {
    //console.log(props)
    let sources = props.sources

    return (
        <div className="flex flex-row w-full">
            {/* <AgentPanel id={props.id} userFeedback={props.userFeedback} copiedMessage={props.copiedMessage} sources={sources}/> */}            
            
            <div className="flex flex-row w-full bg-slate-50">
                <div className="px-4 mt-4 mb-2 h-full">
                    <p>A:</p>
                </div>
                <div className="w-full mt-4 mb-4 mr-4 text-left w-10/12">
                    {/*<p className="w-full text-left whitespace-pre-line" > {props.message} </p>*/}
                    <TextWithLinks text={props.message} sources={sources}/>
                </div>
            </div>
        </div>
    )
}

export default AgentMessage;