import { useEffect, useState } from 'react';
import Loading from '../../utils/Loading';
import { Card, Title, BarChart } from "@tremor/react";

const ProjectSurveyResultsForInsights = (props) => {

    console.log("in ProjectSurveyResultsForInsights: ", props)

    const [loading, setLoading] = useState(true);
    const objective = props.objective;
    const results = props.results;
    const respondents = props.respondents;
    const questions = props.questions;

    const valueFormatter = (number) => Intl.NumberFormat("us").format(number).toString();

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading])    

    //let dataForBarChart = [];

    return (

        <div className="flex flex-col w-full m-4 bg-slate-50 overflow-y-auto">

            {loading ? (<Loading /> ) : (
            
                // Transform data into format for BarChart
                questions.map((question, j) => {
                    // find row in the results array that matches the question
                    // do this only if results exists
                    let resultsquestion = [];
                    if (results) {
                        const index = results.findIndex((result) => result[0].question === question.question);
                        //console.log(index)
                        if (index !== -1) {
                            resultsquestion = results[index];
                        }
                    }

                    //const optionsArray = JSON.parse(question.options); 
                    const optionsArray = question.options;                     
                    const dataForBarChart = optionsArray.map((optionObject, k) => {
                        // check if optionObject.respondents is already set
                        /*if (optionObject.respondents) {
                            return {
                                categories: optionObject.option,
                                values: optionObject.respondents,  // number of respondents
                            };
                        }*/
                        
                        // find the number of respondents for each option
                        optionObject.respondents = 0;
                        for (let i = 0; i < resultsquestion.length; i++) {
                            if (resultsquestion[i].question === question.question) {
                                console.log("resultsquestion[i].answer: ", resultsquestion[i].answer);
                                try {
                                    //const answerOptions = JSON.parse(resultsquestion[i].answer);
                                    let answerOptions = resultsquestion[i].answer ? JSON.parse(resultsquestion[i].answer) : {};
                                    // if answerOptions is an array, set answerOptions to the first element
                                    if (Array.isArray(answerOptions)) {
                                        answerOptions = answerOptions[0];
                                    }
                                    console.log("answerOptions: ", answerOptions);
                                    if (answerOptions.option === optionObject.option) {
                                        optionObject.respondents = optionObject.respondents + 1;
                                    }
                                } catch (err) {
                                    console.error("Error parsing JSON string:", err);
                                }
                                
                            }
                        }
                        
                        return {
                            categories: optionObject.option,
                            respondents: optionObject.respondents,  // number of respondents
                        };
                    
                    });
                    console.log("dataForBarChart:");
                    console.log(dataForBarChart);
    
                    return (
                        
                        <div key={j} className="flex flex-row flex-wrap w-full bg-slate-50 mt-4 mb-4 overflow-x-auto">

                            <div className="flex flex-row bg-slate-50 w-full">
                                
                                <Card className="w-full" decoration="">
                                    <Title className="text-center">{question.question}</Title>
                                    <BarChart 
                                        data={dataForBarChart}
                                        categories={['respondents']}
                                        index="categories"
                                        valueFormatter={valueFormatter}
                                        colors={["blue"]}
                                        showLegend={false}
                                        yAxisWidth={48}
                                        showAnimation={true}
                                    />
                                </Card>
                            </div>
                        </div>
                    );
                })
            )}
        
        </div>

    );
}

export default ProjectSurveyResultsForInsights;