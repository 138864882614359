import { Construction } from "@mui/icons-material";
import { API } from "aws-amplify";

async function getJobStatusWithBackoff(jobId) {
    const path = '/jobs/' + jobId;
    let response = await API.get('llmjobdevapi', path);
    let attempts = 0;

    while (true) {
        response = await API.get('llmjobdevapi', path);
        console.log(response);

        if (response.status === 'completed') {
            //console.log('Job completed!', response);
            break;
        } else {
            //console.log('Job not yet completed');
            attempts++;
            let delay = Math.min(Math.pow(2, attempts) * 1000, 30000);
            console.log(`Retrying in ${delay}ms...`);
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }

    // test the diffrent outputs
    return JSON.parse(response.result);
  }

const SimpleLLMStartPoll= async (data) => {
    let response;
    let jobID;
    console.log('SimpleLLMStartPoll:', data);
    let response_format = "simple";
    /*if (response_format in data) {
        response_format = data.response_format;
    }*/
    try {
        const apiresponse = await API.post ('llmjobdevapi', '/jobs', {
            body: {
                input: {
                    question: data.question,
                    system_prompt: data.system_prompt, 
                    model: data.model,
                    temperature: data.temperature,
                    response_format: response_format,
                }
            },
        });
        //console.log('SimpleLLMCall:', apiresponse);
        jobID = apiresponse.jobID;
    }
    catch (error) {
        console.error(error);
        // Return a graceful error message
        return 'An error occurred while making the API call';
    }
    // get the job status using the jobID
    console.log("getting job results")
    response = await getJobStatusWithBackoff(jobID);
    console.log("completed job and here are the results:")
    console.log(response);

    if (response) {
        console.log('SimpleLLMStartPoll:', response);
        //const parsedResponse = JSON.parse(trimmedResponse);
        //onsole.log('SimpleLLMCall:', parsedResponse);
        //return parsedResponse;
        return response;
    }
    else {
        // Return a graceful error message
        return 'An error occurred while processing the API response';
    }    
}

export default SimpleLLMStartPoll;
