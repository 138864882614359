import { useEffect } from "react";
import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { useState } from "react";
import { useParams } from "react-router-dom";

const InterviewResults = (props) => {

    const projectID = useParams().projectID;
    const [interviewResultID, setInterviewResultID] = useState(null);
    const [interviewResults, setInterviewResults] = useState([]);

    useEffect(() => {

        let result = null;

        const fetchInterviewBriefs = async() => {

            const userInfo = await Auth.currentAuthenticatedUser();

            try {

                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.listCustomerInterviewResults,
                    variables: {
                        filter: {
                            owner: {eq: userInfo.attributes.email},
                            customerInterviewBriefID: {eq: projectID},
                        }
                    },                
                });
                console.log(result.data.listCustomerInterviewResults.items[0].id)
                setInterviewResultID(result.data.listCustomerInterviewResults.items[0].id)

            } catch(err) {
                console.error(err);
            }
        }
    
        // get interview results for this ID
        const fetchInterviewResults = async() => {

            const userInfo = await Auth.currentAuthenticatedUser();

            try {
                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.getCustomerInterviewResults,
                    variables: {
                        id: interviewResultID,           
                    },
                });
                console.log(result.data.getCustomerInterviewResults.customerInterviewAnswers)
                setInterviewResults(result.data.getCustomerInterviewResults.customerInterviewAnswers);

            } catch(err) {
                console.error(err);
            }
        }
    
        fetchInterviewBriefs();
        if (interviewResultID) {
            fetchInterviewResults();
        }

    }, [projectID]);

    return (
        <div className="flex flex-col w-full content-start bg-slate-50">
            <table className="table-auto w-full border border-slate-500">
                        <thead className="content-start">
                        <tr className="border bg-slate-50">
                                <th className="px-4 py-4 text-left">
                                    <h5>Objective</h5>
                                </th>
                                <th className="border px-4 py-4 text-left">
                                    <p>{props.objective}</p>
                                </th>
                            </tr>
                            <tr className="border bg-slate-50">
                                <th className="px-4 py-4 border text-left">
                                    <h5>Question</h5>
                                    </th>
                                    <th className="px-4 py-4 border text-left">
                                    <h5>Answer</h5>
                                    </th>
                            </tr>
                        </thead>
                        <tbody>
                        {interviewResults.map((item) => (
                                <tr key={item.id} className="border">
                                    <td className="border text-left p-4 whitespace-pre-wrap">{item.question}
                                    </td>
                                    <td className="border text-left p-4 whitespace-pre-wrap">{item.answer}
                                    </td>
                                    
                                    
                                </tr>
                                
                            ))}
                        </tbody>
                    </table>
                </div>
    )
}

export default InterviewResults;