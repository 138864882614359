import { API, Auth } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const SaveSources = async (data, createOrChange) => {

    if (createOrChange === "create") {

        try {
            const apiData = await API.graphql({ 
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: mutations.createSources , // change this after we know DB schema
                variables: {
                    input: data,
                }
            });

            return apiData.data.createSources.id;
        } catch (err) {
            console.log("ERROR- SaveSources: " + err)
        }
    } else if (createOrChange === "change") {
        await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: mutations.updateSources , // change this after we know DB schema
            variables: {
                input: data,
            }
        });
    }
}

export default SaveSources;