// use state
import { useEffect, useState } from "react";
// Table component
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ThemeProvider, createTheme } from '@mui/system';
// custom components

const SegmentTable = (props) => {

    console.log("in Segments")
    
    console.log(props.segments)
    // display format
    const [displayFormat, setDisplayFormat] = useState('Card');
    // persona
    const segments = props.segments; 
    // loading 
    const [isLoading, setIsLoading] = useState(props.isLoading);
    // user
    const [user, setUser] = useState(null);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    //const [selectedPersonas, setSelectedPersonas] = useState([]);

    const theme = createTheme({
        palette: {
            background: {
              paper: '#fff',
            },
            text: {
              primary: '#173A5E',
              secondary: '#46505A',
            },
            action: {
              active: '#001E3C',
            },
            success: {
              dark: '#009688',
            },
        },
    });

    const columns = [
        {field: 'name', headerName: 'Name', width: 300},
        {field: 'description', headerName: 'Description', width: 500},
    ]

    const rows = segments.map((segment) => {
        return {
            id: segment.id,
            name: segment.name,
            description: segment.description,
        }
    })

    const handleSelectionChange = (newSelection) => {
        console.log(newSelection);
        //props.selectedPersonas(newSelection);
        //setSelectedPersonas(newSelection);
    }

  return (
      <div className="flex flex-col w-full h-full bg-slate-50 m-4 ">
          <div className="w-full h-full bg-slate-50">
          <div className="w-full h-full bg-slate-50 overflow-x-auto overflow-y-auto">
                        <DataGridPro
                            autoHeight
                            sx={{   
                                    fontWeight: 'fontWeightLight',
                                    bgcolor: 'white',
                                    boxShadow: 2, 
                                    borderRadius: 4,
                                    border: 1,
                                    px: 2,
                                }}
                            rows={segments}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            
                            pagination
                            pageSize={10}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            //checkboxSelection
                            onRowSelectionModelChange={handleSelectionChange}
                        />
          </div>
      </div>
    </div>
  )
};
  
export default SegmentTable;