const UserMessage = (props) => {

    return (
        <div className="flex flex-row w-full bg-sky-100">
                <div className="px-4 mt-4 mb-2 h-full">
                    <p>Q:</p>
                </div>
                <div className="mt-4 mb-2 mr-4 text-left w-10/12">
                    <p>{props.message}</p>
                </div>
        </div>
    )
}

export default UserMessage;