import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";


const InteractionSidebar = (props) => {
    
    const { conversationID } = useParams();
    const [selectedButton, setSelectedButton] = useState(null);
    const buttonText = props.convType.slice(0, -1);

    const onClick = async(id) => {
        setSelectedButton(id);
        props.updateConversation(id);
    }

    const onClickNew = async(event) => {
        setSelectedButton(null);
        props.newChat();
    }   

    useEffect(() => {
        if (conversationID) {
          setSelectedButton(conversationID);
          props.updateConversation(conversationID);
        }
      }, [conversationID]);

    return(
        <div className="flex flex-col h-full bg-slate-50">
            {/* Sidebar */}
            <aside>
           
            <div className="w-auto h-screen overflow-y-auto bg-slate-50 dark:border-slate-700 dark:bg-slate-900">                

                <div className="w-auto p-4 border-b-2">
                    <h5>Previous {props.convType} </h5>
                </div>
                {/* Previous chats container */}
                <div className="w-auto overflow-y-auto space-y-4 border-slate-50 px-2 py-4 dark:border-slate-700" >
                    {props.conversationTitles.map((item) => (
                        <button key={item.id} onClick={() => onClick(item.id)}
                            className={selectedButton === item.id ? 
                                'flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 focus:outline-none bg-slate-200 dark:bg-slate-600' :
                                'flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 focus:outline-none hover:bg-slate-200 dark:hover:bg-slate-800'
                            }
                        >
                            <h1 className="text-sm font-medium capitalize text-slate-700 dark:text-slate-200" > {item.title}</h1>
                            <p className="text-xs text-slate-500 dark:text-slate-400">{item.date}</p>
                        </button>
                    ))}                        
                </div>  

                <button onClick={onClickNew} className="w-auto justify-items-start gap-x-4 rounded-lg border border-slate-300 p-4 m-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:border-slate-700 dark:text-slate-200 dark:hover:bg-slate-800">
                    <AddIcon />
                    New {buttonText}
                </button>    
            </div>
            </aside>
        </div>
    )
};

export default InteractionSidebar;

//space-y-4 border-b
