// use state
import { useEffect, useState } from "react";
// Table component
import { DataGridPro } from '@mui/x-data-grid-pro';
// custom components
import GetSourceById from "../../utils/panels/GetSourceById";
import { set } from "react-hook-form";

const Panels = (props) => {

    const [loading, setLoading] = useState(props.loading);
    const panels = props.panels;
    const [rows, setRows] = useState([]);

    useEffect(() => {
        
        setLoading(true);

        const fetchSources = async () => {
            if (props.panels && props.panels.length > 0) {
                const updatedPanels = await Promise.all(panels.map(async (panel) => {
                    const source = await GetSourceById(panel.sourceID);
                    return {...panel, source: source.name};
                }));
                setRows(updatedPanels);
            }
        };

        setLoading(false);
        
        fetchSources();

    }, [props.panels]);

    const columns = [
        {field: 'id', headerName: 'ID'},
        {field: 'sourceID', headerName: 'Source ID'},
        {field: 'name', headerName: 'Name', width: 300},
        {field: 'description', headerName: 'Description', width: 500},
        {field: 'source', headerName: 'Source', width: 700},
    ];

    return (
        <div className="flex flex-col w-full h-full bg-slate-50 overflow-x-hidden">
            <div className="flex flex-row flex-wrap w-full h-full bg-slate-50 overflow-x-hidden">
                    <div className="w-full h-full bg-slate-50 m-4 overflow-x-hidden">
                    <DataGridPro
                        autoHeight
                        loading={loading}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                                sourceID: false,
                              },
                            },
                        }}

                        sx={{   
                            fontWeight: 'fontWeightLight',
                            bgcolor: 'white',
                            boxShadow: 2, 
                            borderRadius: 4,
                            border: 1,
                            px: 2,
                        }}
                        rows={rows}
                        columns={columns}
                        pagination
                        pageSize={10}
                        pageSizeOptions={[5, 10]}
                        //checkboxSelection
                    />
                    </div>
            </div>
        </div>
    )
};
  
export default Panels;