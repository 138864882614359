import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetInsightAnswersByProjectBriefID = async (projectBriefID) => {

    console.log("ProjectBriefID: " + projectBriefID);

    // 1. get the project brief 
    let projectBrief;
    let projectBriefResult;

    try {
        projectBriefResult = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.getProjectBrief,
            variables: {
                id: projectBriefID,
            }
        });
        
    } catch(err) {
        console.error(err);
    }

    projectBrief = projectBriefResult.data.getProjectBrief;
    console.log("ProjectBrief: ")
    console.log(projectBrief)

    // 2. get all the questions for this project
    let questions;
    let questionsResult
    try {
        questionsResult = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.questionsByProjectbriefID,
            variables: {
                projectbriefID: projectBriefID,
            }
        });
        
    } catch(err) {
        console.error(err);
    }

    questions = questionsResult.data.questionsByProjectbriefID.items;
    // if options are present, parse into JSON
    questions.forEach((question, index) => {
        if (question.options) {
            try {
                questions[index].options = JSON.parse(question.options);
            } catch(err) {
                console.error("Inavlid JSON:", err);
            }
        }
    });
    console.log("Questions: ");
    console.log(questions);

    // 3. get all the answers for this project
    let answers;
    //let answersResult;

    let items = [];
    let nextToken;

    do {
        const answersResult = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.answersByProjectbriefID,
            variables: {
                projectbriefID: projectBriefID,
                nextToken,
            }
        });
        items = items.concat(answersResult.data.answersByProjectbriefID.items);
        nextToken = answersResult.data.answersByProjectbriefID.nextToken;
    } while(nextToken)

    answers = items;
    console.log("Answers: ")
    console.log(answers.length)
    
    // 4. get the respondent IDs for this project
    let respondents = [];
    answers.forEach((answer) => {
        if (projectBrief.audience === "Panels") {            
            // check if the panelistID is already in the respondents
            if (!respondents.some(item => item.id === answer.panelistID)) {                
                respondents.push({id: answer.panelistID});               
            }
        } else {
            if (!respondents.some(item => item.id === answer.customerpersonaID)) {
                respondents.push({id: answer.customerpersonaID});                
            }
        }        
    });
    console.log("respondents - get the IDs");
    console.log(respondents);    
    
    // 5. get the respondent names based on the IDs
    for (let i=0; i<respondents.length; i++) {
        if (projectBrief.audience === "Panels") {
            // get the panelist name
            let panelistResult;
            try {
                panelistResult = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.getPanelist,
                    variables: {
                        id: respondents[i].id,
                    }
                });
                
            } catch(err) {
                console.error(err);
            }
            const panelist = panelistResult.data.getPanelist;
            console.log("panelist")
            console.log (panelist)
            respondents[i].name = panelist.name;
        } else {
            // get the persona name
            let personaResult;
            try {
                personaResult = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.getCustomerPersona,
                    variables: {
                        id: respondents[i].id,
                    }
                });
                
            } catch(err) {
                console.error(err);
            }
            
            const persona = personaResult.data.getCustomerPersona;
            console.log("persona")
            console.log (persona)
            respondents[i].name = persona.name;    
        }
    }
    console.log("respondents - get IDS and names");
    console.log(respondents);

    // 6. iterate through the Answers and construct a 2D array for dispalying the results
    let results = new Array(questions.length).fill().map(() => []);

    for (let j=0; j<respondents.length; j++) {
        
        //results[j] = [];

        for (let k=0; k<questions.length; k++) {
        
            //results[j][k] = {};
            //results[j][k].name = respondents[j].name;
            //results[j][k].question = questions[k].question;
            //results[j][k].answer = "";
            
            // save current question and answer to db
            results[k].push( {
                name: respondents[j].name,
                question: questions[k].question,
                answer: '',
            })

            for (let l=0; l<answers.length; l++) {

                if (answers[l].questionsID === questions[k].id &&
                    (answers[l].panelistID === respondents[j].id ||
                    answers[l].customerpersonaID === respondents[j].id)) {
                        results[k][j].answer = answers[l].answer;
                        console.log("answers[l].answer: ", answers[l].answer);
                }
            }
            console.log("Result[k][j]: ", results[k][j]); 
        }
    }

    console.log("Final Results: ");
    console.log(results);
    
    return {results: results, respondents: respondents, questions: questions, objective: projectBrief.objective};

};

export default GetInsightAnswersByProjectBriefID;
