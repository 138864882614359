// use state
import { useEffect, useState } from "react";
// AWS
import { API, Auth } from 'aws-amplify';
// local components
//import Card from "../../utils/Card";
import ChatWindow from "./ChatWindow";
import SearchInput from "../SearchInput";
import InteractionSidebar from "../navigation/InteractionSidebar";

// GraphQL
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

import { useParams } from "react-router-dom";


// openai model
//const model1 = "gpt-4";
const model1 = "gpt-3.5-turbo";
const model2 = "gpt-3.5-turbo";
//const model2 ="gpt-4";

const PersonaChatWorkflow = (props) => {

    const { personaID, convTitleID } = useParams();
    //console.log(props.persona)
    // is loading
    const [isLoading, setIsLoading] = useState(false);
    // current conv: formatting required for ChatWindow component
    const [conv, setConv] = useState([])
    // user
    const [user, setUser] = useState(null); // user is null if not logged in
    // conversation titles
    const [convTitles, setConvTitles] = useState([]);
    // current conv ID
    const [convId, setConvId] = useState(convTitleID);
    //const [convId, setConvId] = useState(null);
    //console.log(personaId)
    const persona = props.persona;
    console.log(persona)

    // transform conv titles to format for display to the component
    const transformConvTitles = (datainput) => {       
        
        return datainput.map(item => {
            const dateObj=new Date(item.createdAt)
            // Array of short month names
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // Format the date as "Mon day", e.g., "Aug 11"
            const formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;

            // Return the transformed object
            return {
                "id": item.id,
                "title": item.title,
                "date": formattedDate,
            };
        })
    }


    // fetch user and conversations
    useEffect(() => {
        
        const fetchUser = async() => {
            try {
                const amplifyUser = await Auth.currentAuthenticatedUser();
                setUser(amplifyUser);
            } catch (error) {
                setUser(null);
                console.log(error);
            }
        }

        const fetchConversations= async() => {            
            const userInfo = await Auth.currentAuthenticatedUser();

            let result;
            console.log(userInfo.attributes.email)
            console.log(persona.id)
            // fetch conv titles                       
            try {
                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.listCustomerConversations,
                    variables: {
                        filter: {
                            owner: {eq: userInfo.attributes.email},
                            customerPersonaID: {eq: persona.id},
                        }
                    },                
                });
            } catch(err) {
                console.error(err);
            }
    
            
            let convList = result.data.listCustomerConversations.items;
            console.log(convList)
            convList.sort((a,b) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
    
                return dateB-dateA
            })        
            
           console.log("List of all conversations")
           console.log(convList)
    
            let displayTitle = transformConvTitles(convList)
            //console.log("List of conversation title")
            //console.log(displayTitle)
            setConvTitles(displayTitle)
        }        
        fetchUser();
        fetchConversations();
        
    }, [])

    const userFeedback = async(id, feedback) => {
        console.log(id, feedback);
    }

    const copiedMessage = async(id) => {
        console.log(id);
    }

    function generateId() {
        return Date.now().toString(36) + Math.random().toString(36).slice(2);
    }    

    // transform conversation to format for display to the component
    const transformConv= (datainput) => {       
        
        return datainput.map(item => {
            // Return the transformed object
            console.log(item)
            return {
                "id": item.id,
                "role": item.agent,
                "message": item.message,
                "sources": item.sources,
                "like" : item.preference === 'LIKE' ? true : false,
                "dislike": item.preference === 'DISLIKE' ? true : false,
            };
        })
    }

    const updateConversation = async(id) => {
        console.log(id);
        let result;
        setConvId(id);
        setConv([]);

        // fetch messages from this conversation
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.listCustomerMessages,
                variables: {
                    filter : {
                        customerConversationID: {eq: id},
                    },
                },                
            });
        } catch(err) {
            console.error(err);
        }

        // get the messages in the conversation
        const convMessages = result.data.listCustomerMessages.items;
        convMessages.sort((a,b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            return dateA-dateB;
        });

        const convMessagesDisplay = transformConv(convMessages);
        setConv(convMessagesDisplay);
    }

    const newChat = async() => {
        console.log("new chat");
        setConv([])
        setConvId(null);
    }

    // update the chat when user types a message in search input component
    const updateUserMessage = async(message) => {
        let result;
        let formattedDate;
        let convIDNew;

        console.log(message);
        console.log(persona.id)
        setIsLoading(true);
        

        // create a conversation if not set
        if (!convId) {
            console.log("creating a new conversation")
            const questions = "Summarize the question to set a short descriptive title for this question: " + message;
            const title = await API.post('openaigptapi', '/chat', {
                body: {
                input: {
                    question: questions,
                    history: "",
                    model: model2,                
                    mode: "simple",
                    sources: [],
                }
            },
            });
            const parsedDataTitle = title.Answer.trim();
            console.log(parsedDataTitle)      

            const inputConversation = {
                // id will be auto-populated by AWS
                title: parsedDataTitle,  // title generated by the 
                owner: user.attributes.email,
                // belongs to the persona
                customerPersonaID: persona.id,
            }
            console.log(inputConversation)

            // Create a new conversation in the dB            
            try {
                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: mutations.createCustomerConversation,
                    variables: {
                        input: inputConversation,                        
                    }
                });
            } catch(err) {
                console.error(err);
            }
            
            convIDNew = result.data.createCustomerConversation.id
            console.log(convIDNew)
            setConvId(convIDNew)

            const dateObj = new Date(result.data.createCustomerConversation.createdAt)
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`; 
            
            const parsedDataTitleDisplay = {"id": convIDNew, "title": title.Answer.trim(), "date": formattedDate}
            setConvTitles(prevArr => [parsedDataTitleDisplay, ...prevArr])
        }
        else {
            console.log("using existing conversation")
            convIDNew = convId
        }

        console.log(convIDNew)

        const input = {
            // id is auto populated by AWS Amplify
            message: message,  // the message content the user submitted (from state)
            owner: user.attributes.email,  // this is the username of the current user
            customerConversationID: convIDNew,  //  belongs to the conversation
            agent: "user",
            preference: "NEUTRAL",
            copied: false,
        };

        // save user message to the dB 
        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: mutations.createCustomerMessage,
                variables: {
                    input: input,
                },
            });
        } catch (err) {
        console.error(err);
        }

        const userMessage = {"id": generateId(), "role": "user", "message": message, "sources": []}
        setConv(prevArr => [...prevArr, userMessage]);   
        
        // persona details
        //const persona_details = persona.name;
        const personaType = persona.type;
        let persona_details;
        if (personaType === "Business") {
            persona_details = "Persona Name: " + persona.name + ", Role: " + persona.role +
            ", Industry: " + persona.industry + ", Title: " + persona.seniority + 
            ", Education Level: " + persona.education + ", Company Size: " + persona.companySize + 
            ", Other Details:" + persona.details + ".";
        }
        else {
            persona_details = "Persona Name: " + persona.name + ", Age: " + persona.age +
            ", Gender: " + persona.gender + ", Education Level: " + persona.education + 
            ", Occupation: " + persona.occupation + ", Marital Status: " + persona.maritalStatus + 
            ", Income: " + persona.income + ", Net Worth: " + persona.netWorth + 
            ", Other Details:" + persona.details + ".";

        }

        const response = await API.post('openaigptapi', '/chat', {
            body: {
                input: {
                    question: message,
                    persona: persona.name,
                    persona_details: persona_details,              
                    model: model1,                    
                    mode: "persona_chat",
                }
            },
        });

        console.log(response.Answer.trim());

        // set the input to be updated in DB - AI agent message message
        const inputagent = {
            // id is auto populated by AWS Amplify
            message: response.Answer.trim(),  // the message content the user submitted (from state)
            owner: user.attributes.email,  // this is the username of the current user
            customerConversationID: convIDNew,  //  belongs to the conversation
            agent: "agent",
            preference: "NEUTRAL",
            copied: false,            
        };
        console.log(inputagent)

        // Try make the mutation to graphql API in DB for agent 
        try {
            await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: mutations.createCustomerMessage,
                variables: {
                    input: inputagent,
                },
            });
        } catch (err) {
        console.error(err);
        }

        console.log("after saving agent repsonse to dB")

        const agentMessage = {"id": generateId(), "role": "agent", "message": response.Answer.trim(), "sources": [], "like": false, "dislike": false}
        setConv(prevArr => [...prevArr, agentMessage])  
        setIsLoading(false);
    }
    
    const convType = "Chats";
    
    return (
        <div className="flex flex-row w-full">
        <div className="w-1/5 h-full border-b-2 border-r-2 overflow-y-auto">
            <InteractionSidebar conversationTitles={convTitles} updateConversation={updateConversation} newChat={newChat} convType={convType}/>
        </div>
     
            <div className="bg-slate-50 flex flex-row flex-wrap w-full">
                <div id="main chat" className="flex-1">
                    <div className="border-b-2 overflow-y-auto">    
                        <ChatWindow conversations={conv} userFeedback={userFeedback} copiedMessage={copiedMessage} isLoading={isLoading}
                        />
                    </div>

                    <div className="bg-slate-50 m-0 p-4 lg:w-full md:w-3/4 sm:w-5/6">
                        <SearchInput updateUserMessage={updateUserMessage} />
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default PersonaChatWorkflow;
